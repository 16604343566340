import React from 'react';

import { Box, type BoxProps, createStyles, TFCard, TFText } from '@tf/ui';

interface Props extends React.PropsWithChildren, BoxProps {
	title?: React.ReactNode;
	actions?: React.ReactNode;
}

const useStyles = createStyles(() => ({
	header: {
		padding: '.5em .25rem .5em 1.25rem',
		cursor: 'default',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
}));

export const SideMenu: React.FC<Props> = ({ children, title, actions, ...boxProps }) => {
	const { classes } = useStyles();

	return (
		<Box {...boxProps} w={280}>
			<TFCard>
				{title && (
					<Box className={classes.header}>
						<TFText lineClamp={1}>{title}</TFText>
						{actions}
					</Box>
				)}
				{children}
			</TFCard>
		</Box>
	);
};
