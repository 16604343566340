import { useState } from 'react';

import { useMutation } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { Center, Icon, Menu, TFNotifier, TFText, UnstyledButton } from '@tf/ui';
import { S } from '@tf/utils';

import { setAccountPriorityMutation } from '@/core/mutations';

type AccountPriority = BackendTypes.OverviewImportance;

interface Props {
	accountId: number;
	value: AccountPriority;
}

const PRIORITY = {
	LOW: 'LOW',
	MEDIUM: 'MEDIUM',
	HIGH: 'HIGH',
} as const;

export const PriorityButton: React.FC<Props> = ({ value, accountId }) => {
	const [accountPriority, setAccountPriority] = useState<AccountPriority>(value);

	const { mutate } = useMutation({
		mutationFn: (priority: AccountPriority) => {
			return setAccountPriorityMutation({
				accountId,
				priority,
			});
		},
		onSuccess: (isSuccess) => {
			if (isSuccess) {
				TFNotifier.info('Importance has been changed', { id: 'change-importance' });
			}
		},
	});

	const handleChange = (priority: AccountPriority) => {
		setAccountPriority(priority);
		mutate(priority);
	};

	return (
		<Menu withinPortal styles={{ item: { padding: '.25rem 1rem' } }}>
			<Menu.Target>
				<UnstyledButton
					sx={({ colors }) => ({
						display: 'flex',
						alignItems: 'center',
						width: '4.5rem',
						height: '1.8rem',
						...(accountPriority === PRIORITY.LOW && { color: colors.gray[5] }),
						...(accountPriority === PRIORITY.MEDIUM && { color: colors.orange[5] }),
						...(accountPriority === PRIORITY.HIGH && { color: colors.red[5] }),
					})}
				>
					<Center opacity={0.7} mr=".5rem">
						<Icon.IconCircleFilled size={10} />
					</Center>

					<TFText
						c="dimmed"
						sx={({ colors }) => ({
							flex: 1,
							fontSize: 13,
							'&:hover': { color: colors.brand[6], textDecoration: 'underline' },
						})}
					>
						{S.prettify(accountPriority as string)}
					</TFText>
				</UnstyledButton>
			</Menu.Target>

			<Menu.Dropdown>
				<Menu.Item
					onClick={() => handleChange(PRIORITY.LOW)}
					disabled={accountPriority === PRIORITY.LOW}
				>
					<TFText size="sm">{S.prettify(PRIORITY.LOW)}</TFText>
				</Menu.Item>
				<Menu.Item
					onClick={() => handleChange(PRIORITY.MEDIUM)}
					disabled={accountPriority === PRIORITY.MEDIUM}
				>
					<TFText size="sm">{S.prettify(PRIORITY.MEDIUM)}</TFText>
				</Menu.Item>
				<Menu.Item
					onClick={() => handleChange(PRIORITY.HIGH)}
					disabled={accountPriority === PRIORITY.HIGH}
				>
					<TFText size="sm">{S.prettify(PRIORITY.HIGH)}</TFText>
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
};
