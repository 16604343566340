import { BackendClient, type BackendTypes } from '@tf/api';

export const writeSegmentsMutation = async (
	input: BackendTypes.SegmentsWritePayload
): Promise<BackendTypes.SegmentsWriteData> => {
	const api = BackendClient.getInstance();
	const res = await api.segmentsWrite(input).then((r) => r.json());

	return res as BackendTypes.SegmentsWriteData;
};
