export interface PrimitiveField {
	name: string;
	component: FieldName;
	label?: string;
	disabled?: boolean;
	props?: {
		options: { value: string; label: string }[];
		isSearchable?: boolean;
		[key: string]: any;
	};
}

export enum FieldName {
	TextField = 'TextField',
	DateField = 'DateField',
	SelectField = 'SelectField',
}
