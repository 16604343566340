import { forwardRef } from 'react';

import { Avatar } from '@tf/ui';
import { S } from '@tf/utils';

interface UserAvatarProps {
	userInfo: {
		username: string;
		firstName: string;
		lastName: string;
		imageUrl: string;
		hasImage: boolean;
	};
	size?: number;
	className?: string;
}

export const UserAvatar = forwardRef<HTMLDivElement, UserAvatarProps>(({ userInfo, size = 32 }, ref) => {
	const fullName = `${userInfo.firstName} ${userInfo.lastName}`.trim();
	const initials = S.abbreviate(fullName);
	const hasInitials = initials.length > 0;

	const alt = fullName.replaceAll(' ', '') ? fullName : userInfo.username || '';
	const imgSrc = userInfo.hasImage || !hasInitials ? userInfo.imageUrl : undefined;

	return (
		<Avatar ref={ref} size={size} src={imgSrc} alt={alt} radius="xl" color="brand">
			{hasInitials ? initials : ''}
		</Avatar>
	);
});
