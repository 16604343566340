import { useMutation } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { BackendClient } from '@tf/api';
import type { FileDownloadFn, FileUploadFn } from '@tf/form-builder';

export const fileUpload: FileUploadFn = async (input) => {
	const res = await BackendClient.getInstance().upload({
		SegmentIdentity: input.identity,
		filename: input.file,
	});

	return res.json();
};

export const fileDownload: FileDownloadFn = async (input) => {
	const res = await BackendClient.getInstance().download(input.storageKey);

	return res.arrayBuffer();
};

export const useDownloadFileMutation = () => {
	return useMutation({
		mutationFn: async ({ file }: { file: BackendTypes.FieldFile }) => {
			const res = await BackendClient.getInstance().download(file.storageKey);
			const blob = await res.arrayBuffer();

			const url = URL.createObjectURL(new Blob([blob]));
			const a = document.createElement('a');
			a.href = url;
			a.download = file.filename;
			a.click();
			window.URL.revokeObjectURL(url);
		},
	});
};
