import { useSelector } from '@datagrid/state';

import { monitoringStore } from '@/core/stores';

export const useSelectedMonitoringEntity = () => {
	return useSelector(() => {
		const account = monitoringStore.selected.get();
		if (!account) {
			throw new Error('Entity not found');
		}

		return account;
	});
};
