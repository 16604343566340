import type { BackendTypes } from '@tf/api';
import type { FormStatuses } from '@tf/shared';

export type { AppConfig, AppDefinitions, AppState, AppUser, ComponentState } from './common.js';

export type ExtendedReviewContainer = BackendTypes.EntityReviewContainer & {
	graphLinkId: number;
	graphNodeId: number;
};

export interface FormState {
	id: string;
	statuses: FormStatuses;
}

export enum UserCapabilities {
	CAN_PREPARE_ACCOUNTS_DATA = 'CAN_PREPARE_ACCOUNTS_DATA',
	CAN_REVIEW_ACCOUNTS_DATA = 'CAN_REVIEW_ACCOUNTS_DATA',
	CAN_MANAGE_MONITORING = 'CAN_MANAGE_MONITORING',
	CAN_MANAGE_USERS = 'CAN_MANAGE_USERS',
	CAN_EXPORT_ACCOUNT_DATA = 'CAN_EXPORT_ACCOUNT_DATA',
}

export { MATCH_STATUSES, MatchStatus } from './monitoring.js';
