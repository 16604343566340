import type { BackendTypes } from '@tf/api';

import type { AccountWithReviews } from '@/core/types/account';

// Check if this review belongs to group (like APPLICANT and CO_APPLICANT)
export const isReviewFromConnectionGroup = (
	account: AccountWithReviews,
	reviewLinkId: BackendTypes.GraphId
) => {
	const review = account.reviews.find((r) => r.graphLinkId === reviewLinkId);
	const accountEntity = review?.listEntities.find((e) => e.graphNodeId === account.meta.graphNodeId);
	const reviewGroup = accountEntity?.listConnections.find(
		(c) => c.isReview && c.connectionKind === review?.connectionKind
	)?.reviewGroup;

	return accountEntity?.listConnections.some(
		(c) => !c.isReview && c.connectionKind !== review?.connectionKind && c.reviewGroup === reviewGroup
	);
};
