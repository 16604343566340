import { useEffect } from 'react';

import { useObservable } from '@datagrid/state';
import { useQueryClient } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { TFNotifier } from '@tf/ui';
import type { FormSegment, FormValues, SubmitHandler } from '@tf/utils';
import { rawRevision } from '@tf/utils';

import { accountQueryOptions } from '@/core/api/account';
import { overviewAccountQueryOptions } from '@/core/api/overview';
import { segmentsQueryOptions, useSegmentsQuery } from '@/core/api/segments';
import { useProcessingScripts } from '@/core/hooks/use-processing-scripts';
import { useSelectedAccount } from '@/core/hooks/use-selected-account';
import { writeSegmentsMutation } from '@/core/mutations';

interface State {
	values?: FormValues;
	revisions: Record<string, number>;
}

export const useAccountForm = (segmentKinds: string[], options: { onSuccess: () => void }) => {
	const { meta } = useSelectedAccount();

	const state = useObservable<State>({
		revisions: {},
	});

	const formSegments: FormSegment[] = meta.listSegments
		.filter((s) => {
			return segmentKinds.includes(s.SegmentIdentity.segmentKind);
		})
		.map((s) => ({ ...s.SegmentIdentity, accessMode: s.AccessMode }));

	const segmentsQuery = useSegmentsQuery(formSegments);

	useEffect(() => {
		if (segmentsQuery.data) {
			const nextState: Required<State> = {
				values: {},
				revisions: {},
			};
			for (const segmentData of segmentsQuery.data) {
				const { info, segment } = segmentData.container;
				const segmentKind = info.SegmentIdentity.segmentKind;
				nextState.values[segmentKind] = segment;
				nextState.revisions[segmentKind] = info.Revision.revision;
			}
			state.assign(nextState);
		}
	}, [segmentsQuery.data, state]);

	const queryClient = useQueryClient();
	const handleSubmit: SubmitHandler = async ({ values, segmentValidationStatuses }) => {
		const payload: BackendTypes.SegmentsWritePayload = [];
		for (const formSegment of formSegments) {
			if (formSegment.accessMode === 'VIEW') {
				continue;
			}
			payload.push({
				segmentIdentity: formSegment,
				segment: values[formSegment.segmentKind],
				validationStatus: segmentValidationStatuses[formSegment.segmentKind],
				revision: {
					...rawRevision,
					revision: state.revisions.peek()[formSegment.segmentKind],
				},
			});
		}

		const res = await writeSegmentsMutation(payload);

		// Update revisions
		for (const segmentData of res) {
			const { SegmentIdentity, Revision } = segmentData.container.info;
			const segmentKind = SegmentIdentity.segmentKind;
			state.revisions.set((prev) => ({ ...prev, [segmentKind]: Revision.revision }));
		}

		TFNotifier.success('Account updated successfully!');
		await queryClient.refetchQueries(overviewAccountQueryOptions(meta.graphNodeId));
		await queryClient.refetchQueries(accountQueryOptions(meta.graphNodeId));
		await queryClient.refetchQueries(segmentsQueryOptions(formSegments));
		options.onSuccess();
	};

	const scripts = useProcessingScripts(segmentKinds);

	return { state, scripts, formSegments, handleSubmit };
};
