import { TFPageContent, TFPageHeader } from '@tf/shared';
import { T } from '@tf/utils';

import { appStore } from '@/core/stores';
import { UserCapabilities } from '@/core/types';
import { useCurrentUserCapabilityCheck } from '@/core/utils';
import { UserInvitationForm } from '@/components/settings';

export const UserInvitationRoute = () => {
	const tenant = appStore.tenant?.get();
	T.assertNotNullish(tenant, 'no tenant in appStore');
	const canManageUsers = useCurrentUserCapabilityCheck(UserCapabilities.CAN_MANAGE_USERS);

	return (
		<>
			<TFPageHeader
				tenant={tenant}
				title="User invitation"
				breadcrumbs={[
					...(canManageUsers ? [{ href: '/settings/users', title: 'Workspace settings' }] : []),
					{ href: '/settings/users/invitations', title: 'Invitations' },
					{ href: '/settings/invitations/invite', title: 'Invite' },
				]}
			/>
			<TFPageContent>
				<UserInvitationForm />
			</TFPageContent>
		</>
	);
};
