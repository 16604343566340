import type { BackendTypes } from '@tf/api';

import { capabilities } from './capabilities';

export const getCapabilities = () => {
	return Object.entries(capabilities).map(([value, label]) => {
		return {
			value: value as BackendTypes.RoleCapabilityValue,
			label,
		};
	});
};
