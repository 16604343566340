import React from 'react';

import type { BackendTypes } from '@tf/api';
import { Modal, useDisclosure } from '@tf/ui';
import { S } from '@tf/utils';

import { TFBadge, TFBadgeColors } from '@/components/shared';

import { RiskProfileForm } from './RiskProfileForm';

const riskLevelColors: Record<BackendTypes.OverviewAccountRiskLevel, TFBadgeColors> = {
	VERY_HIGH: TFBadgeColors.RED,
	HIGH: TFBadgeColors.ORANGE,
	MEDIUM: TFBadgeColors.YELLOW,
	LOW: TFBadgeColors.GREEN,
};

interface Props {
	clientRiskLevel: BackendTypes.OverviewAccountRiskLevel | undefined;
	readonly: boolean;
}

export const RiskProfile: React.FC<Props> = ({ clientRiskLevel, readonly }) => {
	const riskProfileModal = useDisclosure();

	return (
		<>
			<TFBadge
				text={clientRiskLevel ? S.prettify(clientRiskLevel) : 'Not set'}
				disabled={readonly}
				color={clientRiskLevel ? riskLevelColors[clientRiskLevel] : TFBadgeColors.DIMMED}
				onClick={riskProfileModal.open}
			/>

			<Modal
				opened={riskProfileModal.isOpen}
				onClose={riskProfileModal.close}
				title="Customer risk profile"
			>
				<RiskProfileForm onClose={riskProfileModal.close} />
			</Modal>
		</>
	);
};
