import { TFPageContent, TFPageHeader } from '@tf/shared';
import { T } from '@tf/utils';

import { appStore } from '@/core/stores';
import { EntitiesTable } from '@/components/entities';

export const EntityListRoute = () => {
	const tenant = appStore.tenant?.get();
	T.assertNotNullish(tenant, 'no tenant in appStore');

	return (
		<>
			<TFPageHeader tenant={tenant} title="Entities" />
			<TFPageContent>
				<EntitiesTable />
			</TFPageContent>
		</>
	);
};
