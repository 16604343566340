import { useNavigate } from 'react-router-dom';

import { TFPageContent, TFPageHeader } from '@tf/shared';
import { T } from '@tf/utils';

import { appStore } from '@/core/stores';
import { UserCapabilities } from '@/core/types';
import { useCurrentUserCapabilityCheck } from '@/core/utils';
import { MonitoringTable } from '@/components/monitoring';

export const MonitoringRoute = () => {
	const navigate = useNavigate();
	const tenant = appStore.tenant?.get();
	T.assertNotNullish(tenant, 'no tenant in appStore');

	if (!useCurrentUserCapabilityCheck(UserCapabilities.CAN_MANAGE_MONITORING)) {
		navigate('/');
	}

	return (
		<>
			<TFPageHeader tenant={tenant} title="Monitoring" />
			<TFPageContent>
				<MonitoringTable />
			</TFPageContent>
		</>
	);
};
