import type { PrimitiveField } from '@/components/shared';
import { FieldName } from '@/components/shared';

const fields = {
	name: {
		name: 'name',
		component: FieldName.TextField,
		label: 'Name',
	},
	accountId: {
		name: 'accountId',
		component: FieldName.TextField,
		label: 'Account ID',
	},
	priority: {
		name: 'priority',
		component: FieldName.SelectField,
		label: 'Priority',
		props: {
			options: [
				{ value: 'LOW', label: 'Low' },
				{ value: 'MEDIUM', label: 'Medium' },
				{ value: 'HIGH', label: 'High' },
			],
		},
	},
};

export const formStructure: PrimitiveField[] = [fields.name, fields.accountId, fields.priority];
