import { BackendClient } from '@tf/api';

interface MutationInput {
	accountId: number;
	ids: string[];
}

export const setAccountAssigneesMutation = async ({
	accountId,
	ids,
}: MutationInput): Promise<boolean> => {
	const res = await BackendClient.getInstance().setAssignedManagers(accountId, ids);
	return res.ok;
};
