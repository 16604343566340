import React, { useEffect } from 'react';

import { batch, observer, Switch, useObservable } from '@datagrid/state';
import { useQuery } from '@tanstack/react-query';

import { TFLoadingOverlay } from '@tf/ui';

import { configStore } from '@/core/stores';
import type { AppConfig, ComponentState } from '@/core/types';

export const ConfigProvider: React.FC<React.PropsWithChildren> = observer(({ children }) => {
	const state = useObservable<ComponentState>({ status: 'loading' });

	const configQuery = useQuery({
		queryKey: ['config'],
		queryFn: () => {
			return fetch(`/env.json?ts=${Date.now()}`).then((res) => {
				if (res.status !== 200) throw new Error('Invalid response');
				return res.json() as Promise<AppConfig>;
			});
		},
	});

	useEffect(() => {
		switch (configQuery.status) {
			case 'success': {
				const config = configQuery.data;
				if (!config) {
					state.set({ status: 'error', message: 'No config provided' });
					return;
				}
				batch(() => {
					state.set({ status: 'ready' });
					configStore.assign(config);
				});
				break;
			}
			case 'error': {
				const error = configQuery.error;
				const message = error instanceof Error ? error.message : 'Unknown error';
				state.set({ status: 'error', message });
				break;
			}
		}
	}, [configQuery.data, configQuery.error, configQuery.status, state]);

	return (
		<Switch value={state.status}>
			{{
				ready: () => children,
				error: () => <div>Error: {state.message?.get()}</div>,
				loading: () => <TFLoadingOverlay />,
			}}
		</Switch>
	);
});
