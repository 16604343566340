import { useAccount } from '@/core/api/account';
import { useAccountParams } from '@/core/hooks';

export const useSelectedReview = () => {
	const { connectionId } = useAccountParams();
	const { accountId } = useAccountParams();
	const { data } = useAccount(accountId);

	if (!data) {
		throw new Error('Account not found');
	}

	const review = data.reviews.find((r) => r.graphLinkId === connectionId);
	if (!review) {
		throw new Error('Review not found');
	}

	return review;
};
