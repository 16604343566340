import { useMemo, useState } from 'react';

import { useSelector } from '@datagrid/state';

import type { BackendTypes } from '@tf/api';
import { createModal, Select, useModalControl } from '@tf/ui';
import { type SubmitHandler, T } from '@tf/utils';

import { useCreateAccountMutation } from '@/core/api/account';
import { useProcessingScripts } from '@/core/hooks';
import { appStore } from '@/core/stores';
import { AppFormBuilder, ModalFooter } from '@/components/shared';

interface Props {
	onSuccess: (res: { connectedEntityId: BackendTypes.GraphId }) => void;
	onError: (error: Error) => void;
}

export const AddAccountModal = createModal(({ onSuccess, onError }: Props) => {
	const { onClose } = useModalControl();

	const defs = useSelector(() => appStore.defs.get());

	const accountKinds = (defs.options['AccountKind'] || []).sort((a, b) =>
		a.value.localeCompare(b.value)
	);
	const [selectedAccountKind, setSelectedAccountKind] = useState<string | null>(
		accountKinds[0]?.value ?? null
	);

	const selfSegments = useMemo(() => {
		if (!selectedAccountKind) {
			return null;
		}

		// Applicant connection always exist and have exactly one entity kind that can be connected with this connection
		const applicantEntityKind = defs.entities[selectedAccountKind].possibleConnections.find(
			(c) => c.kind === 'APPLICANT'
		)?.fromEntities[0]?.kind;
		T.assertNotNullish(
			applicantEntityKind,
			`applicant entity not found for account ${selectedAccountKind}`
		);

		return {
			account: defs.selfSegments[selectedAccountKind],
			entity: defs.selfSegments[applicantEntityKind],
		};
	}, [defs.entities, defs.selfSegments, selectedAccountKind]);

	const scripts = useProcessingScripts(selfSegments?.entity ? [selfSegments.entity] : []);

	const createAccountMutation = useCreateAccountMutation();
	const submitHandler: SubmitHandler = async ({ values }) => {
		if (!selectedAccountKind || !selfSegments?.entity) {
			onError(new Error('No account kind selected'));
			return;
		}

		createAccountMutation.mutate(
			{
				accountKind: selectedAccountKind,
				entitySelfSegmentKind: selfSegments.entity,
				entitySelfSegmentData: values[selfSegments.entity],
			},
			{
				onSuccess,
				onError,
			}
		);
	};

	return (
		<>
			<Select
				m="0.75rem"
				label="Account type"
				placeholder="Select an account type"
				data={accountKinds}
				data-autofocus
				value={selectedAccountKind}
				onChange={setSelectedAccountKind}
			/>

			{selfSegments?.entity && (
				<AppFormBuilder
					segments={[{ segmentKind: selfSegments.entity, graphId: 0, accessMode: 'MODIFY' }]}
					processingScripts={scripts}
					isLoading={createAccountMutation.isPending}
					onSubmit={submitHandler}
					styles={{ content: { padding: '0 .75rem .25rem' } }}
					shouldValidate
					submitButtonRenderer={(isLoading) => (
						<ModalFooter submitText="Create account" isDisabled={isLoading} onCancel={onClose} />
					)}
				/>
			)}
		</>
	);
});
