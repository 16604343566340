import React from 'react';

import { useSelector } from '@datagrid/state';
import { useParams, useSearchParams } from 'react-router-dom';

import { TFPageContent, TFPageHeader } from '@tf/shared';
import { Group } from '@tf/ui';
import { T } from '@tf/utils';

import { useSelectedMonitoringEntity } from '@/core/hooks';
import { appStore } from '@/core/stores';
import { AcknowledgeChangesButton, MonitoringReportButton } from '@/components/monitoring';
import { MonitoringMatchCard } from '@/components/monitoring/MonitoringCard/MonitoringCardMatches';

interface Props {
	hit: any | null;
}

export const MonitoringMatchWrapper: React.FC<Props> = ({ hit }) => {
	const [searchParams] = useSearchParams();
	const params = useParams<{ graphId: string }>();
	const graphId = parseInt(params.graphId as string, 10);
	const entityData = useSelectedMonitoringEntity();
	const tenant = useSelector(() => appStore.tenant?.get());
	T.assertNotNullish(tenant, 'no tenant in appStore');

	return (
		<>
			<TFPageHeader
				tenant={tenant}
				sx={() => ({
					marginBottom: 0,
				})}
				title={hit?.key_information?.name || 'N/A'}
				breadcrumbs={[
					{ href: '/monitoring', title: 'Monitoring' },
					{ href: `/monitoring/${graphId}?${searchParams.toString()}`, title: entityData.name || 'N/A' },
					{
						href: `/monitoring/${graphId}/matches/${hit?.id}?${searchParams.toString()}`,
						title: hit?.key_information?.name || 'N/A',
					},
				]}
				toolbar={
					<Group>
						<MonitoringReportButton />
						<AcknowledgeChangesButton graphId={graphId} />
					</Group>
				}
			/>
			<TFPageContent>
				<MonitoringMatchCard hit={hit} />
			</TFPageContent>
		</>
	);
};
