import { queryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { BackendClient } from '@tf/api';
import { TFNotifier } from '@tf/ui';

const usersInvitationsQueryOptions = () => {
	return queryOptions({
		queryKey: ['invitations'],
		queryFn: async (): Promise<BackendTypes.GetUsersInvitationsData> => {
			const api = BackendClient.getInstance();
			const res = await api.getUsersInvitations();
			return res.json();
		},
		staleTime: 0,
	});
};

export const useUsersInvitations = () => {
	return useQuery(usersInvitationsQueryOptions());
};

export const useRevokeUserInvitationMutation = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({
			email,
		}: {
			email: string;
		}): Promise<BackendTypes.RevokeUsersInvitationData> => {
			await BackendClient.getInstance().revokeUsersInvitation({ email });
		},
		onSuccess: () => {
			return queryClient.invalidateQueries(usersInvitationsQueryOptions());
		},
		onError: () => {
			TFNotifier.error('Failed to revoke invitation. Please try again');
		},
	});
};

const usersQueryOptions = () => {
	return queryOptions({
		queryKey: ['users'],
		queryFn: async (): Promise<BackendTypes.GetUsersData> => {
			const res = await BackendClient.getInstance().getUsers();
			return res.json();
		},
		staleTime: 20 * 1000,
	});
};

export const useUsers = () => {
	return useQuery(usersQueryOptions());
};

export const useUserInvitationMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (
			invites: BackendTypes.UserInvitation[]
		): Promise<BackendTypes.CreateUsersInvitationData> => {
			const api = BackendClient.getInstance();

			await Promise.all(invites.map((invite) => api.createUsersInvitation(invite)));
		},
		onSuccess: async () => {
			await Promise.all([
				queryClient.refetchQueries({ queryKey: ['invitations'] }),
				queryClient.refetchQueries({ queryKey: ['overviewAccounts'] }),
			]);
		},
	});
};

export const useUpdateUserMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({
			id,
			data,
		}: {
			id: string;
			data: BackendTypes.UserUpdate;
		}): Promise<BackendTypes.UpdateUserData> => {
			await BackendClient.getInstance().updateUser(id, data);
		},
		onSuccess: () => {
			return queryClient.refetchQueries({ queryKey: ['users'] });
		},
	});
};
