import { useState } from 'react';

import { useSelector } from '@datagrid/state';
import { Link } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import {
	createStyles,
	EntityBadge,
	Flex,
	TFDataTable,
	TFText,
	Tooltip,
	UnstyledButton,
	useLocalStorage,
} from '@tf/ui';
import { fmt, S } from '@tf/utils';

import { useEntities } from '@/core/api/entities';
import { appStore } from '@/core/stores';

const useStyles = createStyles(({ colors }) => ({
	entityName: {
		fontSize: 13,
		height: 32,
		display: 'flex',
		alignItems: 'center',
		color: colors.brand[6],
		'&:hover': {
			color: colors.brand[6],
			textDecoration: 'underline',
		},
	},
}));

const filtersNamesMap: Record<string, string> = {
	kind: 'accountType',
	'values.accountStatus': 'accountStatus',
	verification: 'verificationStatus',
	importance: 'priority',
};

const sortingNamesMap: Record<string, string> = {
	name: 'ENTITY_NAME',
	kind: 'ENTITY_TYPE',
	createdAt: 'CREATED_AT',
};

export const EntitiesTable = () => {
	const { classes } = useStyles();
	const entityKinds = useSelector(() => appStore.defs.options['EntityKind'].get());

	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0 });

	const [filtersState, setFiltersState] = useLocalStorage<
		{
			id: string;
			value: unknown;
		}[]
	>({
		defaultValue: [],
		key: 'entities-table-filters',
		getInitialValueInEffect: false,
	});

	const [sortingState, setSortingState] = useLocalStorage<
		{
			desc: boolean;
			id: string;
		}[]
	>({
		defaultValue: [{ desc: true, id: 'ENTITY_NAME' }],
		key: 'entities-table-sorting',
		getInitialValueInEffect: false,
	});

	const sortingParams =
		sortingState.length > 0
			? {
					sortBy: sortingNamesMap[sortingState[0].id] as
						| BackendTypes.EntityObserverItemSortBy
						| undefined,
					sortOrder: sortingState[0].desc ? 'DESC' : ('ASC' as BackendTypes.EntityObserverItemSortOrder),
			  }
			: undefined;

	const filteringParams = filtersState.reduce<Record<string, any>>((acc, curr) => {
		acc[filtersNamesMap[curr.id]] = curr.value;
		return acc;
	}, {});

	const { data: entities, isPending } = useEntities({
		page: pagination.pageIndex + 1,
		limit: pagination.pageSize,
		...filteringParams,
		...sortingParams,
	});

	return (
		<TFDataTable<BackendTypes.EntityObserverItem>
			name="entites-list"
			isLoading={isPending}
			serverSorting={{
				onSortingChange: setSortingState,
				state: sortingState,
			}}
			serverFiltering={{
				onFiltersChange: setFiltersState,
				state: filtersState,
			}}
			serverPagination={
				entities
					? {
							...pagination,
							rowCount: entities.count,
							onPaginationChange: setPagination,
					  }
					: undefined
			}
			data={entities?.items ?? []}
			defs={[
				{
					header: 'Entity name',
					accessorKey: 'name',
					size: 200,
					Cell: ({ cell }) => {
						const { graphNodeId, name } = cell.row.original;
						return (
							<UnstyledButton
								component={Link}
								to={`/entities/${graphNodeId}`}
								className={classes.entityName}
							>
								<TFText inherit>{name || 'N/A'}</TFText>
							</UnstyledButton>
						);
					},
				},
				{
					header: 'Entity type',
					accessorKey: 'entityKind',
					Cell: ({ cell }) => {
						const { entityKind } = cell.row.original;
						return <EntityBadge kind={entityKind} />;
					},
				},
				{
					header: 'Entity status',
					accessorKey: 'connectionStatus',
					enableSorting: false,
					accessorFn: (item) => S.prettify(item.connectionStatus || ''),
				},
				{
					header: 'Date created',
					accessorKey: 'createdAt',
					accessorFn: (item) => fmt.toDate(item.createdAt, { preset: 'full_date_with_time' }),
				},
				{
					header: 'Relations',
					accessorKey: 'relations',
					enableSorting: false,
					Cell: ({ cell }) => {
						const { relations } = cell.row.original;
						if (relations.length === 0) {
							return <TFText inherit>None</TFText>;
						}
						if (relations.length === 1) {
							return <TFText inherit>{relations[0].name}</TFText>;
						}
						return (
							<Flex align="center">
								{relations[0].name}
								<Tooltip label={relations.map((r) => r.name).join(', ')} position="top" withArrow>
									<TFText inherit ml="4px" c="#2563EB">
										and more
									</TFText>
								</Tooltip>
							</Flex>
						);
					},
				},
			]}
			availableFilters={[
				{
					name: 'connectionStatus',
					label: 'Entity status',
					type: 'select',
					value: [
						{ value: 'ACTIVE', label: 'Active' },
						{ value: 'INACTIVE', label: 'Inactive' },
						{ value: 'ARCHIVED', label: 'Archived' },
					],
				},
				{
					name: 'entityKind',
					label: 'Entity type',
					type: 'select',
					value: entityKinds,
				},
			]}
		/>
	);
};
