import { Icon } from '@tf/ui';

interface Props {
	kind: string;
	size?: number;
}

export const EntityKindIcon: React.FC<Props> = ({ kind, size = 14 }) => {
	if (kind === 'INDIVIDUAL_ENTITY') {
		return <Icon.IconUser size={size} />;
	}

	return <Icon.IconBuildingSkyscraper size={size} />;
};
