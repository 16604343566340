import React from 'react';

import type { BackendTypes } from '@tf/api';
import { Box, createStyles, Input, Modal, TFNotifier, Tooltip, useDisclosure } from '@tf/ui';

import { useUpdateClientCaseRequestMutation } from '@/core/api/caseRequests';
import { useAccountParams } from '@/core/hooks';
import { ModalFooter } from '@/components/shared';

import { isValidEmail } from './utils';

const useStyles = createStyles(() => ({
	button: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		fontWeight: 600,
		borderBottom: '1px dotted',
		color: '#155EEF',
		cursor: 'pointer',
	},
	tooltipContent: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
}));

interface Props {
	email: string;
	requestId: string;
	status: string;
	data: Partial<BackendTypes.UpdateClientCaseRequest>;
}

export const ClientEmail: React.FC<Props> = ({ email, requestId, status, data }: any) => {
	const { accountId } = useAccountParams();
	const { close, isOpen, toggle } = useDisclosure();
	const { classes } = useStyles();

	const updateClientCaseMutation = useUpdateClientCaseRequestMutation(accountId);

	const [fieldsState, setFieldsState] = React.useState({
		email,
		emailError: '',
	});

	const handleSubmit = async () => {
		if (!isValidEmail(fieldsState.email)) {
			setFieldsState((prevState) => ({ ...prevState, emailError: 'Invalid email' }));
			return;
		}
		await updateClientCaseMutation.mutateAsync(
			{
				accountId,
				requestId,
				data: {
					...data,
					clientEmail: fieldsState.email,
				},
			},
			{
				onSuccess: () => {
					TFNotifier.success('Email updated');
					close();
				},
			}
		);
	};

	if (status !== 'DRAFT') {
		return (
			<Tooltip label={email} position="top">
				<Box className={classes.tooltipContent}>{email}</Box>
			</Tooltip>
		);
	}

	return (
		<>
			<Modal title="Update email" opened={isOpen} withinPortal onClose={toggle}>
				<form
					onSubmit={async (e) => {
						e.preventDefault();
						await handleSubmit();
					}}
				>
					<Input.Wrapper p="1rem" required label="Email">
						<Input
							error={fieldsState.emailError}
							placeholder="Enter email"
							value={fieldsState.email}
							onChange={(e) => {
								const email = e.target.value;
								setFieldsState(() => ({ email, emailError: '' }));
							}}
						/>
					</Input.Wrapper>
					<ModalFooter
						onSubmit={handleSubmit}
						submitText="Update"
						isDisabled={updateClientCaseMutation.isPending}
						onCancel={close}
					/>
				</form>
			</Modal>

			<Tooltip label={email} position="top">
				<Box onClick={toggle} className={classes.button}>
					{email}
				</Box>
			</Tooltip>
		</>
	);
};
