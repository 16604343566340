import React from 'react';

import type { BackendTypes } from '@tf/api';
import { Box, Button, Group, Icon, TFCard } from '@tf/ui';
import type { FormSegment } from '@tf/utils';

import { UserCapabilities } from '@/core/types';
import { useCurrentUserCapabilityCheck } from '@/core/utils';
import { FormStatusLabelBase } from '@/components/reviews/ReviewForm/FormStatusLabel';

interface Props {
	isLoading: boolean;
	graphId: number;
	segments: FormSegment[];
	status?: BackendTypes.FieldValidationStatus;
}

export const EntityFormFooter: React.FC<Props> = ({ isLoading, segments, status }) => {
	const canPrepareAccountsData = useCurrentUserCapabilityCheck(
		UserCapabilities.CAN_PREPARE_ACCOUNTS_DATA
	);

	const isReadOnly = segments.every((s) => s.accessMode === 'VIEW');

	return (
		<TFCard innerPadding=".75rem 1rem">
			<Group
				gap="md"
				sx={{
					display: 'flex',
					flexWrap: 'nowrap',
					justifyContent: 'end',
					alignItems: 'center',
				}}
			>
				<Box sx={{ flexGrow: 1, flexShrink: 1, minWidth: 0 }}>
					<FormStatusLabelBase status={status} />
				</Box>
				{canPrepareAccountsData && !isReadOnly && (
					<Button loading={isLoading} leftSection={<Icon.IconRefresh size={16} />} type="submit">
						Save
					</Button>
				)}
			</Group>
		</TFCard>
	);
};
