import { useAccount } from '@/core/api/account';
import { useAccountParams } from '@/core/hooks/use-account-params';

export const useSelectedAccount = () => {
	const { accountId } = useAccountParams();
	const { data } = useAccount(accountId);

	if (!data) {
		throw new Error('Account not found');
	}

	return data;
};
