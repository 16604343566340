import { Outlet, useParams } from 'react-router-dom';

import { MonitoringCard, MonitoringProvider } from '@/components/monitoring';

export const MonitoringCardLayout = () => {
	const params = useParams<{ graphId: string }>();
	const graphId = parseInt(params.graphId as string, 10);

	return (
		<>
			<MonitoringProvider entityId={graphId}>
				<MonitoringCard>
					<Outlet />
				</MonitoringCard>
			</MonitoringProvider>
		</>
	);
};
