import { useAccountParams, useSelectedAccount } from '@/core/hooks';

import { AccountEntityWidget } from './AccountEntityWidget';

export const Menu = () => {
	const { reviews } = useSelectedAccount();
	const { connectionId, entityId } = useAccountParams();

	const review = reviews.find((r) => r.graphLinkId === connectionId);
	const entity = review?.listEntities.find((e) => e.graphNodeId === entityId);
	if (!entity || !review) {
		throw new Error('Entity not found');
	}

	return <AccountEntityWidget entity={entity} review={review} />;
};
