import React, { useEffect, useState } from 'react';

import { useMutation } from '@tanstack/react-query';
import type { FC } from 'react';

import type { BackendTypes } from '@tf/api';
import { Modal, Select, TFNotifier } from '@tf/ui';

import { monitoringSearchMatchStatusUpdate } from '@/core/mutations';
import { MATCH_STATUSES } from '@/core/types';
import { MonitoringCardComments } from '@/components/monitoring';

interface Props {
	initialMatchStatus: string;
	entityId: number;
	matchId: string;
}

export const MonitoringCardMatchStatus: FC<Props> = ({ initialMatchStatus, entityId, matchId }) => {
	const [currentStatus, setCurrentStatus] = useState<string | null>(null);
	const [isOpen, setIsOpen] = useState(false);
	const toggleModal = () => setIsOpen(!isOpen);

	const updateMatchStatus = useMutation({
		mutationKey: ['update-status-match', entityId, matchId],
		mutationFn: ({ matchStatus }: any) => {
			return monitoringSearchMatchStatusUpdate({
				graphNodeId: entityId,
				data: {
					matchIds: [matchId],
					matchStatus,
				},
			});
		},
	});

	const updateStatus = async (value: BackendTypes.MonitoringSearchMatchStatus) => {
		try {
			await updateMatchStatus.mutateAsync({
				matchStatus: value,
			});
			setCurrentStatus(value);
			setIsOpen(true);
		} catch (e) {
			TFNotifier.error('Could not update match status');
		}
	};

	useEffect(() => {
		setCurrentStatus(initialMatchStatus);
	}, [initialMatchStatus]);

	if (!isOpen) {
		return (
			<Select
				value={currentStatus}
				data={MATCH_STATUSES}
				onChange={(value) => {
					if (value) {
						updateStatus(value as BackendTypes.MonitoringSearchMatchStatus);
					}
				}}
			/>
		);
	}

	return (
		<>
			<Select
				value={currentStatus}
				data={MATCH_STATUSES}
				onChange={(value) => {
					if (value) {
						updateStatus(value as BackendTypes.MonitoringSearchMatchStatus);
					}
				}}
			/>
			<Modal title="Comments" size={600} opened onClose={toggleModal}>
				<MonitoringCardComments entityId={entityId} matchId={matchId} />
			</Modal>
		</>
	);
};
