import { useSelector } from '@datagrid/state';

import { useAccountInfoSegments } from "@/core/hooks/use-account-info-segments";

export const useSelectedAccountRmData = () => {
	const { state } = useAccountInfoSegments();
	return useSelector(() => {
		if (!state.values?.get()) {
			return {
				client_account_status: null,
				account_id: null,
				onboarding_date: null,
				closing_date: null
			}
		}

		return state.values?.get()?.COMMON__RM_FEEDBACK;
	});
};
