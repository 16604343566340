import { Outlet } from 'react-router-dom';

import { TFPageContent } from '@tf/shared';

import { AccountAlert } from './AccountAlert';
import { AccountTabs } from './AccountTabs';

export const AccountTabsLayout = () => {
	return (
		<>
			<AccountTabs />
			<AccountAlert />
			<TFPageContent>
				<Outlet />
			</TFPageContent>
		</>
	);
};
