import { Outlet } from 'react-router-dom';

import { useAccountParams, useSelectedAccount } from '@/core/hooks';
import { EmptyConnectionScreen, SideMenuLayout } from '@/components/shared';

import { Menu } from './Menu';

export const AccountReviewLayout = () => {
	const account = useSelectedAccount();
	const params = useAccountParams();
	const reviews = account.reviews.filter((r) => r.connectionKind === params.connectionKind);

	if (reviews.length === 0) return <EmptyConnectionScreen />;

	return (
		<SideMenuLayout menu={<Menu reviews={reviews} />}>
			<Outlet />
		</SideMenuLayout>
	);
};
