import React from 'react';

import { enableState } from '@datagrid/state';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import { DGLayout } from '@tf/ui';

import { AmplitudeInitialization, AppProvider, SentryInitialization } from '@/core/providers';

import { App } from './App';

enableState();

(window as any).APP_VERSION = import.meta.env.VITE_APP_VERSION;

createRoot(document.getElementById('root') as HTMLElement).render(
	<Sentry.ErrorBoundary
		beforeCapture={(scope) => {
			scope.setTag('location', 'global');
		}}
		fallback={() => <VanillaInitializationError />}
	>
		<DGLayout>
			<AppProvider>
				<SentryInitialization />
				<AmplitudeInitialization />
				<App />
			</AppProvider>
		</DGLayout>
	</Sentry.ErrorBoundary>
);

// we don't have a Mantine/Emotion provider at the top-level error boundary, so we're using plain css for this component
const VanillaInitializationError = () => {
	return (
		<div style={styles.container}>
			<div style={styles.errorBox}>
				<h3 style={styles.title}>Initialisation Error</h3>
				<p style={styles.message}>An error occurred during initialisation of the application.</p>
				<p style={styles.message}>
					If it repeats, please email us at{' '}
					<a href="mailto:support@trustform.io" style={styles.link}>
						support@trustform.io
					</a>
				</p>
			</div>
		</div>
	);
};

const styles: Record<string, React.CSSProperties> = {
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
		backgroundColor: '#fff',
		fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif',
	},
	errorBox: {
		minWidth: '480px',
		maxWidth: '640px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: '1rem',
		color: '#000',
		border: '0.5px solid #E5E7EB',
		backgroundColor: 'white',
		padding: '1.5rem 1.25rem',
		borderRadius: '8px',
		boxShadow: '0 4px 20px -4px rgb(0 0 0 / 10%)',
	},
	title: {
		fontSize: '18px',
		fontWeight: 600,
		margin: '0 0 8px 0',
		padding: '0',
		color: '#333',
	},
	message: {
		fontSize: '14px',
		margin: '0',
		padding: '0',
		color: '#4B5563',
	},
	link: {
		color: '#2563EB',
		textDecoration: 'none',
	},
};
