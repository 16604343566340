import type { BackendTypes } from '@tf/api';
import type { ExtendedFormDefinition } from '@tf/shared';

const isSelfSegment = (segment: BackendTypes.SegmentIdentity, selfSegmentKinds: string[]) =>
	selfSegmentKinds.includes(segment.segmentKind);

const getSegmentWithSelfSegmentDataDependency = (
	form: ExtendedFormDefinition<BackendTypes.FormDefinition>
) => form.segmentIdentities.find((s) => s.segmentKind === 'COMMON__BACKGROUND_CHECK');

export const getAdditionalDataForBackgroundCheck = (
	segmentsData: BackendTypes.SegmentData[],
	form: ExtendedFormDefinition<BackendTypes.FormDefinition>,
	selfSegmentsKinds: string[]
) => {
	const selfSegment = segmentsData.find(
		(s) =>
			s.container.info.SegmentIdentity.graphId ===
				getSegmentWithSelfSegmentDataDependency(form)?.graphId &&
			isSelfSegment(s.container.info.SegmentIdentity, selfSegmentsKinds)
	);

	if (!selfSegment) {
		return;
	}

	const segmentData: any = selfSegment.container.segment;
	if (selfSegment.container.info.SegmentIdentity.segmentKind === 'INDIVIDUAL_ENTITY__SELF') {
		return {
			firstName: segmentData.first_name,
			lastName: segmentData.last_name,
			middleName: segmentData.middle_name,
		} as BackendTypes.AdhocSearchPersonParams;
	}

	return {
		entityName: segmentData.name,
	} as BackendTypes.AdhocSearchEntityParams;
};
