interface UserNameInfo {
	username: string;
	firstName: string;
	lastName: string;
}

export const getDisplayName = (user: UserNameInfo) => {
	const fullName = `${user.firstName} ${user.lastName}`.trim();

	return fullName || user.username;
};
