import React from 'react';

import { useSelector } from '@datagrid/state';
import { useNavigate, useParams } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import type { ExtendedFormDefinition } from '@tf/shared';
import { extractForms, getEntityFormStatus } from '@tf/shared';
import { Stack } from '@tf/ui';

import { appStore } from '@/core/stores';
import { EntityKindIcon, SideMenu, SideMenuItem } from '@/components/shared';
import { FormStatusIndicatorBase } from '@/components/shared/FormStatusIndicator';

interface Props {
	entity: BackendTypes.Entity;
	segmentsReadData: BackendTypes.SegmentsReadData;
}

export const EntityWidget: React.FC<Props> = ({ entity, segmentsReadData }) => {
	const navigate = useNavigate();
	const params = useParams<{ formKind: string }>();

	const formDefs = useSelector(() => appStore.defs.get().forms);
	const forms = extractForms({
		formDefs,
		segmentIdentities: entity.listSegments.map((s) => s.SegmentIdentity),
	});

	const navigateToForm = (form: ExtendedFormDefinition<BackendTypes.FormDefinition>) => {
		window.scrollTo(0, 0);
		navigate(`/entities/${entity.graphNodeId}/${form.name}`);
	};

	return (
		<Stack gap={0} mr=".75rem">
			<SideMenu>
				<SideMenuItem
					onClick={() => navigate(`/entities/${entity.graphNodeId}`)}
					label={entity.name}
					leftSection={<EntityKindIcon kind={entity.entityKind} />}
				/>

				{forms.map((form) => {
					const formStatus = getEntityFormStatus({
						segmentsData: segmentsReadData,
						segmentIdentities: form.segmentIdentities,
					});
					return (
						<SideMenuItem
							key={form.label}
							label={form.label}
							isSelected={form.name === params.formKind}
							rightSection={<FormStatusIndicatorBase status={formStatus} />}
							onClick={() => navigateToForm(form)}
						/>
					);
				})}
			</SideMenu>
		</Stack>
	);
};
