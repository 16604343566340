import React from 'react';

import { useAuth } from '@clerk/clerk-react';
import { useSelector } from '@datagrid/state';

import { TFLoadingOverlay } from '@tf/ui';

import { appStore } from '@/core/stores';
import { Router } from '@/routes/index';

export const App = () => {
	const appState = useSelector(() => appStore.state.get());
	const { isSignedIn } = useAuth();
	const isLoading = isSignedIn === undefined || (isSignedIn && appState === 'unauthorized');
	if (isLoading) return <TFLoadingOverlay />;

	return <Router />;
};
