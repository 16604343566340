import React, { useEffect, useState } from 'react';

import { SignUp, useAuth, useClerk } from '@clerk/clerk-react';
import type { ClerkAPIError } from '@clerk/types';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import { TFErrorTemplate, useSignInWithTicket } from '@tf/shared';
import { Anchor, Center, TFLoadingOverlay, TFText } from '@tf/ui';
import { S } from '@tf/utils';

interface ClerkAPIErrorResponse {
	errors: ClerkAPIError[];
}

const clerkDefaultError: ClerkAPIError = {
	code: 'unknown invitation error',
	message: 'Error accepting invitation',
	longMessage: 'Try to sign in or ask your administrator to send an invitation again',
};

export const AcceptInvitationRoute: React.FC = () => {
	const [params] = useSearchParams();
	const clerkStatus = params.get('__clerk_status');
	const clerkTicket = params.get('__clerk_ticket');

	const [state, setState] = useState<'sign_in' | 'sign_up' | 'complete' | ClerkAPIError>(
		!!clerkTicket &&
			(clerkStatus === 'sign_in' || clerkStatus === 'sign_up' || clerkStatus === 'complete')
			? clerkStatus
			: clerkDefaultError
	);

	const navigate = useNavigate();
	const signInWithTicket = useSignInWithTicket();
	const { signOut } = useClerk();
	const { isSignedIn } = useAuth();

	useEffect(() => {
		const logout = async () => {
			if (isSignedIn) {
				await signOut({ redirectUrl: window.location.href });
			}
		};
		logout();
	}, [isSignedIn]);

	useEffect(() => {
		if (!clerkTicket || state !== 'sign_in' || !signInWithTicket) {
			return;
		}

		signInWithTicket(clerkTicket)
			.then(() => {
				navigate('/');
			})
			.catch((data: ClerkAPIErrorResponse) => {
				const error = data.errors[0] ?? clerkDefaultError;

				if (error?.code === 'organization_invitation_already_accepted') {
					setState('sign_in');
					navigate('/sign-in');
					return;
				}

				setState(error);
			});
	}, [clerkTicket, navigate, signInWithTicket, state]);

	if (state === 'sign_in' || isSignedIn) {
		return <TFLoadingOverlay />;
	}

	if (state === 'sign_up') {
		return (
			<Center mt="20vh">
				<SignUp routing="path" path="/accept-invite" signInForceRedirectUrl={window.location.origin} />
			</Center>
		);
	}

	// temporary Clerk invitations fix
	if (state === 'complete') {
		return <Navigate to="/sign-in" />;
	}

	return (
		<TFErrorTemplate
			title={clerkDefaultError.message}
			text={state.longMessage || S.prettify(state.message) || clerkDefaultError.longMessage}
		>
			<TFText size="0.75rem" c="gray.3">
				Error code: {state.code}
			</TFText>
			<Anchor component={Link} to="/sign-in">
				Go to Sign in
			</Anchor>
		</TFErrorTemplate>
	);
};
