import React, { useState } from 'react';

import { useSelector } from '@datagrid/state';
import { Link, useParams } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import {
	Box,
	createStyles,
	TFDataTable,
	TFText,
	UnstyledButton,
	useDebouncedValue,
	useLocalStorage,
} from '@tf/ui';
import { fmt, S } from '@tf/utils';

import { useEntityAssociations } from '@/core/api/entities';
import { useOverviewData } from '@/core/api/overview';
import { appStore } from '@/core/stores';
import { getDisplayName } from '@/core/utils';
import {
	AssignedManagersView,
	HiddenAccountsNotice,
	PriorityView,
	ReviewState,
} from '@/components/shared';
import { getAccountsFilters } from '@/components/utils';

const useStyles = createStyles(({ colors }) => ({
	accountName: {
		fontSize: 13,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		color: colors.gray[9],
		'&:hover': {
			color: colors.brand[6],
			textDecoration: 'underline',
		},
	},
}));

const filtersNamesMap: Record<string, string> = {
	kind: 'accountType',
	'values.accountStatus': 'accountStatus',
	verificationStatus: 'verificationStatus',
	accountStatus: 'accountStatus',
	importance: 'priority',
};

const sortingNamesMap: Record<string, string> = {
	name: 'ACCOUNT_NAME',
	kind: 'ACCOUNT_TYPE',
	'values.accountStatus': 'ACCOUNT_STATUS',
	verificationStatus: 'VERIFICATION_STATUS',
	accountStatus: 'ACCOUNT_STATUS',
	importance: 'PRIORITY',
	'values.nextKycReviewDate': 'KYC_VIEW',
};

export const EntityRelationsTable = () => {
	const { classes } = useStyles();
	const params = useParams<{ entityId: string }>();
	const entityId = Number(params.entityId);

	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0 });

	const [searchValue, setSearchValue] = useLocalStorage<string>({
		defaultValue: '',
		key: 'entity-relations-table-search',
		getInitialValueInEffect: false,
	});
	const [debouncedSearch] = useDebouncedValue(searchValue, 500);

	const [filtersState, setFiltersState] = useLocalStorage<
		{
			id: string;
			value: unknown;
		}[]
	>({
		defaultValue: [],
		key: 'entity-relations-table-filters',
		getInitialValueInEffect: false,
	});

	const [sortingState, setSortingState] = useLocalStorage<
		{
			desc: boolean;
			id: string;
		}[]
	>({
		defaultValue: [{ desc: true, id: 'ACCOUNT_NAME' }],
		key: 'entity-relations-table-sorting',
		getInitialValueInEffect: false,
	});

	const sortingParams =
		sortingState.length > 0
			? {
					sortBy: sortingNamesMap[sortingState[0].id] as BackendTypes.OverviewSortBy | undefined,
					sortOrder: sortingState[0].desc ? 'DESC' : ('ASC' as BackendTypes.OverviewSortOrder),
			  }
			: undefined;

	const filteringParams = filtersState.reduce<Record<string, any>>((acc, curr) => {
		acc[filtersNamesMap[curr.id]] = curr.value;
		return acc;
	}, {});

	const { data, isPending } = useEntityAssociations({
		entityId,
		page: pagination.pageIndex + 1,
		limit: pagination.pageSize,
		query: debouncedSearch,
		...filteringParams,
		...sortingParams,
	});

	const { data: overviewData } = useOverviewData();
	const listUsers = overviewData?.listUsers ?? [];

	const accountKinds = useSelector(() => appStore.defs.options['AccountKind'].get());
	const hasArchivedStatusMessage = Boolean(
		!filtersState.find((f) => f.value === 'ARCHIVED') && !!debouncedSearch
	);

	return (
		<>
			<TFDataTable<BackendTypes.OverviewAccount>
				name="entity-relations-list"
				isLoading={isPending}
				noDataText={
					hasArchivedStatusMessage ? (
						<HiddenAccountsNotice
							textSize="16px"
							onClick={() => setFiltersState([{ id: 'values.accountStatus', value: 'ARCHIVED' }])}
						/>
					) : undefined
				}
				data={data?.accounts.items ?? []}
				serverSorting={{
					onSortingChange: setSortingState,
					state: sortingState,
				}}
				serverSearch={{
					state: searchValue,
					onSearchChange: setSearchValue,
				}}
				serverFiltering={{
					onFiltersChange: setFiltersState,
					state: filtersState,
				}}
				serverPagination={
					data
						? {
								...pagination,
								rowCount: data.accounts.count,
								onPaginationChange: setPagination,
						  }
						: undefined
				}
				defs={[
					{
						header: 'Account name',
						accessorKey: 'name',
						size: 200,
						Cell: ({ row }: { row: { original: BackendTypes.OverviewAccount } }) => {
							const { nodeId, name } = row.original;
							return (
								<UnstyledButton
									component={Link}
									to={`/accounts/${nodeId}`}
									className={classes.accountName}
								>
									<TFText inherit lineClamp={1}>
										{name || 'N/A'}
									</TFText>
								</UnstyledButton>
							);
						},
					},
					{
						header: 'Account type',
						accessorKey: 'kind',
						Cell: ({ cell }) => {
							const nextKind = accountKinds?.find((a: { value: string }) => a.value === cell.getValue());
							return (
								<TFText inherit lineClamp={1}>
									{nextKind?.label || 'Not defined'}
								</TFText>
							);
						},
					},
					{
						header: 'Account status',
						accessorKey: 'accountStatus',
						Cell: ({ row }: { row: { original: BackendTypes.OverviewAccount } }) => {
							const status = row.original.values?.accountStatus;
							return status ? (
								<TFText inherit>{S.prettify(status)}</TFText>
							) : (
								<TFText inherit c="dimmed">
									Not defined
								</TFText>
							);
						},
					},
					{
						header: 'Verification status',
						accessorKey: 'verificationStatus',
						Cell: ({ row }: { row: { original: BackendTypes.OverviewAccount } }) => (
							<ReviewState account={row.original} />
						),
					},
					{
						header: 'Assigned managers',
						accessorKey: 'assignedManagers',
						enableSorting: false,
						Cell: ({ row }: { row: { original: BackendTypes.OverviewAccount } }) => (
							<AssignedManagersView
								accountId={row.original.nodeId}
								selectedIds={row.original.assignedManagers}
							/>
						),
					},
					{
						header: 'Priority',
						accessorKey: 'importance',
						Cell: ({ row }: { row: { original: BackendTypes.OverviewAccount } }) => (
							<PriorityView value={row.original.importance} />
						),
					},
					{
						header: 'Next KYC review',
						accessorKey: 'values.nextKycReviewDate',
						Cell: ({ row }: { row: { original: BackendTypes.OverviewAccount } }) => {
							const date: string | undefined = row.original.values.nextKycReviewDate;
							return date ? (
								<TFText inherit>{fmt.toDate(date, { preset: 'full_date' })}</TFText>
							) : (
								<TFText inherit c="dimmed">
									Not defined
								</TFText>
							);
						},
					},
				]}
				availableFilters={getAccountsFilters(listUsers, accountKinds)}
			/>
			<Box mt="12px">
				{hasArchivedStatusMessage && Boolean(data?.accounts.items.length) && (
					<HiddenAccountsNotice
						textSize="13px"
						withIcon
						onClick={() => setFiltersState([{ id: 'values.accountStatus', value: 'ARCHIVED' }])}
					/>
				)}
			</Box>
		</>
	);
};
