import React, { useState } from 'react';

import { useSelector } from '@datagrid/state';
import { useQueryClient } from '@tanstack/react-query';
import { useOutletContext, useParams } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import { extractForms, getEntityFormStatus } from '@tf/shared';
import { TFNotifier } from '@tf/ui';
import type { FormSegment, SubmitHandler } from '@tf/utils';
import { rawRevision, T } from '@tf/utils';

import { segmentsQueryOptions } from '@/core/api/segments';
import { useProcessingScripts } from '@/core/hooks';
import { writeSegmentsMutation } from '@/core/mutations';
import { appStore } from '@/core/stores';
import { FormWrapper } from '@/components/reviews/ReviewForm/FormWrapper';
import { AppFormBuilder } from '@/components/shared';
import { getAdditionalDataForBackgroundCheck } from '@/components/utils';

import { EntityFormFooter } from './EntityFormFooter';

type Revisions = Record<BackendTypes.SegmentKind, number>;

export const EntityReviewForm = () => {
	const currency = useSelector(() => appStore.currency?.get());
	const { entity, segmentsReadData } = useOutletContext<{
		entity: BackendTypes.Entity;
		segmentsReadData: BackendTypes.SegmentsReadData;
	}>();
	const { formKind } = useParams<{ formKind: string }>();
	const defs = useSelector(() => appStore.defs.get().structs);
	const selfSegments = useSelector(() => appStore.defs.get().selfSegments);
	const formDefs = useSelector(() => appStore.defs.get().forms);
	const queryClient = useQueryClient();

	T.assertNotNullish(currency, 'no currency in appStore');

	const forms = extractForms({
		formDefs,
		segmentIdentities: entity.listSegments.map((s) => s.SegmentIdentity),
	});

	const form = forms.find((f) => f.name === formKind)!;

	const selfSegmentKinds = Object.values(selfSegments);

	const segmentsData = segmentsReadData.filter((segment) =>
		form.segmentIdentities.some(
			(item) => item.segmentKind === segment.container.info.SegmentIdentity.segmentKind
		)
	);

	const segments: FormSegment[] =
		segmentsData?.map(({ container: { info } }) => {
			const label = defs[info.SegmentIdentity.segmentKind]?.label || '';
			return { accessMode: info.AccessMode, ...info.SegmentIdentity, label } satisfies FormSegment;
		}) ?? [];

	const segmentKinds = segments.map(({ segmentKind }) => segmentKind);
	const scripts = useProcessingScripts(segmentKinds);

	const initialValues = segmentsData?.reduce((acc, { container }) => {
		const { info, segment } = container;
		return {
			...acc,
			[info.SegmentIdentity.segmentKind]: segment,
		};
	}, {} as Record<BackendTypes.SegmentKind, BackendTypes.Segment>);

	const formStatus = getEntityFormStatus({
		segmentsData: segmentsReadData,
		segmentIdentities: form.segmentIdentities,
	});

	const initialRevisions = segmentsData.reduce(
		(acc, { container: { info } }) => ({
			...acc,
			[info.SegmentIdentity.segmentKind]: info.Revision.revision,
		}),
		{} as Revisions
	);
	const [revisions, setRevisions] = useState<Revisions>(initialRevisions);
	const handleSubmit: SubmitHandler = async ({ values, segmentValidationStatuses }) => {
		try {
			const input: BackendTypes.SegmentsWritePayload = [];
			for (const { container } of segmentsData) {
				const { graphId, segmentKind } = container.info.SegmentIdentity;
				input.push({
					segmentIdentity: { graphId, segmentKind },
					revision: { ...rawRevision, revision: revisions[segmentKind] },
					segment: values[segmentKind] ?? {},
					validationStatus: segmentValidationStatuses[segmentKind],
				});
			}

			const res = await writeSegmentsMutation(input);
			TFNotifier.info('Form successfully updated', { id: 'form-updated' });
			for (const { container } of res) {
				setRevisions((prev) => ({
					...prev,
					[container.info.SegmentIdentity.segmentKind]: container.info.Revision.revision,
				}));
			}

			await queryClient.refetchQueries(segmentsQueryOptions(segments));
		} catch (err) {
			const message = err instanceof Error ? err.message : 'Something went wrong';
			TFNotifier.error(message);
		}
	};

	return (
		<FormWrapper key={form.name} hideCommentsButton def={form}>
			<AppFormBuilder
				currency={currency}
				segments={segments}
				additionalData={getAdditionalDataForBackgroundCheck(segmentsReadData, form, selfSegmentKinds)}
				initialValues={initialValues}
				processingScripts={scripts}
				validateInitialValues={formStatus === 'VALIDATION_ERRORS'}
				onSubmit={handleSubmit}
				submitButtonRenderer={(isLoading) => (
					<EntityFormFooter
						status={formStatus}
						segments={segments}
						graphId={segments[0]?.graphId}
						isLoading={isLoading}
					/>
				)}
				styles={{
					content: { marginTop: '1rem' },
					segment: ({ colors }) => ({
						marginBottom: '1rem',
						padding: '.75rem 1rem 1rem',
						borderStyle: 'solid',
						borderRadius: 8,
						borderWidth: 0.5,
						borderColor: colors.gray[2],
						backgroundColor: '#fff',
						boxShadow: '0 4px 20px -4px rgb(0 0 0 / 10%)',
					}),
				}}
			/>
		</FormWrapper>
	);
};
