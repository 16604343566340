import React from 'react';

import type { BackendTypes } from '@tf/api';
import type { FormStatuses } from '@tf/shared';
import { Center, Icon, type Sx, Tooltip } from '@tf/ui';

interface Props {
	reviewState: BackendTypes.ReviewState;
	isDecisionRequired: boolean;
	status: FormStatuses;
	sx?: Sx;
}

export const FormStatusIndicator: React.FC<Props> = ({
	status,
	reviewState,
	isDecisionRequired,
	sx,
}) => {
	const baseSx: Sx = sx || {
		cursor: 'default',
		height: 40,
		padding: '0 .35rem',
	};

	if (reviewState !== 'DRAFT') {
		switch (status.review) {
			case 'ACCEPTED':
				return (
					<Tooltip label="Form is verified by compliance" position="right" withArrow>
						<Center
							sx={({ colors }) => ({
								...baseSx,
								color: colors.green[8],
							})}
						>
							<Icon.IconCircleCheck size={16} />
						</Center>
					</Tooltip>
				);
			case 'REJECTED':
				return (
					<Tooltip label="Form is rejected by compliance" position="right" withArrow>
						<Center
							sx={({ colors }) => ({
								...baseSx,
								color: colors.red[5],
							})}
						>
							<Icon.IconCircleX size={16} />
						</Center>
					</Tooltip>
				);
			default:
				return isDecisionRequired ? (
					<Tooltip label="Form awaits verification" position="right" withArrow>
						<Center
							sx={({ colors }) => ({
								...baseSx,
								color: colors.brand[4],
							})}
						>
							<Icon.IconClock size={16} />
						</Center>
					</Tooltip>
				) : null;
		}
	}

	return <FormStatusIndicatorBase status={status.structure} />;
};

export const FormStatusIndicatorBase: React.FC<{
	status?: BackendTypes.FieldValidationStatus;
	sx?: Sx;
}> = ({ status, sx }) => {
	const baseSx: Sx = sx || {
		cursor: 'default',
		height: 40,
		padding: '0 .35rem',
	};
	switch (status) {
		case 'OK':
			return (
				<Tooltip label="Form is valid and ready for verification" position="right" withArrow>
					<Center
						sx={({ colors }) => ({
							...baseSx,
							color: colors.brand[4],
						})}
					>
						<Icon.IconCircleCheck size={16} />
					</Center>
				</Tooltip>
			);
		case 'VALIDATION_ERRORS':
			return (
				<Tooltip
					label="The form contains errors. Correct them to be submitted for verification"
					position="right"
					withArrow
				>
					<Center
						sx={({ colors }) => ({
							...baseSx,
							color: colors.red[5],
						})}
					>
						<Icon.IconAlertCircle size={16} />
					</Center>
				</Tooltip>
			);
		case 'PENDING_FILL':
			return (
				<Tooltip label="The form requires filling in" position="right" withArrow>
					<Center
						sx={({ colors }) => ({
							...baseSx,
							color: colors.gray[4],
						})}
					>
						<Icon.IconCircleDashed size={16} />
					</Center>
				</Tooltip>
			);
		default:
			return null;
	}
};
