import React, { useEffect, useState } from 'react';

import { useSelector } from '@datagrid/state';
import { i18n } from '@lingui/core';
import { I18nProvider as LinguiI18nProvider } from '@lingui/react';

import { TFLoadingOverlay } from '@tf/ui';

import { appStore } from '@/core/stores';
import { addTFConsoleMethods } from '@/core/tfConsole';

export const loadCatalog = async (locale: string) => {
	const { messages } = await import(`../../../locales/${locale}.po`);
	i18n.loadAndActivate({ locale, messages });
};

const pseudoLocaleCode = 'testing';

export const I18nProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const defaultLocale = useSelector(() => appStore.defaultLocale.get());
	const [isInitialized, setIsInitialized] = useState(false);

	useEffect(() => {
		loadCatalog(defaultLocale).then(() => {
			setIsInitialized(true);
		});

		// For testing
		let prevActiveLocale = i18n.locale;
		addTFConsoleMethods({
			l10nTogglePseudolocalization: () => {
				if (i18n.locale !== pseudoLocaleCode) {
					prevActiveLocale = i18n.locale;
					loadCatalog(pseudoLocaleCode);
				} else {
					loadCatalog(prevActiveLocale);
				}
			},
			l10nSwitchLocale: (locale: string) => {
				loadCatalog(locale);
			},
		});
	}, []);

	if (!isInitialized) {
		return <TFLoadingOverlay />;
	}

	return <LinguiI18nProvider i18n={i18n}>{children}</LinguiI18nProvider>;
};
