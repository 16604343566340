import type { BackendTypes } from '@tf/api';
import { Group, TFText } from '@tf/ui';

import { useOverviewAccount } from '@/core/api/overview';
import { useSelectedAccount, useSelectedAccountApplicantReview } from '@/core/hooks';
import { getLabel } from '@/core/utils';
import { ReviewState, TFBadge, TFBadgeColors } from '@/components/shared';

const colors: Record<BackendTypes.ReviewState, string> = {
	DRAFT: TFBadgeColors.DIMMED,
	READY_TO_REVIEW: TFBadgeColors.INDIGO,
	UNDER_REVIEW: TFBadgeColors.INDIGO,
	UPDATE_REQUIRED: TFBadgeColors.ORANGE,
	VERIFIED: TFBadgeColors.GREEN,
};

export const ReviewStatus = () => {
	const { meta } = useSelectedAccount();
	const { data } = useOverviewAccount(meta.graphNodeId);

	const additionalReviews =
		data?.account.reviewStates.filter((r) => r.connectionKind !== 'APPLICANT') || [];

	const reviewState = useSelectedAccountApplicantReview();

	const label = getLabel(reviewState.state || '', { kind: 'reviewState' });

	return (
		<ReviewState account={data?.account as BackendTypes.OverviewAccount}>
			<TFBadge
				text={
					<Group gap="xs">
						{label}
						<TFText
							sx={({ colors }) => ({
								color: colors.gray[4],
							})}
						>
							{' '}
							{additionalReviews?.length > 0 ? '(+' + additionalReviews.length + ')' : ''}
						</TFText>
					</Group>
				}
				color={colors[reviewState.state || 'DRAFT'] as TFBadgeColors}
			/>
		</ReviewState>
	);
};
