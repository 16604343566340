import { queryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { BackendClient } from '@tf/api';

const accountsExportHistoryOptions = () => {
	return queryOptions({
		queryKey: ['dataExport'],
		queryFn: async (): Promise<BackendTypes.ListAccountsExportData> => {
			const res = await BackendClient.getInstance().listAccountsExport();
			return res.json();
		},
	});
};

export const useAccountsExportHistoryQuery = () => {
	return useQuery(accountsExportHistoryOptions());
};

export const useOrderAccountsExportMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (): Promise<BackendTypes.OrderAccountsExportData> => {
			return await BackendClient.getInstance().orderAccountsExport();
		},
		onSuccess: () => {
			queryClient.invalidateQueries(accountsExportHistoryOptions());
		},
	});
};

export const useDownloadAccountsExportMutation = () => {
	return useMutation({
		mutationFn: async ({ jobId }: { jobId: BackendTypes.JobId }) => {
			const res = await BackendClient.getInstance().downloadAccountsExport(jobId);
			return res.arrayBuffer();
		},
	});
};
