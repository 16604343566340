import { TFCard, TFLoadingOverlay } from '@tf/ui';

interface Props {
	text?: string;
}

export const FormLoader: React.FC<Props> = ({ text }) => {
	return (
		<TFCard mt="1rem">
			<TFLoadingOverlay size={28} label={text ? text : 'Loading...'} h={300} />
		</TFCard>
	);
};
