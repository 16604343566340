import { Button, createStyles, Group } from '@tf/ui';

interface Props {
	onCancel?: () => void;
	onSubmit?: () => void;
	isDisabled?: boolean;
	isLoading?: boolean;
	cancelText?: string;
	submitText?: string;
}

const useStyles = createStyles(({ colors }) => ({
	root: {
		display: 'flex',
		margin: '.5rem 0',
		padding: '.75rem .75rem .25rem',
		borderTopStyle: 'solid',
		borderTopWidth: 1,
		borderTopColor: colors.gray[2],
	},
	button: {
		flex: 1,
	},
}));

export const ModalFooter: React.FC<Props> = ({
	onCancel,
	isDisabled,
	isLoading,
	onSubmit,
	submitText,
	cancelText,
}) => {
	const { classes } = useStyles();

	return (
		<Group className={classes.root} gap=".75rem">
			{onCancel ? (
				<Button variant="light" className={classes.button} onClick={onCancel}>
					{cancelText || 'Cancel'}
				</Button>
			) : null}

			<Button
				className={classes.button}
				loading={isLoading}
				loaderProps={{ size: 14 }}
				disabled={isDisabled}
				{...(onSubmit ? { onClick: onSubmit } : { type: 'submit' })}
			>
				{submitText || 'Submit'}
			</Button>
		</Group>
	);
};
