import { useState } from 'react';

import { Box, Button, createStyles, Space, TFModals, TFText, Title } from '@tf/ui';

export interface ConfirmationModalProps extends Record<string, any> {
	children?: React.ReactNode;
	title?: string;
	message?: string;
	confirmText?: string;
	onConfirm: () => Promise<void> | void;
	onCancel?: () => void;
	isSubmitDisabled?: boolean;
	confirmButtonColor?: string;
	accountNameConfirmText?: string;
}

const useStyles = createStyles((theme) => ({
	header: {
		padding: '.75rem 1rem',
		borderBottomStyle: 'solid',
		borderBottomWidth: 1,
		borderBottomColor: theme.colors.gray[2],
	},
	title: {
		fontSize: '1.2rem',
		color: theme.colors.gray[7],
		fontWeight: 500,
	},
	content: {
		fontSize: '.875rem',
		padding: '1rem',
	},
	footer: {
		display: 'flex',
		padding: '.75rem',
		borderTopStyle: 'solid',
		borderTopWidth: 1,
		borderTopColor: theme.colors.gray[2],
	},
	button: {
		flex: 1,
	},
}));

export const ConfirmationModal = ({
	children,
	title,
	confirmText,
	onConfirm,
	onCancel,
	message,
	isSubmitDisabled,
	confirmButtonColor,
}: ConfirmationModalProps) => {
	const { classes } = useStyles();
	const [isLoading, setIsLoading] = useState(false);

	return (
		<Box>
			<Box hidden={!title} className={classes.header}>
				<Title className={classes.title}>{title}</Title>
			</Box>

			<Box className={classes.content}>
				<TFText>{message ?? 'Please confirm action'}</TFText>
				{children}
			</Box>

			<Box className={classes.footer}>
				<Button
					variant="light"
					className={classes.button}
					onClick={() => {
						onCancel?.();
						TFModals.closeAll();
					}}
				>
					Cancel
				</Button>
				<Space w={12} />
				<Button
					disabled={isSubmitDisabled}
					className={classes.button}
					loading={isLoading}
					variant="filled"
					color={confirmButtonColor}
					loaderProps={{ size: 12 }}
					onClick={async () => {
						try {
							setIsLoading(true);
							await onConfirm();
							TFModals.closeAll();
						} finally {
							setIsLoading(false);
						}
					}}
				>
					{confirmText || 'Confirm'}
				</Button>
			</Box>
		</Box>
	);
};
