import { useSelector } from '@datagrid/state';
import type { FC } from 'react';

import { TFLoadingOverlay } from '@tf/ui';

import { useAccountForm } from '@/core/hooks';
import { AppFormBuilder, ModalFooter } from '@/components/shared';

interface Props {
	onClose: () => void;
}

export const RiskModalForm: FC<Props> = ({ onClose }) => {
	const { state, scripts, formSegments, handleSubmit } = useAccountForm(
		['COMMON__COMPLIANCE_FEEDBACK', 'COMMON__COMPLIANCE_DATES'],
		{ onSuccess: onClose }
	);
	const values = useSelector(() => state.values?.get());

	if (values === undefined) {
		return <TFLoadingOverlay h={300} size={24} />;
	}

	return (
		<AppFormBuilder
			styles={{ content: { padding: '.25rem .75rem' }, segment: { paddingTop: '0.75rem' } }}
			segments={formSegments}
			processingScripts={scripts}
			shouldValidate
			initialValues={values}
			onSubmit={handleSubmit}
			submitButtonRenderer={(isLoading) => (
				<ModalFooter submitText="Save changes" isDisabled={isLoading} onCancel={onClose} />
			)}
		/>
	);
};
