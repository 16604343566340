import React from 'react';

import { Alert, Box, createStyles, Icon, TFLoadingOverlay } from '@tf/ui';

import { useCraAutoAssigneesGroups } from '@/core/api/craSettings';
import { useUsers } from '@/core/api/users';
import { AutoAssignedTable } from '@/components/cra-settings/AutoAssignedTable';

const useStyles = createStyles(() => ({
	alert: {
		backgroundColor: '#F1F3F5',
		borderColor: '#F1F3F5',
		borderRadius: '8px',
		color: '#1A1B1E',
	},
	textContainer: {
		maxWidth: '800px',
		color: '#475467',
		fontWeight: 400,
		fontSize: '14px',
	},
}));

export const AutoAssignedSettings = () => {
	const { classes } = useStyles();

	const craAutoAssigneesGroups = useCraAutoAssigneesGroups();
	const usersQuery = useUsers();

	if (craAutoAssigneesGroups.error) {
		return <div>Error, please try again</div>;
	}

	const listUsers = usersQuery.data ?? [];

	return (
		<>
			<Alert
				title="Auto-assign settings"
				icon={<Icon.IconAlertCircleFilled fill="#1A1B1E" />}
				className={classes.alert}
				fw="600"
			>
				<Box className={classes.textContainer}>
					You can specify which user(s) should be automatically assigned to an application based on the
					risk level. If you can’t find the right person, please make sure the’ve been added to your
					Workspace.
				</Box>
			</Alert>

			{craAutoAssigneesGroups.isPending || usersQuery.isPending ? (
				<TFLoadingOverlay />
			) : (
				<>
					<AutoAssignedTable
						riskLevelMap={craAutoAssigneesGroups.data}
						listUsers={listUsers}
						label="Low risk"
						icon={<Icon.IconCircleArrowDown color="#37B24D" size="20px" />}
						riskLevel="LOW"
					/>

					<AutoAssignedTable
						riskLevelMap={craAutoAssigneesGroups.data}
						listUsers={listUsers}
						label="Medium risk"
						icon={<Icon.IconCircleCheck color="#155EEF" size="20px" />}
						riskLevel="MEDIUM"
					/>

					<AutoAssignedTable
						riskLevelMap={craAutoAssigneesGroups.data}
						listUsers={listUsers}
						label="High risk"
						icon={<Icon.IconCircleArrowUp color="#FF922B" size="20px" />}
						riskLevel="HIGH"
					/>

					<AutoAssignedTable
						riskLevelMap={craAutoAssigneesGroups.data}
						listUsers={listUsers}
						label="Very high risk"
						icon={<Icon.IconAlertCircle color="#FA5252" size="20px" />}
						riskLevel="VERY_HIGH"
					/>
				</>
			)}
		</>
	);
};
