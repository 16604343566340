import type { ReactElement } from 'react';

import { Icon } from '@tf/ui';

import type { TFBadgeColors } from '@/components/shared';
import { TFBadge } from '@/components/shared';

interface Props {
	content: ReactElement | string;
	color: TFBadgeColors;
	onClick?: () => void;
	disabled?: boolean;
}

export const AccountStatusBadge: React.FC<Props> = ({ content, color, onClick, disabled }) => {
	return (
		<TFBadge
			text={content}
			icon={disabled ? '' : <Icon.IconChevronDown size={13} />}
			color={color}
			onClick={() => (onClick ? onClick() : null)}
			disabled={disabled}
		/>
	);
};
