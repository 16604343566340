import { Group, Stack, TFCard, TFLoadingOverlay } from '@tf/ui';

import { useAccountInfoSegments, usePriority } from '@/core/hooks';
import { useAccountSettingsSegment } from '@/core/hooks/use-account-settings-segments';
import { AccountEventLog, AccountSettings, AccountTasks, AccountToolbar } from '@/components/accounts';

export const AccountOverview = () => {
	const { values } = useAccountSettingsSegment();
	const priorityObject = usePriority();
	const accountInfo = useAccountInfoSegments();

	if (!values.segments || !priorityObject || !accountInfo.accountStatus) {
		return <TFLoadingOverlay h="600px" />;
	}

	return (
		<Stack py="0.75rem" gap="md">
			<AccountToolbar />

			<Group gap="md" align="start" grow>
				<Stack>
					<TFCard title="Tasks">
						<AccountTasks />
					</TFCard>
					<TFCard title="Event log">
						<AccountEventLog />
					</TFCard>
				</Stack>

				<TFCard title="Account information">
					<AccountSettings />
				</TFCard>
			</Group>
		</Stack>
	);
};
