import React from 'react';

import { useSelector } from '@datagrid/state';
import * as Sentry from '@sentry/react';
import { Navigate } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import type { ExtendedFormDefinition } from '@tf/shared';
import { getFormHash, getFormStatuses, selectForm } from '@tf/shared';
import { S } from '@tf/utils';

import { useSegmentsQuery } from '@/core/api/segments';
import { useAccountParams, useSelectedAccount, useSelectedReview } from '@/core/hooks';
import { appStore } from '@/core/stores';
import type { ExtendedReviewContainer } from '@/core/types';
import { FormLoader } from '@/components/shared';
import { getAdditionalDataForBackgroundCheck } from '@/components/utils';

import { Form } from './Form';
import { FormWrapper } from './FormWrapper';

const isSelfSegment = (segment: BackendTypes.SegmentIdentity, selfSegmentKinds: string[]) =>
	selfSegmentKinds.includes(segment.segmentKind);

const getSegmentWithSelfSegmentDataDependency = (
	form: ExtendedFormDefinition<BackendTypes.FormDefinition>
) => form.segmentIdentities.find((s) => s.segmentKind === 'COMMON__BACKGROUND_CHECK');

const ReviewFormContent: React.FC<{
	form: ExtendedFormDefinition<BackendTypes.FormDefinition>;
	segmentIdentities: BackendTypes.SegmentIdentity[];
	review: ExtendedReviewContainer;
}> = ({ form, segmentIdentities, review }) => {
	// Find segment which needs data from self-segment to work
	// Currently it is only Background check, it needs entity name
	const additionalSegments: BackendTypes.SegmentIdentity[] = [];

	const selfSegments = useSelector(() => appStore.defs.get().selfSegments);
	const selfSegmentKinds = Object.values(selfSegments);
	const segmentWithSelfSegmentDataDependency = getSegmentWithSelfSegmentDataDependency(form);

	if (segmentWithSelfSegmentDataDependency) {
		// Dependent segment lives on the entity, so we can find required self segment
		const requiredSelfSegment = segmentIdentities.find(
			(s) =>
				s.graphId === segmentWithSelfSegmentDataDependency.graphId && isSelfSegment(s, selfSegmentKinds)
		);
		if (requiredSelfSegment) {
			additionalSegments.push(requiredSelfSegment);
		}
	}

	// * Get initial form data
	const segmentsQuery = useSegmentsQuery(
		// unique segment identities
		[...form.segmentIdentities, ...additionalSegments].filter((s, i, segmentsIdentities) => {
			const sameSegmentIndex = segmentsIdentities.findIndex(
				(s2) => s.graphId === s2.graphId && s.segmentKind === s2.segmentKind
			);
			return sameSegmentIndex === i;
		}),
		{
			enabled: Boolean(form.segmentIdentities),
		}
	);

	const { structure } = getFormStatuses({
		containers: review.listSegmentReviewContainers,
		segmentIdentities: form.segmentIdentities,
	});
	const hasPrevErrors = structure === 'VALIDATION_ERRORS';

	return (
		<FormWrapper def={form}>
			{segmentsQuery.status === 'success' ? (
				<Form
					key={`${form.name}.${getFormHash(form)}`}
					data={segmentsQuery.data.filter((segment) =>
						form.segmentIdentities.some(
							(item) => item.segmentKind === segment.container.info.SegmentIdentity.segmentKind
						)
					)}
					additionalData={getAdditionalDataForBackgroundCheck(
						segmentsQuery.data,
						form,
						selfSegmentKinds
					)}
					validateInitialValues={hasPrevErrors}
				/>
			) : (
				<FormLoader />
			)}
		</FormWrapper>
	);
};

export const ReviewForm = () => {
	const params = useAccountParams();
	const account = useSelectedAccount();
	const review = useSelectedReview();
	const formDefs = useSelector(() => appStore.defs.get().forms);

	// Pick current form
	const segmentIdentities = [
		...review.listEntities.flatMap(({ listSegments }) =>
			listSegments.flatMap(({ SegmentIdentity }) => SegmentIdentity)
		),
		// Segments from connections:
		...review.listEntities.flatMap((entity) => {
			return entity.listConnections.flatMap((connection) => {
				return connection.listFromEntities.flatMap((connectionData) => {
					return connectionData.listSegments.map((s) => s.SegmentIdentity);
				});
			});
		}),
		...review.listSegmentReviewContainers.flatMap(({ identity }) => identity),
		...account.meta.listSegments.map((s) => s.SegmentIdentity),
	];

	// Try to read form and redirect to parent entity in case of failure
	let form: ExtendedFormDefinition<BackendTypes.FormDefinition> | null = null;
	try {
		form = selectForm({
			formDefs,
			segmentIdentities,
			formName: params.formKind,
			hash: params.searchParams.hash,
		});
	} catch (err) {
		// Lower level because we handle this with redirect
		Sentry.captureException(err, { level: 'warning' });
	}

	if (!form) {
		const connectionPath = `/accounts/${params.accountId}/${S.slugify(params.connectionKind)}`;
		return (
			<Navigate
				to={
					params.entityId
						? `${connectionPath}/${params.connectionId}/entity/${params.entityId}`
						: connectionPath
				}
			/>
		);
	}

	return <ReviewFormContent form={form} segmentIdentities={segmentIdentities} review={review} />;
};
