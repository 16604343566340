import React from 'react';

import { useSelector } from '@datagrid/state';

import { FormBuilder, type FormBuilderProps } from '@tf/form-builder';

import { fileDownload, fileUpload } from '@/core/api/files';
import { useCurrentUser } from '@/core/hooks';
import { appStore } from '@/core/stores';

export const AppFormBuilder: React.FC<
	Omit<FormBuilderProps, 'apiConfig' | 'defs'> & { fieldFilters?: string[] }
> = (props) => {
	const defs = useSelector(() => appStore.defs.peek());
	const { username } = useCurrentUser();

	if (Object.keys(defs.structs).length === 0) {
		return null;
	}

	return (
		<FormBuilder
			{...props}
			apiConfig={{
				username,
				fileDownloadFn: fileDownload,
				fileUploadFn: fileUpload,
			}}
			defs={{
				structs: defs.structs,
				options: defs.options,
			}}
		/>
	);
};
