import React from 'react';

import { useSelector } from '@datagrid/state';

import { Box, TFDataTable, TFLoadingOverlay } from '@tf/ui';
import { fmt } from '@tf/utils';

import { useCraSettings } from '@/core/api/craSettings';
import { appStore } from '@/core/stores';

import { DeleteTemplateButton } from './DeleteTemplateButton';
import { DownloadTemplateLink } from './DownloadTemplateLink';
import { UploadTemplateButton } from './UploadTemplateButton';

export const CraSettingsTable = () => {
	const { data, isPending, error } = useCraSettings();
	const defs = useSelector(() => appStore.defs.get());
	const accountOptions = [...defs.options['AccountKind']].sort((a, b) => a.value.localeCompare(b.value));

	const craTemplates = data
		? accountOptions.map((accountOption) => {
				return {
					accountKind: accountOption.value,
					label: accountOption.label,
					file: data[accountOption.value],
				};
		  })
		: [];

	if (isPending) {
		return <TFLoadingOverlay />;
	}

	if (error) {
		return <div>Error, please try again</div>;
	}

	return (
		<TFDataTable
			data={craTemplates}
			defs={[
				{
					header: 'Account type',
					accessorKey: 'label',
				},
				{
					header: 'Template',
					id: 'template',
					Cell: ({ row }) => {
						return <DownloadTemplateLink file={row.original.file} />;
					},
				},
				{
					header: 'Date uploaded',
					id: 'uploadDate',
					accessorFn: (template) => {
						return template.file?.uploadDate
							? fmt.toDate(template.file?.uploadDate, { preset: 'full_date' })
							: '–';
					},
				},
				{
					header: 'Action',
					id: 'action',
					Cell: ({ row }) => {
						return (
							<Box sx={{ display: 'flex', gap: '0.75rem' }}>
								<UploadTemplateButton accountKind={row.original.accountKind} />

								{row.original.file ? (
									<DeleteTemplateButton accountKind={row.original.accountKind} />
								) : null}
							</Box>
						);
					},
				},
			]}
			name="cra-templates-list"
		/>
	);
};
