export enum MatchStatus {
	UNKNOWN = 'unknown',
	NO_MATCH = 'no_match',
	POTENTIAL_MATCH = 'potential_match',
	FALSE_POSITIVE = 'false_positive',
	TRUE_POSITIVE = 'true_positive',
	TRUE_POSITIVE_APPROVE = 'true_positive_approve',
	TRUE_POSITIVE_REJECT = 'true_positive_reject',
}

export const MATCH_STATUSES = [
	{ value: MatchStatus.UNKNOWN, label: 'Unknown' },
	{ value: MatchStatus.NO_MATCH, label: 'No match' },
	{ value: MatchStatus.POTENTIAL_MATCH, label: 'Potential match' },
	{ value: MatchStatus.FALSE_POSITIVE, label: 'False positive' },
	{ value: MatchStatus.TRUE_POSITIVE, label: 'True positive' },
	{ value: MatchStatus.TRUE_POSITIVE_APPROVE, label: 'Approve - true positive' },
	{ value: MatchStatus.TRUE_POSITIVE_REJECT, label: 'Reject - true positive' },
];
