import { BackendClient, type BackendTypes } from '@tf/api';

export const updateMonitoringSearchCommentMutation = async ({
	graphNodeId,
	matchId,
	comment,
}: BackendTypes.MonitoringSearchMatchCommentCreateData): Promise<void> => {
	await BackendClient.getInstance().monitoringSearchMatchCommentCreate(graphNodeId, matchId, {
		comment,
	});
};
