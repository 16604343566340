import React, { useState } from 'react';

import type { BackendTypes } from '@tf/api';
import type { ExtendedFormDefinition } from '@tf/shared';
import { getFormStatuses, isFormDecisionRequired } from '@tf/shared';
import { Box, Button } from '@tf/ui';

import type { ExtendedReviewContainer } from '@/core/types';
import { UserCapabilities } from '@/core/types';
import { useCurrentUserCapabilityCheck } from '@/core/utils';

import { BulkReviewModal } from './BulkReviewModal';

interface Props {
	graphId: number;
	review: ExtendedReviewContainer;
	forms: ExtendedFormDefinition<BackendTypes.FormDefinition>[];
}

export const BulkReviewButton: React.FC<Props> = ({ graphId, review, forms }) => {
	const [isOpened, setIsOpened] = useState(false);
	const toggleIsOpened = () => setIsOpened(!isOpened);

	const canReviewAccounts = useCurrentUserCapabilityCheck(UserCapabilities.CAN_REVIEW_ACCOUNTS_DATA);

	const formsToReview = forms.filter((form) => isFormDecisionRequired(form.segmentIdentities, review));

	const hasFormsToApprove = formsToReview.some((form) => {
		const status = getFormStatuses({
			containers: review.listSegmentReviewContainers,
			segmentIdentities: form.segmentIdentities,
		});

		return status.review !== 'ACCEPTED';
	});

	if (!canReviewAccounts || review.state === 'DRAFT' || !hasFormsToApprove) {
		return null;
	}

	return (
		<Box mb="0.5rem">
			<Button fullWidth onClick={toggleIsOpened}>
				Bulk Review
			</Button>

			<BulkReviewModal
				graphId={graphId}
				forms={formsToReview}
				isOpened={isOpened}
				onClose={toggleIsOpened}
			/>
		</Box>
	);
};
