import React from 'react';

import { useSelector } from '@datagrid/state';
import { useNavigate } from 'react-router-dom';

import { extractForms, getFormHash, getFormStatuses, isFormDecisionRequired } from '@tf/shared';
import { Box, Stack } from '@tf/ui';
import { S, T } from '@tf/utils';

import { useAccountParams, useEntityConnectionLabels, useSelectedAccount } from '@/core/hooks';
import { appStore } from '@/core/stores';
import type { ExtendedReviewContainer } from '@/core/types';
import { AddConnectionButton } from '@/components/shared/AddConnectionButton';
import { ConnectionMenu } from '@/components/shared/ConnectionMenu';
import { FormStatusIndicator } from '@/components/shared/FormStatusIndicator';
import { SideMenu, SideMenuItem } from '@/components/shared/SideMenu';

import { GroupLabel } from './GroupLabel';

export const ReviewGroupConnections: React.FC<{ review: ExtendedReviewContainer }> = ({ review }) => {
	const { meta } = useSelectedAccount();

	const params = useAccountParams();
	const navigate = useNavigate();

	const formDefs = useSelector(() => appStore.defs.get().forms);
	const connectionLabels = useEntityConnectionLabels(meta.entityKind);

	const accountEntity = review.listEntities.find((e) => e.graphNodeId === meta.graphNodeId);
	const reviewGroup = accountEntity?.listConnections.find(
		(c) => c.isReview && c.connectionKind === review.connectionKind
	)?.reviewGroup;
	const reviewGroupConnections = accountEntity?.listConnections.filter(
		(c) => !c.isReview && c.connectionKind !== review.connectionKind && c.reviewGroup === reviewGroup
	);

	return (
		<>
			{reviewGroupConnections?.map((connection) => {
				const { singular: label, plural: groupLabel } = connectionLabels[connection.connectionKind];
				const hasConnections = connection.listFromEntities.length > 0;

				return (
					<Box key={connection.connectionKind}>
						<GroupLabel
							label={groupLabel}
							isRequired={connection.isLogicalRequired}
							actions={
								hasConnections && (
									<AddConnectionButton
										buttonKind="icon"
										label={label}
										connectionKind={connection.connectionKind}
										parentEntity={{ kind: meta.entityKind, graphId: meta.graphNodeId }}
										skipRedirect
									/>
								)
							}
						/>

						<Stack gap="sm">
							{connection.listFromEntities.map((connectionData) => {
								const entity = review.listEntities.find(
									(e) => e.graphNodeId === connectionData.graphNodeId
								);
								T.assertNotNullish(entity, 'entity not found in review');

								const forms = extractForms({
									formDefs,
									segmentIdentities: [
										...entity.listSegments.map((s) => s.SegmentIdentity),
										...review.listSegmentReviewContainers
											.filter((s) => s.identity.graphId === connectionData.graphNodeId)
											.map((s) => s.identity),
									],
								});

								return (
									<SideMenu
										key={connectionData.graphNodeId}
										title={entity.name}
										actions={
											<ConnectionMenu
												parentEntityId={meta.graphNodeId}
												connectionKind={connection.connectionKind}
												entityId={connectionData.graphNodeId}
											/>
										}
									>
										{forms.map((form) => (
											<SideMenuItem
												key={form.name}
												label={form.label}
												isSelected={
													params.formKind === form.name && params.searchParams.hash === getFormHash(form)
												}
												rightSection={
													<FormStatusIndicator
														status={getFormStatuses({
															containers: review.listSegmentReviewContainers,
															segmentIdentities: form.segmentIdentities,
														})}
														reviewState={review.state}
														isDecisionRequired={isFormDecisionRequired(form.segmentIdentities, review)}
													/>
												}
												onClick={() => {
													navigate(
														`/accounts/${meta.graphNodeId}/${S.slugify(review.connectionKind)}/${
															review.graphLinkId
														}/${form.name}?hash=${getFormHash(form)}`
													);
												}}
											/>
										))}
									</SideMenu>
								);
							})}

							{!hasConnections ? (
								<AddConnectionButton
									label={label}
									connectionKind={connection.connectionKind}
									parentEntity={{ kind: meta.entityKind, graphId: meta.graphNodeId }}
									skipRedirect
								/>
							) : null}
						</Stack>
					</Box>
				);
			})}
		</>
	);
};
