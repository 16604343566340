import { useMemo } from 'react';

import { useSelector } from '@datagrid/state';

import { getAccountTasks } from '@tf/shared';

import { useSelectedAccount } from '@/core/hooks/use-selected-account';
import { appStore } from '@/core/stores';
import { UserCapabilities } from '@/core/types';
import { useCurrentUserCapabilityCheck } from '@/core/utils';

export const useSelectedAccountTasks = () => {
	const canEditAccounts = useCurrentUserCapabilityCheck(UserCapabilities.CAN_PREPARE_ACCOUNTS_DATA);
	const canReviewAccounts = useCurrentUserCapabilityCheck(UserCapabilities.CAN_REVIEW_ACCOUNTS_DATA);

	const account = useSelectedAccount();
	const formDefs = useSelector(() => appStore.defs.get().forms);
	const entityDefs = useSelector(() => appStore.defs.get().entities);

	const accountTasks = useMemo(() => {
		const reviews = account.reviews.sort((a, b) => a.connectionKind.localeCompare(b.connectionKind));

		return getAccountTasks({ formDefs, entityDefs, accountId: account.meta.graphNodeId, reviews });
	}, [formDefs, entityDefs, account.meta.graphNodeId, account.reviews]);

	return accountTasks.map((reviewTasks) => {
		return {
			...reviewTasks,
			entities: reviewTasks.entities.map((entityTasks) => {
				return {
					...entityTasks,
					tasks: entityTasks.tasks.filter((task) => {
						switch (task.type) {
							case 'PENDING_FILL':
							case 'VALIDATION_ERRORS':
							case 'REJECTED':
							case 'REQUIRED_CONNECTION':
							case 'CORRECT_SHAREHOLDERS_PERCENTAGE_SUM':
							case 'CORRECT_PARTNERSHIP_PERCENTAGE_SUM':
								return canEditAccounts;
							case 'PENDING_REVIEW':
							case 'FINALIZE_REVIEW':
								return canReviewAccounts;
							default:
								throw new Error(`Unhandled task type in capabilities filter`);
						}
					}),
				};
			}),
		};
	});
};
