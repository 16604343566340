import { useOrganization, useUser } from '@clerk/clerk-react';

import type { BackendTypes } from '@tf/api';
import { T } from '@tf/utils';

export const useCurrentUser = (): BackendTypes.UserInfo => {
	const { organization } = useOrganization();
	T.assertNotNullish(organization, 'organization is empty');

	const { user } = useUser();
	T.assertNotNullish(user, 'user is empty, not signed in');

	const orgMembership = user.organizationMemberships.find((m) => m.organization.id === organization.id);
	T.assertNotNullish(orgMembership, 'active organization not found in user memberships');

	return {
		userId: user.id,
		username: user.username ?? '',
		firstName: user.firstName ?? '',
		lastName: user.lastName ?? '',
		hasImage: user.hasImage,
		imageUrl: user.imageUrl,
		listRoleCapabilities: orgMembership.publicMetadata
			.capabilities as BackendTypes.RoleCapabilityValue[],
	};
};
