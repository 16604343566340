import React from 'react';

import type { BackendTypes } from '@tf/api';
import { Button, TFDataTable, TFLoadingOverlay, TFNotifier } from '@tf/ui';
import { fmt, S, saveFile } from '@tf/utils';

import { useAccountsExportHistoryQuery, useDownloadAccountsExportMutation } from '@/core/api/dataExport';

const statusesMap: Record<string, string> = {
	SUSPENDED: 'File ready',
	ACTIVE: 'Processing, might take a few mins',
};

export const ExportHistoryTable: React.FC = () => {
	const exportHistoryQuery = useAccountsExportHistoryQuery();

	const downloadAccountsExportMutation = useDownloadAccountsExportMutation();
	const downloadResult = (item: BackendTypes.AccountsExport) => {
		downloadAccountsExportMutation.mutate(
			{ jobId: item.key },
			{
				onSuccess: (file) => {
					saveFile(
						file,
						`${item.result?.filename}` ??
							`Data Export of ${fmt.toDate(item.createdAt, { preset: 'full_date' })}.xlsx`
					);
				},
				onError: () => {
					TFNotifier.error('Failed to download file');
				},
			}
		);
	};

	if (exportHistoryQuery.error) {
		return <div>Error, please try again</div>;
	}

	if (exportHistoryQuery.data) {
		return (
			<TFDataTable<BackendTypes.AccountsExport>
				hideSearchbar
				name="export-history"
				data={exportHistoryQuery.data}
				defs={[
					{
						header: 'Date',
						accessorKey: 'createdAt',
						enableSorting: true,
						size: 200,
						Cell: ({ row }) => {
							return fmt.toDate(row.original.createdAt, { preset: 'full_date_with_time' });
						},
					},
					{
						header: 'Status',
						accessorKey: 'status',
						enableSorting: true,
						size: 200,
						Cell: ({ row }) => {
							return statusesMap[row.original.status] ?? S.prettify(row.original.status);
						},
					},
					{
						header: 'Result',
						accessorKey: 'result',
						enableSorting: true,
						size: 200,
						Cell: ({ row }) => {
							if (!row.original.result) {
								return null;
							}

							return (
								<Button
									variant="subtle"
									size="xs"
									disabled={row.original.status === 'ACTIVE'}
									onClick={() => downloadResult(row.original)}
								>
									Download
								</Button>
							);
						},
					},
				]}
			/>
		);
	}

	return <TFLoadingOverlay size={28} h={300} />;
};
