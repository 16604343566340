import React from 'react';

import { TFPageContent, TFPageHeader } from '@tf/shared';
import { Alert, Box, createStyles } from '@tf/ui';
import { T } from '@tf/utils';

import { appStore } from '@/core/stores';
import { ExportHistoryTable, OrderExportButton } from '@/components/data-export';

const useStyles = createStyles(() => ({
	alert: {
		backgroundColor: '#F1F3F5',
		borderColor: '#F1F3F5',
		borderRadius: '8px',
		color: '#1A1B1E',
		marginBottom: '0.75rem',
	},
	textContainer: {
		maxWidth: '800px',
		color: '#475467',
		fontWeight: 400,
		fontSize: '14px',
	},
}));
export const DataExportRoute: React.FC = () => {
	const tenant = appStore.tenant?.get();
	const { classes } = useStyles();

	T.assertNotNullish(tenant, 'no tenant in appStore');

	return (
		<>
			<TFPageHeader title="All exports" tenant={tenant} toolbar={<OrderExportButton />} />
			<TFPageContent>
				<Alert className={classes.alert} fw="600">
					<Box className={classes.textContainer}>
						Key data for all active accounts will be exported and available to download as a .XLSX file.
						Please note the file will not contain information on any related entities (such as directors
						and shareholders, if applicable), nor account opening documentation, nor ownership
						structures.
					</Box>
				</Alert>
				<ExportHistoryTable />
			</TFPageContent>
		</>
	);
};
