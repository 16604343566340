import React, { useEffect, useState } from 'react';

import type { UseQueryResult } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { Button, Modal, useDisclosure } from '@tf/ui';

import { useMonitoringMatchesCommentsQuery } from '@/core/api/monitoring';
import { MonitoringCardComments } from '@/components/monitoring';

interface Props {
	entityId: number;
	matchId: string;
	monitoringMatchesQuery: UseQueryResult<BackendTypes.MonitoringSearchDetails, Error>;
}

export const MonitoringCardCommentsButton: React.FC<Props> = ({ entityId, matchId }: Props) => {
	const { data: storedComments } = useMonitoringMatchesCommentsQuery(entityId);
	const { isOpen, toggle } = useDisclosure();

	const [commentsCount, setCommentsCount] = useState<number | null>(null);

	const buttonText = `Open ${commentsCount ? '(' + commentsCount + ')' : ''}`;

	useEffect(() => {
		if (!storedComments?.length) return;
		setCommentsCount(storedComments.filter((item: any) => item.matchId === matchId).length || null);
	}, [matchId, storedComments]);

	if (!isOpen) {
		return (
			<Button size="sm" variant="subtle" onClick={toggle}>
				{buttonText}
			</Button>
		);
	}

	return (
		<>
			<Button size="sm" variant="subtle" onClick={toggle}>
				{buttonText}
			</Button>
			<Modal title="Comments" size={600} opened onClose={toggle}>
				<MonitoringCardComments entityId={entityId} matchId={matchId} />
			</Modal>
		</>
	);
};
