import type { ReactElement } from 'react';

import type { BackendTypes } from '@tf/api';
import { Icon } from '@tf/ui';

import { UserCapabilities } from '@/core/types';

/**
 * List all available capabilities with labels
 */
export const capabilities: Record<BackendTypes.RoleCapabilityValue, string> = {
	[UserCapabilities.CAN_PREPARE_ACCOUNTS_DATA]: 'Account management',
	[UserCapabilities.CAN_REVIEW_ACCOUNTS_DATA]: 'Account review',
	[UserCapabilities.CAN_MANAGE_MONITORING]: 'Ongoing monitoring',
	[UserCapabilities.CAN_MANAGE_USERS]: 'User management',
	[UserCapabilities.CAN_EXPORT_ACCOUNT_DATA]: 'Data export',
};

export const capabilitiesIcons: Record<BackendTypes.RoleCapabilityValue, ReactElement> = {
	[UserCapabilities.CAN_PREPARE_ACCOUNTS_DATA]: <Icon.IconSettings />,
	[UserCapabilities.CAN_REVIEW_ACCOUNTS_DATA]: <Icon.IconFileLike />,
	[UserCapabilities.CAN_MANAGE_MONITORING]: <Icon.IconHeartRateMonitor />,
	[UserCapabilities.CAN_MANAGE_USERS]: <Icon.IconUserCog />,
	[UserCapabilities.CAN_EXPORT_ACCOUNT_DATA]: <Icon.IconFileTypeXls />,
};
