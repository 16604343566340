import React from 'react';

import { useSelector } from '@datagrid/state';

import { Button } from '@tf/ui';

import { useAcknowledgeMonitoringSearchMutation } from '@/core/api/monitoring';
import { monitoringStore } from '@/core/stores';

export const AcknowledgeChangesButton = ({ graphId }: { graphId: number }) => {
	const monitoring = useSelector(() => monitoringStore.get());

	const count = monitoring?.searchInfo?.unacknowledgedChangesCount ?? 0;
	const mutation = useAcknowledgeMonitoringSearchMutation(graphId);
	return (
		<Button size="sm" disabled={!count} onClick={() => mutation.mutate()}>
			{count > 0 ? 'Acknowledge changes' : 'No changes'}
		</Button>
	);
};
