import React from 'react';

import { ActionIcon, Icon, TFNotifier } from '@tf/ui';

import { useDeleteCRATemplateMutation } from '@/core/api/craSettings';

export const DeleteTemplateButton: React.FC<{ accountKind: string }> = ({ accountKind }) => {
	const deleteCRATemplateMutation = useDeleteCRATemplateMutation();

	return (
		<ActionIcon
			size="md"
			variant="light"
			color="red"
			onClick={() => {
				deleteCRATemplateMutation.mutate(
					{ accountKind },
					{
						onSuccess: () => {
							TFNotifier.success('Template deleted');
						},
						onError: () => {
							TFNotifier.error('Template deletion failed');
						},
					}
				);
			}}
		>
			<Icon.IconTrash size={12} />
		</ActionIcon>
	);
};
