import React from 'react';

import type { BackendTypes } from '@tf/api';
import { Anchor } from '@tf/ui';

import { useDownloadFileMutation } from '@/core/api/files';

export const DownloadTemplateLink: React.FC<{ file?: BackendTypes.FieldFile }> = ({ file }) => {
	const downloadFileMutation = useDownloadFileMutation();

	if (!file) {
		return <span>–</span>;
	}

	return (
		<Anchor
			component="button"
			type="button"
			onClick={() => {
				downloadFileMutation.mutate({ file });
			}}
		>
			{file.filename}
		</Anchor>
	);
};
