import type { BackendTypes } from '@tf/api';

export const getAvailableStatuses = ({
	status,
	verificationStatus,
}: {
	status: BackendTypes.OverviewAccountStatus | 'UNDEFINED';
	verificationStatus?: BackendTypes.ReviewState;
}) => {
	const result: BackendTypes.OverviewAccountStatus[] = [];

	switch (status) {
		case 'UNDEFINED': {
			result.push('PROSPECT');
			break;
		}
		case 'PROSPECT': {
			if (verificationStatus === 'VERIFIED' || verificationStatus === 'UPDATE_REQUIRED') {
				result.push('ACTIVE');
			}

			result.push('REJECTED');

			break;
		}
		case 'ACTIVE': {
			result.push('SUSPENDED', 'CLOSED');
			break;
		}
		case 'SUSPENDED': {
			result.push('ACTIVE', 'CLOSED');
			break;
		}
	}

	if (status !== 'ARCHIVED') {
		result.push('ARCHIVED');
	}

	return result;
};
