import { useEffect } from 'react';

import { useObservable, useSelector } from '@datagrid/state';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { TFNotifier } from '@tf/ui';
import type { FormSegment } from '@tf/utils';
import { rawRevision } from '@tf/utils';

import { accountQueryOptions } from '@/core/api/account';
import { accountEventLogsQueryOptions } from '@/core/api/accountEvenstLog';
import { overviewAccountQueryOptions } from '@/core/api/overview';
import { segmentsQueryOptions, useSegmentsQuery } from '@/core/api/segments';
import { useSelectedAccount } from '@/core/hooks/use-selected-account';
import { writeSegmentsMutation } from '@/core/mutations';
import { getSelfSegment } from '@/core/utils';

interface Values {
	CORPORATE_ACCOUNT__SELF: {
		name: string;
	};
	COMMON__RM_FEEDBACK: {
		client_account_status: BackendTypes.OverviewAccountStatus;
		onboarding_date: string;
		closing_date: string;
		account_id: string;
	};
	COMMON__COMPLIANCE_FEEDBACK: {
		client_risk_level: BackendTypes.OverviewAccountRiskLevel;
	};
	COMMON__COMPLIANCE_DATES: {
		kyc_approval_date: string;
		next_kyc_review_date: string;
	};

	[field: string]: any;
}

type ValuesUpdate = {
	[SegmentKind in keyof Values]?: Partial<Values[SegmentKind]>;
};

interface State {
	values?: Values;
	revisions?: Record<string, number>;
	validationStatuses?: Record<string, BackendTypes.FieldValidationStatus>;
}

export const useAccountInfoSegments = () => {
	const { meta } = useSelectedAccount();

	const state = useObservable<State>({});

	// Extract target segment identities
	const selfSegmentKind = getSelfSegment(meta.entityKind);
	const formSegments: FormSegment[] = meta.listSegments
		.filter((s) => {
			const segmentKind = s.SegmentIdentity.segmentKind;
			const requiredSegments = [
				selfSegmentKind,
				'COMMON__RM_FEEDBACK',
				'COMMON__COMPLIANCE_FEEDBACK',
				'COMMON__COMPLIANCE_DATES',
			];
			return requiredSegments.includes(segmentKind);
		})
		.map((s) => ({ ...s.SegmentIdentity, accessMode: s.AccessMode }));

	// * Get segments data
	const segmentsQuery = useSegmentsQuery(formSegments);

	useEffect(() => {
		if (segmentsQuery.data) {
			prepareState(segmentsQuery.data);
		}
	}, [segmentsQuery.data]);

	function prepareState(data: BackendTypes.SegmentsReadData) {
		const nextState: Required<State> = {
			values: {} as Values,
			revisions: {},
			validationStatuses: {},
		};

		for (const segmentData of data) {
			const { info, segment, validationStatus } = segmentData.container;
			const segmentKind = info.SegmentIdentity.segmentKind;
			nextState.values[segmentKind] = segment;
			nextState.revisions[segmentKind] = info.Revision.revision;
			nextState.validationStatuses[segmentKind] = validationStatus;
		}

		state.assign(nextState);
	}

	const queryClient = useQueryClient();

	const updateSegments = (values: ValuesUpdate) => {
		const payload: BackendTypes.SegmentsWritePayload = [];
		for (const formSegment of formSegments) {
			if (formSegment.accessMode === 'VIEW') {
				continue;
			}

			payload.push({
				segmentIdentity: formSegment,
				segment: {
					...state.values?.get()?.[formSegment.segmentKind],
					...values[formSegment.segmentKind],
				},
				revision: {
					...rawRevision,
					revision: state.revisions?.peek()?.[formSegment.segmentKind] ?? rawRevision.revision,
				},
				validationStatus: state.validationStatuses?.peek()?.[formSegment.segmentKind],
			});
		}

		return writeSegmentsMutation(payload);
	};

	const onSuccess = (res: BackendTypes.SegmentsWriteData) => {
		// * Update revisions
		for (const segmentData of res) {
			const { SegmentIdentity, Revision } = segmentData.container.info;
			const segmentKind = SegmentIdentity.segmentKind;
			if (state.revisions) {
				state.revisions.set((prev) => ({ ...prev, [segmentKind]: Revision.revision }));
			}
		}

		TFNotifier.success('Account updated successfully!');
		queryClient.refetchQueries(overviewAccountQueryOptions(meta.graphNodeId));
		queryClient.refetchQueries(accountQueryOptions(meta.graphNodeId));
		queryClient.refetchQueries(segmentsQueryOptions(formSegments));
		queryClient.invalidateQueries(accountEventLogsQueryOptions(meta.graphNodeId));
	};

	// Getters and mutations for separate fields
	const accountStatus = useSelector(
		() => state.values?.get()?.COMMON__RM_FEEDBACK?.client_account_status
	);
	const onboardingDate = useSelector(() => state.values?.get()?.COMMON__RM_FEEDBACK.onboarding_date);
	const closingDate = useSelector(() => state.values?.get()?.COMMON__RM_FEEDBACK.closing_date);

	const kycApprovalDate = useSelector(
		() => state.values?.get()?.COMMON__COMPLIANCE_DATES?.kyc_approval_date
	);

	const kycApprovalDateMutation = useMutation({
		mutationFn: (newDate: string) => {
			return updateSegments({ COMMON__COMPLIANCE_DATES: { kyc_approval_date: newDate } });
		},
		onSuccess,
	});

	const nextKycReviewDate = useSelector(
		() => state.values?.get()?.COMMON__COMPLIANCE_DATES?.next_kyc_review_date
	);

	const nextKycReviewDateMutation = useMutation({
		mutationFn: (newDate: string) => {
			return updateSegments({ COMMON__COMPLIANCE_DATES: { next_kyc_review_date: newDate } });
		},
		onSuccess,
	});

	const accountDataMutation = useMutation({
		mutationFn: ({
			client_account_status,
			onboarding_date,
			closing_date,
			account_id,
		}: Values['COMMON__RM_FEEDBACK']) => {
			return updateSegments({
				COMMON__RM_FEEDBACK: {
					client_account_status,
					onboarding_date,
					closing_date,
					account_id,
				},
			});
		},
		onSuccess,
	});

	const clientRiskLevel = useSelector(
		() => state.values?.get()?.COMMON__COMPLIANCE_FEEDBACK?.client_risk_level
	);

	const defaultValues = [
		{ fieldName: 'onboarding_date', fieldType: 'date' },
		{ fieldName: 'closing_date', fieldType: 'date' },
		{ fieldName: 'kyc_approval_date', fieldType: 'date' },
		{ fieldName: 'next_kyc_review_date', fieldType: 'date' },
	];

	return {
		state,
		accountStatus,
		onboardingDate,
		closingDate,
		kycApprovalDate,
		kycApprovalDateMutation,
		nextKycReviewDate,
		nextKycReviewDateMutation,
		formSegments,
		accountDataMutation,
		clientRiskLevel,
		defaultValues,
	};
};
