import { Link } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import { Alert, Anchor, Box, List, Popover, TFText } from '@tf/ui';
import { S } from '@tf/utils';

import { getLabel } from '@/core/utils';

interface Props {
	account?: BackendTypes.OverviewAccount;
	children?: React.ReactNode;
}

const colors: Record<BackendTypes.ReviewState, string> = {
	DRAFT: 'dimmed',
	READY_TO_REVIEW: 'brand',
	UNDER_REVIEW: 'brand',
	UPDATE_REQUIRED: 'orange',
	VERIFIED: 'green',
};

export const ReviewState: React.FC<Props> = (props) => {
	if (!props.account) {
		return null;
	}

	const { reviewStates, ...account } = props.account;
	const mainReview = reviewStates.find((r) => r.connectionKind === 'APPLICANT');
	const additionalReviews = reviewStates.filter((r) => r.connectionKind !== 'APPLICANT');

	if (!mainReview) {
		return null;
	}

	const label = getLabel(mainReview.state, { kind: 'reviewState' });
	return (
		<Popover
			position="bottom"
			withArrow={false}
			shadow="md"
			withinPortal={true}
			disabled={reviewStates.length === 0}
			styles={{ dropdown: { padding: 0 } }}
		>
			<Popover.Target>
				<Box>
					{props.children ? (
						props.children
					) : (
						<Anchor inherit c={colors[mainReview.state]}>
							{label}
						</Anchor>
					)}
				</Box>
			</Popover.Target>

			<Popover.Dropdown>
				<Alert p=".75rem" radius={0}>
					<Anchor fw={500} component={Link} to={`/accounts/${account.nodeId}/applicant`}>
						Applicant review: {label}
					</Anchor>
				</Alert>
				{additionalReviews.length > 0 && (
					<>
						<Box p=".5rem .75rem">
							<TFText size="sm" c="dimmed" mb=".25rem">
								Other reviews:
							</TFText>
							<List size="sm" spacing="xs">
								{additionalReviews.map(({ connectionKind, connectionId, state, name }, index) => {
									const key = `${connectionId}.${index}`;
									const path = ['/accounts', account.nodeId, S.slugify(connectionKind)].join('/');

									return (
										<List.Item key={key}>
											<Anchor component={Link} to={path} lineClamp={1}>
												{name || 'Unknown entity'} ({getLabel(state, { kind: 'reviewState' })})
											</Anchor>
										</List.Item>
									);
								})}
							</List>
						</Box>
					</>
				)}
			</Popover.Dropdown>
		</Popover>
	);
};
