import { queryOptions, useQuery } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { BackendClient } from '@tf/api';

export const segmentsQueryOptions = (segmentIdentities: BackendTypes.SegmentIdentity[]) => {
	const segmentsKey = segmentIdentities.reduce((acc, s) => {
		acc[s.graphId] = acc[s.graphId] ?? {};
		acc[s.graphId][s.segmentKind] = true;
		return acc;
	}, {} as Record<BackendTypes.GraphId, Record<BackendTypes.SegmentKind, true>>);

	return queryOptions({
		queryKey: ['segments', segmentsKey],
		queryFn: async (): Promise<BackendTypes.SegmentsReadData> => {
			const res = await BackendClient.getInstance().segmentsRead(segmentIdentities);
			return res.json();
		},
		// Completely disable cache
		staleTime: 0,
		gcTime: 0,
	});
};

type AdditionalSegmentsQueryOptions = Omit<
	ReturnType<typeof segmentsQueryOptions>,
	'queryFn' | 'queryKey' | 'staleTime' | 'gcTime'
>;

export const useSegmentsQuery = (
	segmentIdentities: BackendTypes.SegmentIdentity[],
	options: AdditionalSegmentsQueryOptions = {}
) => {
	return useQuery({ ...segmentsQueryOptions(segmentIdentities), ...options });
};
