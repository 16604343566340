import React from 'react';

import { ClerkProvider } from '@clerk/clerk-react';
import { useSelector } from '@datagrid/state';

import { useMantineTheme } from '@tf/ui';

import { configStore } from '@/core/stores';

export const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const config = useSelector(() => configStore.get());
	const clerkPubKey = config.auth.clerk_publishable_key;

	const { colors } = useMantineTheme();

	return (
		<ClerkProvider
			appearance={{
				variables: {
					colorPrimary: colors.brand[6],
				},
				elements: {
					card: {
						backgroundColor: '#fff',
						boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 20px -4px',
						borderColor: colors.gray[2],
					},
					cardBox: {
						borderWidth: 0.5,
						borderRadius: '8px',
						boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 20px -4px',
					},
					headerSubtitle: {
						display: 'none',
					},
					formFieldInput: {
						padding: '0.625rem 1rem',
						'&:focus': {
							boxShadow: 'rgb(151, 185, 248) 0px 0px 0px 1px !important',
						},
					},
					formButtonPrimary: {
						padding: '0.625rem 1rem',
						background: colors.brand[6] + ' !important',
						boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 20px -4px',
						textTransform: 'uppercase',
						fontSize: '11px',
						fontWeight: 'bold',
						'& svg': {
							display: 'none',
						},
						'&::after': {
							display: 'none',
						},
						'&:hover': {
							backgroundColor: colors.brand[7] + ' !important',
						},
					},
				},
			}}
			publishableKey={clerkPubKey}
		>
			{children}
		</ClerkProvider>
	);
};
