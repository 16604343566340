import React, { useEffect } from 'react';

import { useSelector } from '@datagrid/state';
import { useNavigate } from 'react-router-dom';

import { getResultHit, JobSearchResultCard, TFPageContent, TFPageHeader } from '@tf/shared';
import { Box, Button, Icon } from '@tf/ui';
import { T } from '@tf/utils';

import { appStore, instantCheckStore } from '@/core/stores';

const InstantCheckHitsLayout = () => {
	const navigate = useNavigate();
	const { hits, selectedId } = useSelector(() => instantCheckStore.get());
	// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
	const hit = (hits || [])[selectedId];
	const tenant = appStore.tenant?.get();
	T.assertNotNullish(tenant, 'no tenant in appStore');

	useEffect(() => {
		if (!hit) navigate('/instant-check');
	}, [hit]);

	const onNewSearch = () => {
		instantCheckStore.set((s) => ({ ...s, hits: null, selectedId: null }));
		navigate('/instant-check');
	};

	return (
		<>
			<TFPageHeader
				tenant={tenant}
				title={hit?.doc?.name || 'N/A'}
				breadcrumbs={[
					{ href: '/instant-check', title: 'Instant check' },
					{ href: '/instant-check/hits', title: 'Search result' },
					{ href: '/instant-check/card', title: hit?.doc?.name || 'N/A' },
				]}
				toolbar={
					<Button leftSection={<Icon.IconSearch size={14} stroke={2.5} />} onClick={onNewSearch}>
						New Search
					</Button>
				}
			/>
			<TFPageContent>
				<Box>
					<JobSearchResultCard hit={getResultHit(hit)} />
				</Box>
			</TFPageContent>
		</>
	);
};

export default InstantCheckHitsLayout;
