import type { BackendTypes } from '@tf/api';

type GetLabelOptions = {
	noun?: 'singular' | 'plural';
	kind?: 'role' | 'reviewState' | 'accountStatus';
};

const reviewStates = new Map<BackendTypes.ReviewState, string>([
	['DRAFT', 'Draft'],
	['READY_TO_REVIEW', 'Ready to review'],
	['UNDER_REVIEW', 'Pending review'],
	['UPDATE_REQUIRED', 'Update required'],
	['VERIFIED', 'Verified'],
]);

type AccountStatusType = BackendTypes.OverviewAccountStatus | 'UNDEFINED';

const accountStatus = new Map<AccountStatusType, string>([
	['UNDEFINED', 'Not defined'],
	['PROSPECT', 'Prospect'],
	['ACTIVE', 'Active'],
	['SUSPENDED', 'Suspended'],
	['REJECTED', 'Rejected'],
	['CLOSED', 'Closed'],
	['ARCHIVED', 'Archived'],
]);

/**
 * Get a label for a value
 * @param value
 * @param opts
 */
export const getLabel = (value: string, opts?: GetLabelOptions) => {
	const { noun = 'singular', kind = 'global' } = opts || {};

	if (kind === 'reviewState') {
		return reviewStates.get(value as BackendTypes.ReviewState) || value;
	}

	if (kind === 'accountStatus') {
		return accountStatus.get(value as AccountStatusType) || value;
	}

	return value;
};
