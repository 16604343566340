import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import type { FC } from 'react';

import { BackendClient } from '@tf/api';
import type { ButtonProps } from '@tf/ui';
import { Button, Group, Icon, Modal, TFText, useDisclosure } from '@tf/ui';

import { useAccountParams, useSelectedReview } from '@/core/hooks';
import { CommentsList, CounterBadge } from '@/components/shared';

interface Props {
	graphId: number;
	formId: string;
	size?: ButtonProps['size'];
}

export const FormCommentsButton: FC<Props> = ({ graphId, formId, size }) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const commentsModal = useDisclosure();

	const { accountId, connectionId } = useAccountParams();
	const review = useSelectedReview();
	const api = BackendClient.getInstance();
	const queryClient = useQueryClient();

	// * Pick comments for current form by graphId and formId
	const comments = review.listFormComments
		.filter(({ data }) => data.graphId === graphId && data.formId === formId)
		.sort((a, b) => a.createdAt.localeCompare(b.createdAt));

	const sendComment = async (nextComment: string) => {
		try {
			if (nextComment.length === 0) return;
			setIsSubmitting(true);
			await api.reviewFormCommentWrite({
				reviewConnectionId: connectionId,
				data: { graphId, formId, text: nextComment },
			});
			await queryClient.refetchQueries({ queryKey: ['accounts', accountId] });
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<>
			<Button
				variant="light"
				size={size || 'sm'}
				leftSection={<Icon.IconMessageCircle size={16} />}
				onClick={commentsModal.open}
			>
				<Group gap=".25rem" sx={{ flexWrap: 'nowrap' }}>
					<TFText inherit>Comments</TFText>
					{comments.length > 0 ? <CounterBadge value={comments.length} /> : null}
				</Group>
			</Button>

			{commentsModal.isOpen && (
				<Modal title="Comments" size={600} opened onClose={commentsModal.close}>
					<CommentsList comments={comments} sendComment={sendComment} isSubmitting={isSubmitting} />
				</Modal>
			)}
		</>
	);
};
