import React from 'react';

import type { MonitoringHit } from '@tf/shared';
import { getResultHit, JobSearchResultCard } from '@tf/shared';

interface Props {
	hit: MonitoringHit | null;
}

export const MonitoringMatchCard: React.FC<Props> = ({ hit }) => {
	if (!hit) {
		return null;
	}

	return <JobSearchResultCard hit={getResultHit(hit)} />;
};
