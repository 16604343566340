import { BackendClient, type BackendTypes } from '@tf/api';

interface MutationInput {
	accountId: number;
	priority: BackendTypes.OverviewImportance;
}

export const setAccountPriorityMutation = async ({
	accountId,
	priority,
}: MutationInput): Promise<boolean> => {
	const res = await BackendClient.getInstance().setImportance(accountId, priority);
	return res.ok;
};
