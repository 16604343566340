import { fmt } from "@tf/utils";

export const setDefaultValue = ({ fieldName, defaultFields }: {
	fieldName: string,
	defaultFields: { fieldName: string, fieldType: string }[]
}) => {
	const defaultField = defaultFields.find(item => item.fieldName === fieldName);
	if (defaultField) {
		if (defaultField.fieldType === 'date') {
			return fmt.toDate(new Date(), { preset: 'date_struct' });
		}
		if (defaultField.fieldType === 'text') {
			return '';
		}
	}
}