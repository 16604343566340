import React from 'react';

import { Box, Stack } from '@tf/ui';

import { ComponentsMap } from './constants';
import type { FieldName, PrimitiveField } from './types';

interface Props {
	fields: PrimitiveField[];
	values?: Record<string, any>;
	onChange: (fields: Record<string, any>) => void;
}

export const PrimitiveFormBuilder: React.FC<Props> = ({ fields, values, onChange }) => {
	return (
		<Stack sx={{ gap: '0.75rem' }}>
			{fields?.map((field: PrimitiveField) => {
				const Component = ComponentsMap[field.component as FieldName];
				return (
					<Box key={field.name}>
						<Component
							value={values ? values[field.name] : undefined}
							label={field.label}
							onChange={(value: string) => {
								onChange({ ...values, [field.name]: value });
							}}
							disabled={field.disabled}
							{...field.props}
						/>
					</Box>
				);
			})}
		</Stack>
	);
};
