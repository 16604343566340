import React from 'react';

import { useClerk } from '@clerk/clerk-react';

import { TFErrorTemplate } from '@tf/shared';
import { Anchor, Button, TFText } from '@tf/ui';

export interface NoPermissionsRouteProps {
	email?: string;
}

export const NoPermissionsRoute: React.FC<NoPermissionsRouteProps> = ({ email }) => {
	const { signOut } = useClerk();

	const handleLogout = async () => {
		await signOut();
	};

	return (
		<TFErrorTemplate title="Permissions error" text="You don't have permissions for this application.">
			<TFText size=".85rem" c="dimmed">
				For details, please email us {email ? 'at' : ''}{' '}
				<Anchor href={`mailto:${email}`}>{email}</Anchor>
			</TFText>
			<Button fullWidth sx={{ marginTop: '1rem' }} onClick={handleLogout}>
				Sign Out
			</Button>
		</TFErrorTemplate>
	);
};
