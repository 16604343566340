import { queryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { BackendClient } from '@tf/api';

const craSettingsOptions = () => {
	return queryOptions({
		queryKey: ['craSettings'],
		queryFn: async (): Promise<BackendTypes.GetCraSettingsData> => {
			const res = await BackendClient.getInstance().getCraSettings();
			return res.json();
		},
	});
};

export const useCraSettings = () => {
	return useQuery(craSettingsOptions());
};

export const useUploadCRATemplateMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({
			accountKind,
			data,
		}: {
			accountKind: BackendTypes.GraphNodeKind;
			data: BackendTypes.CRACalculationTemplateUploadRequest;
		}) => {
			return BackendClient.getInstance().uploadCraTemplate(accountKind, data);
		},
		onSuccess: () => {
			queryClient.invalidateQueries(craSettingsOptions());
		},
	});
};

export const useDeleteCRATemplateMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({ accountKind }: { accountKind: BackendTypes.GraphNodeKind }) => {
			return BackendClient.getInstance().deleteCraTemplate(accountKind);
		},
		onSuccess: () => {
			queryClient.invalidateQueries(craSettingsOptions());
		},
	});
};

export const useSetCraAutoAssigneesGroupsMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (
			map: BackendTypes.AssigneesManagersRiskLevelMap
		): Promise<BackendTypes.SetAssigneesManagersRiskLevelMapData> => {
			return BackendClient.getInstance().setAssigneesManagersRiskLevelMap(map);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['autoAssign'] });
		},
	});
};

const craAutoAssigneesGroupsOptions = () => {
	return queryOptions({
		queryKey: ['autoAssign'],
		queryFn: async (): Promise<BackendTypes.GetAssigneesManagersRiskLevelMapData> => {
			const res = await BackendClient.getInstance().getAssigneesManagersRiskLevelMap();
			return res.json();
		},
	});
};

export const useCraAutoAssigneesGroups = () => {
	return useQuery(craAutoAssigneesGroupsOptions());
};
