import { observable } from '@datagrid/state';

import type { BackendTypes } from '@tf/api';

interface monitoringStoreType {
	selected: BackendTypes.Entity | null;
	hits: BackendTypes.AdhocSearchResult['rawResponse'] | null;
	associations: BackendTypes.OverviewAccount[] | null;
	searchInfo: BackendTypes.MonitoringSearch | null;
}

export const monitoringStore = observable<monitoringStoreType>({
	selected: null,
	hits: null,
	associations: null,
	searchInfo: null,
});
