import React, { useEffect, useState } from 'react';

import { useSelector } from '@datagrid/state';

import { BackendClient } from '@tf/api';
import { Box, Modal, TFText } from '@tf/ui';

import { useGetTenantToken } from '@/core/hooks';
import { configStore } from '@/core/stores';
import { ModalFooter } from '@/components/shared';

interface Props {
	definitionsRevision: string;
}

export const BackendClientInitialization: React.FC<Props> = ({ definitionsRevision }) => {
	const config = useSelector(() => configStore.get());

	const getTenantToken = useGetTenantToken();

	const [isModalOpened, setModalOpened] = useState(false);

	useEffect(() => {
		const fetchWithStatusCheck = async (input: RequestInfo | URL, init?: RequestInit) => {
			const token = await getTenantToken();
			const response = await fetch(input, {
				...init,
				headers: {
					...init?.headers,
					Authorization: `Bearer ${token?.value}`,
					'Configuration-Revision': definitionsRevision,
				},
			});

			if (response.status === 409) {
				setModalOpened(true);
			}

			return response;
		};

		BackendClient.init(config.urls.backend, fetchWithStatusCheck);
	}, [config.urls.backend, definitionsRevision, getTenantToken]);

	const onReload = async () => {
		window.location.reload();
	};

	return (
		<Modal title="App is oudated" opened={isModalOpened} withCloseButton={false} onClose={() => {}}>
			<Box px="1rem" py="0.75rem">
				<TFText>Please reload page to get new version</TFText>
			</Box>

			<ModalFooter submitText="Reload" onSubmit={onReload} />
		</Modal>
	);
};
