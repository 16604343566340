import React, { useEffect } from 'react';

import { useSelector } from '@datagrid/state';
import { useNavigate, useParams } from 'react-router-dom';

import { TFLoadingOverlay } from '@tf/ui';

import { useMonitoringHits } from '@/core/hooks';
import { monitoringStore } from '@/core/stores';
import { MonitoringMatchWrapper } from '@/components/monitoring';

type PathParams = {
	matchId: string;
	graphId: string;
};

export const MonitoringMatchLayout = () => {
	const navigate = useNavigate();
	const params = useParams<PathParams>();
	const monitoring = useSelector(() => monitoringStore.get());

	const matchId = params.matchId;
	const graphId = parseInt(params.graphId as string, 10);
	useMonitoringHits({
		graphId,
		page: 1,
		limit: 100,
	});

	useEffect(() => {
		// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
		const hit = monitoring?.hits?.find((item) => item.id === matchId);

		if (!hit) {
			goToMatchesList();
		}
	}, [monitoring.hits]);

	const goToMatchesList = () => {
		navigate(`/monitoring/${graphId}`);
	};

	// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
	if (monitoring?.hits?.find((item) => item.id === matchId)) {
		return (
			<MonitoringMatchWrapper
				// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
				hit={monitoring?.hits?.find((item) => item.id === matchId)}
			/>
		);
	}

	return <TFLoadingOverlay />;
};
