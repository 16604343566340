import React, { useEffect } from 'react';

import { useUser } from '@clerk/clerk-react';
import { useSelector } from '@datagrid/state';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import { configStore } from '@/core/stores';
import { useAppInstanceId } from '@/core/useAppInstanceId';

export const SentryInitialization = () => {
	const config = useSelector(() => configStore.get());
	const appInstanceId = useAppInstanceId();

	useEffect(() => {
		if (config?.sentry?.dsn) {
			Sentry.init({
				environment: appInstanceId,
				dsn: config.sentry.dsn,
				integrations: [
					new Sentry.BrowserTracing({
						routingInstrumentation: Sentry.reactRouterV6Instrumentation(
							React.useEffect,
							useLocation,
							useNavigationType,
							createRoutesFromChildren,
							matchRoutes
						),
					}),
				],
				release: import.meta.env.VITE_APP_VERSION,
				tracesSampleRate: 0.1,
				replaysSessionSampleRate: 0.1,
				replaysOnErrorSampleRate: 1.0,
				enabled: true,
			});
		}
	}, [appInstanceId, config.sentry?.dsn]);

	const { user } = useUser();

	useEffect(() => {
		Sentry.setTag('instance', appInstanceId);

		if (user) {
			Sentry.setUser({
				id: `${appInstanceId}:${String(user.id)}`,
				username: user.username ?? '',
				email: user.primaryEmailAddress?.emailAddress ?? '',
				// TODO: add capabilities?
			});
		} else {
			Sentry.setUser(null);
		}
	}, [appInstanceId, user]);

	return null;
};
