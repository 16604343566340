import { observable } from '@datagrid/state';

export interface InstantCheckStoreType {
	hits: any[] | null;
	selectedId: number | null;
	searchId: string | null;
	searchName: string | null;
}

export const instantCheckStore = observable<InstantCheckStoreType>({
	hits: null,
	selectedId: null,
	searchId: null,
	searchName: null,
});
