import { useState } from 'react';

import type { BackendTypes } from '@tf/api';
import type { ExtendedFormDefinition } from '@tf/shared';
import { Box, Checkbox, Modal, Stack, TFText } from '@tf/ui';

import { useReviewDecisionMutation } from '@/core/api/reviewDecision';
import { useAccountParams } from '@/core/hooks';
import { ModalFooter } from '@/components/shared';

interface Props {
	graphId: number;
	forms: ExtendedFormDefinition<BackendTypes.FormDefinition>[];
	isOpened: boolean;
	onClose: () => void;
}

const stepTitle = {
	SELECT: 'Select forms',
	CONFIRM: 'Action confirmation',
};

export const BulkReviewModal: React.FC<Props> = ({ graphId, forms, isOpened, onClose }) => {
	const initialForms = forms.reduce((formSelection, form) => {
		return { ...formSelection, [form.name]: false };
	}, {} as Record<string, boolean>);

	const [step, setStep] = useState<'SELECT' | 'CONFIRM'>('SELECT');
	const [formsState, setFormsState] = useState<Record<string, boolean>>(initialForms);

	const closeModal = () => {
		setStep('SELECT');
		setFormsState(initialForms);
		onClose();
	};

	const toggleForm = (formName: string) => {
		setFormsState((prevForms) => {
			return { ...prevForms, [formName]: !prevForms[formName] };
		});
	};

	const isFormSelected = (formName: string) => formsState[formName];

	const { accountId, connectionId, formKind } = useAccountParams();
	const { mutateAsync } = useReviewDecisionMutation();

	const handleSubmit = async () => {
		const segmentsToApprove = forms
			.filter((form) => isFormSelected(form.name))
			.flatMap((form) => form.segmentIdentities);

		await mutateAsync({
			accountId,
			connectionId,
			graphId,
			formKind,
			segmentIdentities: segmentsToApprove,
			status: 'ACCEPTED',
		});

		closeModal();
	};

	const isAnyFormSelected = forms.some((form) => isFormSelected(form.name));

	return (
		<Modal title={stepTitle[step]} opened={isOpened} onClose={closeModal}>
			{step === 'SELECT' ? (
				<>
					<Stack px="1rem" py="0.75rem" gap="sm">
						<TFText>Select forms you&apos;d like to approve:</TFText>
						{forms.map((form) => (
							<Checkbox
								key={form.name}
								label={form.label}
								checked={isFormSelected(form.name)}
								onChange={() => toggleForm(form.name)}
							/>
						))}
					</Stack>

					<ModalFooter
						submitText="Review"
						isDisabled={!isAnyFormSelected}
						onCancel={closeModal}
						onSubmit={() => setStep('CONFIRM')}
					/>
				</>
			) : null}

			{step === 'CONFIRM' ? (
				<>
					<Box px="1rem" py="0.75rem">
						<TFText>Please confirm you&apos;d like to approve all selected forms</TFText>
					</Box>

					<ModalFooter submitText="Approve" onCancel={() => setStep('SELECT')} onSubmit={handleSubmit} />
				</>
			) : null}
		</Modal>
	);
};
