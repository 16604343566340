import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { BackendClient } from '@tf/api';
import { rawRevision } from '@tf/utils';

import { getSelfSegment } from '@/core/utils';

const useNewConnectionSuccessHandler = () => {
	const queryClient = useQueryClient();

	return async () => {
		await queryClient.invalidateQueries({ queryKey: ['accounts'] });
	};
};

interface ConnectNewEntityMutationParams {
	parentEntityId: BackendTypes.GraphId;
	connectionKind: BackendTypes.EntityConnectionKind;
	entityKind: BackendTypes.EntityKind;
	selfSegmentData: Record<string, any>;
}

export const useConnectNewEntityMutation = () => {
	const onSuccess = useNewConnectionSuccessHandler();

	return useMutation({
		mutationFn: async ({
			parentEntityId,
			connectionKind,
			entityKind,
			selfSegmentData,
		}: ConnectNewEntityMutationParams) => {
			const response = await BackendClient.getInstance().entityConnectNew(
				parentEntityId,
				connectionKind,
				entityKind,
				{
					listEntitySegments: [
						{
							revision: rawRevision,
							segmentIdentity: {
								segmentKind: getSelfSegment(entityKind),
								graphId: -2,
							},
							segment: selfSegmentData,
						},
					],
					listConnectionSegments: [],
				}
			);

			return (await response.json()) as BackendTypes.EntityConnectNewData;
		},
		onSuccess,
	});
};

interface ConnectExistingEntityMutationParams {
	parentEntityId: BackendTypes.GraphId;
	connectionKind: BackendTypes.EntityConnectionKind;
	entityId: BackendTypes.GraphId;
}

export const useConnectExistingEntityMutation = () => {
	const onSuccess = useNewConnectionSuccessHandler();

	return useMutation({
		mutationFn: async ({
			parentEntityId,
			connectionKind,
			entityId,
		}: ConnectExistingEntityMutationParams) => {
			const response = await BackendClient.getInstance().entityConnectExisting(
				parentEntityId,
				connectionKind,
				entityId,
				[]
			);

			return (await response.json()) as BackendTypes.EntityConnectExistingData;
		},
		onSuccess,
	});
};

interface DisconnectEntityMutationParams {
	parentEntityId: BackendTypes.GraphId;
	connectionKind: BackendTypes.EntityConnectionKind;
	entityId: BackendTypes.GraphId;
}

export const useDisconnectEntityMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({ parentEntityId, connectionKind, entityId }: DisconnectEntityMutationParams) => {
			const response = await BackendClient.getInstance().entityDisconnectEntity(
				parentEntityId,
				connectionKind,
				entityId
			);

			return (await response.json()) as BackendTypes.EntityDisconnectEntityData;
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['accounts'] });
		},
	});
};
