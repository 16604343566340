import React from 'react';

import { TFLoadingOverlay } from '@tf/ui';

import { useAccount } from '@/core/api/account';
import { useAccountParams } from '@/core/hooks';

export const AccountProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { accountId } = useAccountParams();
	const { isPending } = useAccount(accountId);

	if (isPending) {
		return <TFLoadingOverlay label="Account loading..." h={500} />;
	}

	return <>{children}</>;
};
