import { useSelector } from '@datagrid/state';
import { Link, useLocation } from 'react-router-dom';

import { Box, Button, Center, createStyles, lighten, TFLogo } from '@tf/ui';

import { configStore } from '@/core/stores';
import { UserCapabilities } from '@/core/types';
import { useCurrentUserCapabilityCheck } from '@/core/utils';

const useStyles = createStyles(({ colors }) => ({
	menu: {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
	},
	logo: {
		backgroundColor: '#fff',
		display: 'inline-flex',
		marginRight: '1rem',
		height: 22,
		width: 22,
		'&:hover': {
			backgroundColor: colors.bra,
		},
	},
	menuButton: {
		height: 44,
		borderRadius: 0,
		borderBottomStyle: 'solid',
		borderBottomWidth: 1.5,
		borderBottomColor: 'transparent',
		'&[data-active="true"]': {
			backgroundColor: lighten(colors.brand[0], 0.5),
			borderBottomColor: colors.brand[6],
		},
	},
}));

const menuItems = [
	{
		label: 'Accounts',
		path: '/',
	},

	{
		label: 'Instant check',
		path: '/instant-check',
	},
];

export const AppMenu = () => {
	const { classes } = useStyles();
	const config = useSelector(() => configStore.get());
	const { pathname } = useLocation();

	const withObserver = Boolean(Number(config.feature_flags?.['entity_observer']));

	const isMonitoringRules = useCurrentUserCapabilityCheck(UserCapabilities.CAN_MANAGE_MONITORING);

	if (isMonitoringRules && !menuItems.find((item) => item.path === '/monitoring')) {
		menuItems.push({
			label: 'Monitoring',
			path: '/monitoring',
		});
	}

	if (withObserver) {
		menuItems.push({
			label: 'Entities',
			path: '/entities',
		});
	}

	return (
		<Box className={classes.menu}>
			<Center component={Link} to="/" h={44}>
				<Box className={classes.logo}>
					<TFLogo size={22} />
				</Box>
			</Center>

			{menuItems.map((item) => {
				let isActive: boolean;
				if (item.path === '/') {
					isActive = pathname.startsWith('/accounts') || pathname === '/';
				} else {
					isActive = pathname.startsWith(item.path);
				}

				return (
					<Button
						h="44px"
						key={item.path}
						component={Link}
						to={item.path}
						variant="subtle"
						className={classes.menuButton}
						data-active={isActive}
					>
						{item.label}
					</Button>
				);
			})}
		</Box>
	);
};
