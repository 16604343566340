import { TFPageContent, TFPageHeader } from '@tf/shared';
import { T } from '@tf/utils';

import { appStore } from '@/core/stores';
import { UserCapabilities } from '@/core/types';
import { useCurrentUserCapabilityCheck } from '@/core/utils';
import { AccountTable } from '@/components/accounts';
import { AddAccountButton } from '@/components/shared';

const AccountListRoute = () => {
	const canEditAccounts = useCurrentUserCapabilityCheck(UserCapabilities.CAN_PREPARE_ACCOUNTS_DATA);
	const tenant = appStore.tenant?.get();
	T.assertNotNullish(tenant, 'no tenant in appStore');

	return (
		<>
			<TFPageHeader tenant={tenant} title="Accounts" toolbar={canEditAccounts && <AddAccountButton />} />
			<TFPageContent>
				<AccountTable />
			</TFPageContent>
		</>
	);
};

export default AccountListRoute;
