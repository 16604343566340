import React, { useState } from 'react';

import { useSelector } from '@datagrid/state';

import { type BackendTypes } from '@tf/api';
import {
	Box,
	Button,
	createStyles,
	EntityBadge,
	Flex,
	Icon,
	Loader,
	ScrollArea,
	Select,
	Stack,
	TextInput,
	TFListCard,
	TFNotifier,
	TFText,
	UnstyledButton,
	useDebounce,
} from '@tf/ui';
import type { ConnectionErrorResponse, SubmitHandler } from '@tf/utils';
import { connectionErrorsMap, S } from '@tf/utils';

import { useConnectExistingEntityMutation, useConnectNewEntityMutation } from '@/core/api/connections';
import { useEntityLookupQuery } from '@/core/api/entities';
import { useProcessingScripts } from '@/core/hooks';
import { appStore } from '@/core/stores';
import { getSelfSegment } from '@/core/utils';
import { useNavigateToEntity } from '@/core/utils/routes';
import { AppFormBuilder, ModalFooter } from '@/components/shared';

const useStyles = createStyles(({ colors }) => ({
	title: {
		color: colors.gray[9],
		fontWeight: 500,
		fontSize: 14,
		lineHeight: 'normal',
	},
	subtitle: {
		marginTop: '4px',
		color: colors.gray[4],
		fontWeight: 500,
		fontSize: 13,
		lineHeight: 'normal',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
}));

export interface ConnectionFormProps {
	parentEntity: {
		kind: BackendTypes.EntityKind;
		graphId: BackendTypes.GraphId;
	};
	connectionKind: BackendTypes.EntityConnectionKind;
	review?: {
		graphLinkId: BackendTypes.GraphId;
		connectionKind: BackendTypes.EntityConnectionKind;
	};
	skipRedirect?: boolean;
}

interface Props extends ConnectionFormProps {
	onSuccess: () => void;
}

type ConnectionType = 'new' | 'existing';
export const ConnectionForm: React.FC<Props> = ({
	parentEntity,
	connectionKind,
	review,
	skipRedirect,
	onSuccess,
}) => {
	const { navigateToEntity } = useNavigateToEntity();
	const { classes } = useStyles();

	// TODO: This is a temporary solution. We need to find a way to get the list of available entities
	const defs = useSelector(() => appStore.defs.get());
	const entityDef = defs.entities[parentEntity.kind];
	const connectionDef = entityDef.possibleConnections.find((c) => c.kind === connectionKind);
	const availableEntities = connectionDef?.fromEntities.map((e) => e.kind) || [];

	const [selectedKind, setSelectedKind] = useState<string>(availableEntities[0]);
	const [connectionType, setConnectionType] = useState<ConnectionType>('existing');
	const [searchValue, setSearchValue] = useState('');
	const [selectedEntityId, setSelectedEntityId] = useState<number | null>(null);

	const query = useDebounce(searchValue, 250);
	const entityLookupQuery = useEntityLookupQuery(availableEntities, query);
	const suggestedEntities = entityLookupQuery.data?.slice(0, 10) ?? [];

	const connectExistingEntityMutation = useConnectExistingEntityMutation();
	const connectExistingEntity = async () => {
		try {
			if (!selectedEntityId) {
				throw new Error('Entity not selected');
			}

			const result = await connectExistingEntityMutation.mutateAsync({
				parentEntityId: parentEntity.graphId,
				connectionKind,
				entityId: selectedEntityId,
			});

			onConnectionSuccess(result.connectedLinkId, selectedEntityId);
		} catch (err) {
			if (err instanceof Response) {
				try {
					const errorResponse: ConnectionErrorResponse = await err.json();
					if (errorResponse.message === 'failed on db rules check') {
						TFNotifier.error('Failed to add connection');
						return;
					}
					for (const [key, value] of Object.entries(errorResponse)) {
						if (value.length) {
							TFNotifier.error(connectionErrorsMap[key]);
							return;
						}
					}
				} catch (e) {
					console.log('Failed to parse error', e);
				}
			} else {
				const message = err instanceof Error ? err.message : 'Unknown error';
				TFNotifier.error(message);
			}
		}
	};

	const onConnectionSuccess = (
		connectedLinkId: BackendTypes.GraphId,
		connectedEntityId: BackendTypes.GraphId
	) => {
		onSuccess();
		TFNotifier.success('Connection created successfully');

		if (skipRedirect) {
			return;
		}

		if (!review || review.connectionKind === connectionKind) {
			// New review created, so use its id in link
			navigateToEntity(connectedLinkId, connectionKind, connectedEntityId);
		} else {
			navigateToEntity(review.graphLinkId, review.connectionKind, connectedEntityId);
		}
	};

	const scripts = useProcessingScripts(availableEntities.map((item) => getSelfSegment(item)));
	const connectNewEntityMutation = useConnectNewEntityMutation();
	const connectNewEntity: SubmitHandler = async ({ values }) => {
		try {
			const selfSegment = getSelfSegment(selectedKind);
			const selfSegmentData = values[selfSegment];

			const result = await connectNewEntityMutation.mutateAsync({
				parentEntityId: parentEntity.graphId,
				connectionKind,
				entityKind: selectedKind,
				selfSegmentData,
			});

			onConnectionSuccess(result.connectedLinkId, result.connectedEntityId);
		} catch (err) {
			const message = err instanceof Error ? err.message : 'Unknown error';
			TFNotifier.error(message);
		}
	};

	if (connectionType === 'existing' && connectionKind !== 'NOMINATED_ACCOUNT') {
		return (
			<>
				<Stack gap="0" m=".5rem .75rem .75rem">
					<TextInput
						label="Name"
						placeholder="Search for existing or create new one"
						value={searchValue}
						onChange={(e) => {
							setSearchValue(e.target.value);
							setSelectedEntityId(null);
						}}
						rightSection={entityLookupQuery.isFetching && <Loader variant="oval" size="xs" />}
					/>
					{!suggestedEntities.length && query && (
						<TFText pb="4px" className={classes.subtitle}>
							No results found. Check the spelling or create new
						</TFText>
					)}

					{Boolean(suggestedEntities.length) && (
						<ScrollArea mt="12px" h={suggestedEntities.length > 10 ? '500px' : 'auto'}>
							<Stack gap="8px">
								{suggestedEntities.map(({ graphNodeId, name, entityKind }) => {
									const isSelected = graphNodeId === selectedEntityId;
									return (
										<TFListCard
											onClick={() => {
												if (isSelected) setSelectedEntityId(null);
												else setSelectedEntityId(graphNodeId);
											}}
											isActive={selectedEntityId === graphNodeId}
											key={name}
											type="radio"
										>
											<Flex align="center" ml="12px" w="100%" justify="space-between">
												<Box mr="12px" maw="280px">
													<TFText className={classes.title}>{name}</TFText>
												</Box>
												<EntityBadge kind={entityKind} />
											</Flex>
										</TFListCard>
									);
								})}
							</Stack>
						</ScrollArea>
					)}
					{Boolean(query) && (
						<Button
							size="lg"
							mt="8px"
							h="51px"
							style={{ borderRadius: '4px' }}
							leftSection={<Icon.IconPlus size="20px" color={'#155EEF'} />}
							variant="default"
							onClick={() => setConnectionType('new')}
						>
							<TFText className={classes.title}>Create new</TFText>
						</Button>
					)}
				</Stack>
				<ModalFooter
					submitText="Add"
					isLoading={connectExistingEntityMutation.isPending}
					isDisabled={selectedEntityId === null}
					onSubmit={connectExistingEntity}
				/>
			</>
		);
	}

	return (
		<>
			{connectionKind !== 'NOMINATED_ACCOUNT' && (
				<UnstyledButton
					sx={({ colors }) => ({
						color: colors.brand[6],
						fill: colors.brand[6],
						fontWeight: 600,
						fontSize: 14,
						'&:hover': {
							color: colors.brand[8],
							fill: colors.brand[8],
						},
					})}
					m="16px 0 12px 8px"
					onClick={() => setConnectionType('existing')}
				>
					<Flex align="center">
						<Icon.IconChevronLeft size={26} />
						<TFText>Back to search results</TFText>
					</Flex>
				</UnstyledButton>
			)}
			{availableEntities.length > 1 && (
				<Select
					label="Entity kind"
					value={selectedKind}
					onChange={(value) => {
						if (value) setSelectedKind(value);
					}}
					placeholder="Select a kind of entity"
					data={availableEntities.map((kind) => ({ value: kind, label: S.prettify(kind) }))}
					m=".25rem .75rem 0"
				/>
			)}
			<AppFormBuilder
				segments={[
					{
						segmentKind: getSelfSegment(selectedKind),
						graphId: 0,
						accessMode: 'MODIFY',
					},
				]}
				shouldValidate
				processingScripts={scripts}
				styles={{ content: { padding: '.5rem .75rem .25rem' } }}
				submitButtonRenderer={() => (
					<ModalFooter
						isLoading={connectNewEntityMutation.isPending}
						isDisabled={connectNewEntityMutation.isPending}
						submitText="Add"
					/>
				)}
				onSubmit={connectNewEntity}
			/>
		</>
	);
};
