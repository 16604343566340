import { appStore } from '@/core/stores';

/**
 * Get the self segment for an entity
 * @param entityKind
 */
export const getSelfSegment = (entityKind: string): string => {
	const selfSegments = Object.entries(appStore.defs.selfSegments.peek());
	const match = selfSegments.find(([s]) => s === entityKind)?.[1];

	if (!match) {
		throw new Error(`No self segment found for entity kind "${entityKind}"`);
	}

	return match as string;
};
