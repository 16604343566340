import React from 'react';

import { Outlet, useParams, useSearchParams } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import { TFPageHeader } from '@tf/shared';
import { Box, EntityBadge, Tabs, TFLoadingOverlay } from '@tf/ui';
import type { FormSegment } from '@tf/utils';

import { useEntity } from '@/core/api/entities';
import { useSegmentsQuery } from '@/core/api/segments';
import { EntityRelationsTable } from '@/components/entities';
import { SideMenuLayout } from '@/components/shared';

import { EntityWidget } from './EntityWidget';

const TABS = {
	entity: 'Entity',
	relations: 'Relations',
};

export const EntityLayout = () => {
	const params = useParams<{ entityId: string }>();
	const entityId = Number(params.entityId);

	const [searchParams, setSearchParams] = useSearchParams();
	const activeTab = searchParams.get('tab') || 'entity';
	const { data: entity, isPending } = useEntity(entityId);

	const formSegments: FormSegment[] =
		entity?.listSegments.map((s: BackendTypes.SegmentInfo) => ({
			...s.SegmentIdentity,
			accessMode: 'VIEW',
		})) ?? [];

	const { data: segmentsReadData } = useSegmentsQuery(formSegments);

	if (isPending || !entity || !segmentsReadData) {
		return <TFLoadingOverlay />;
	}

	return (
		<>
			<Box sx={({ breakpoints }) => ({ maxWidth: breakpoints.xl, margin: '0 auto' })}>
				<TFPageHeader
					tenant={entity.name}
					title={entity.name}
					breadcrumbs={[
						{ href: '/entities', title: 'Entities' },
						{ href: `/entities/${entity.name}`, title: entity.name },
					]}
					mb={0}
					badge={
						<Box ml="8px">
							<EntityBadge kind={entity.entityKind.replace('_ENTITY', '')} />
						</Box>
					}
				/>
				<Tabs
					variant="normal"
					value={activeTab}
					styles={({ colors }) => ({
						tab: {
							padding: '.65rem 1rem',
							borderRadius: 0,
							"&[data-active='true']": {
								fontWeight: 500,
								color: colors.brand[6],
								backgroundColor: colors.brand[0],
								borderBottom: `1.5px solid ${colors.brand[6]}`,
							},
						},
						list: { borderBottom: `1px solid ${colors.gray[2]}` },
					})}
					onChange={(value) => {
						if (!value) return;
						setSearchParams({ tab: value });
					}}
				>
					<Tabs.List>
						{Object.entries(TABS).map(([value, label]) => {
							return (
								<Tabs.Tab key={value} value={value}>
									<Box>{label}</Box>
								</Tabs.Tab>
							);
						})}
					</Tabs.List>

					<Tabs.Panel value="entity">
						<SideMenuLayout menu={<EntityWidget segmentsReadData={segmentsReadData} entity={entity} />}>
							<Outlet context={{ entity, segmentsReadData }} />
						</SideMenuLayout>
					</Tabs.Panel>

					<Tabs.Panel value="relations">
						<Box mt=".75rem">
							<EntityRelationsTable />
						</Box>
					</Tabs.Panel>
				</Tabs>
			</Box>
		</>
	);
};
