import React, { useEffect, useState } from 'react';

import { DateInput as DatePickerInput, Icon } from '@tf/ui';
import { D, fmt } from '@tf/utils';

export interface DateFieldProps {
	value?: string;
	label?: string;
	onChange: (value: string) => void;
	isReadOnly?: boolean;
}

export const DateField: React.FC<DateFieldProps> = ({ value, label, onChange, isReadOnly }) => {
	const [date, setDate] = useState('');

	useEffect(() => {
		if (value) {
			setDate(value);
		}
	}, [value]);

	return (
		<DatePickerInput
			value={date ? D.jsDateFromString(date) : undefined}
			variant="filled"
			label={label}
			valueFormat="DD.MM.YYYY"
			locale="en"
			readOnly={isReadOnly}
			defaultLevel="year"
			placeholder={isReadOnly ? 'Date unspecified' : 'Select date...'}
			onChange={(v) => {
				const nextValue = v ? fmt.toDate(v, { preset: 'date_struct' }) : undefined;
				if (!nextValue) return;
				setDate(nextValue);
				onChange(nextValue);
			}}
			popoverProps={{ position: 'top-start', withinPortal: true }}
			leftSection={!isReadOnly && <Icon.IconCalendar size={16} />}
		/>
	);
};
