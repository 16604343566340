import React, { useEffect, useState } from 'react';

import type { BackendTypes } from '@tf/api';
import { Box, Button, TFLoadingOverlay } from '@tf/ui';
import type { SubmitHandler } from '@tf/utils';

import { usePriority, useProcessingScripts } from '@/core/hooks';
import { useAccountSettingsSegment } from '@/core/hooks/use-account-settings-segments';
import { UserCapabilities } from '@/core/types';
import { useCurrentUserCapabilityCheck } from '@/core/utils';
import { AppFormBuilder, PrimitiveFormBuilder } from '@/components/shared';

import { formStructure } from './formStructure';

interface FieldValues {
	priority?: BackendTypes.OverviewImportance | null;
	name?: string;
	accountId?: string;
}

export const AccountSettings = () => {
	const [fields, setFields] = useState(formStructure);
	const [fieldValues, setFieldValues] = useState<FieldValues>({});

	const canPrepareAccountsData = useCurrentUserCapabilityCheck(
		UserCapabilities.CAN_PREPARE_ACCOUNTS_DATA
	);

	const { values, segmentDefs, updateMutation } = useAccountSettingsSegment();
	const priorityObject = usePriority();
	const scripts = useProcessingScripts(segmentDefs.map((s) => s.segmentKind));

	useEffect(() => {
		setFields((prevFields) => {
			return prevFields.map((f) => {
				return { ...f, disabled: !canPrepareAccountsData };
			});
		});
	}, [canPrepareAccountsData]);

	useEffect(() => {
		if (!priorityObject) return;
		setFieldValues({
			name: values.name,
			accountId: values.accountId,
			priority: priorityObject.priority,
		});
	}, [values.name, values.accountId, priorityObject?.priority]);

	const handleSubmit: SubmitHandler = async ({ values }) => {
		try {
			await updateMutation.mutateAsync({
				segments: values,
				accountId: fieldValues?.accountId ?? '',
				name: fieldValues?.name ?? '',
			});

			if (fieldValues?.priority) {
				if (!priorityObject) return;
				await priorityObject.updatePriority.mutateAsync({
					priority: fieldValues.priority,
				});
			}
		} catch (e) {
			console.error(e);
		}
	};

	if (!values.segments || !priorityObject) {
		return <TFLoadingOverlay h={300} size={24} />;
	}

	return (
		<Box>
			<Box p="0.75rem 1rem 0.3rem 1rem">
				<PrimitiveFormBuilder
					fields={fields}
					values={fieldValues}
					onChange={(newValues) => {
						setFieldValues((prevValues) => {
							return {
								...prevValues,
								...newValues,
							};
						});
					}}
				/>
			</Box>
			<AppFormBuilder
				validateInitialValues
				segments={segmentDefs}
				processingScripts={scripts}
				onSubmit={handleSubmit}
				initialValues={values.segments}
				styles={{ segment: { padding: '12px 1rem 0' } }}
				submitButtonRenderer={(isLoading) => {
					return (
						<Box p="0.75rem 1rem 1rem 1rem">
							<Box
								sx={({ colors }) => ({
									display: 'flex',
									justifyContent: 'end',
									padding: '.75rem 1rem',
									marginTop: '.5rem',
									borderTopStyle: 'solid',
									borderTopWidth: '1px',
									borderTopColor: colors.gray[2],
								})}
							>
								<Button type="submit" size="md" loading={isLoading}>
									Save changes
								</Button>
							</Box>
						</Box>
					);
				}}
			/>
		</Box>
	);
};
