import { useSelector } from '@datagrid/state';
import { useParams } from 'react-router-dom';

import { TFPageContent, TFPageHeader } from '@tf/shared';
import { TFLoadingOverlay } from '@tf/ui';
import { T } from '@tf/utils';

import { useUsers } from '@/core/api/users';
import { useCurrentUser } from '@/core/hooks';
import { appStore } from '@/core/stores';
import { UserCapabilities } from '@/core/types';
import { getDisplayName, useCurrentUserCapabilityCheck } from '@/core/utils';
import { UserCard, UserDeleteButton } from '@/components/settings';

export const UserCardRoute = () => {
	const { userId } = useParams();
	const { data: users } = useUsers();
	const tenant = useSelector(() => appStore.tenant?.get());
	const currentUser = useCurrentUser();
	const canManageUsers = useCurrentUserCapabilityCheck(UserCapabilities.CAN_MANAGE_USERS);

	// TODO: handle state from query
	if (!users || users.length === 0) {
		return <TFLoadingOverlay />;
	}

	const selectedUser = users.find((u) => u.userInfo.userId === userId);
	T.assertNotNullish(selectedUser, 'User not found');

	const title = getDisplayName(selectedUser.userInfo);
	const canDeleteUser = currentUser.userId !== selectedUser.userInfo.userId;
	T.assertNotNullish(tenant, 'no tenant in appStore');

	return (
		<>
			<TFPageHeader
				tenant={tenant}
				title={title}
				breadcrumbs={[
					...(canManageUsers ? [{ href: '/settings/users', title: 'Workspace settings' }] : []),
					{ href: '/settings/users', title: 'Users' },
					{ href: `/settings/users/${selectedUser.userInfo.userId}`, title },
				]}
				toolbar={canDeleteUser ? <UserDeleteButton userId={selectedUser.userInfo.userId} /> : undefined}
			/>
			<TFPageContent>
				<UserCard user={selectedUser.userInfo} />
			</TFPageContent>
		</>
	);
};
