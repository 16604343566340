import React, { useEffect, useState } from 'react';

import type { BackendTypes } from '@tf/api';

import { CommentsList } from '@/components/shared';

interface Props {
	sendComment: (comment: string) => void;
	comments: BackendTypes.MonitoringComment[] | null;
}

export const MonitoringCardCommentsList: React.FC<Props> = ({ sendComment, comments }) => {
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleSubmit = async (newComment: string) => {
		setIsSubmitting(true);
		await sendComment(newComment);
	};

	useEffect(() => {
		setIsSubmitting(false);
	}, [comments]);

	return (
		<CommentsList
			// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
			comments={comments?.map((item: BackendTypes.MonitoringComment) => ({
				...item,
				data: { text: item.text },
			}))}
			sendComment={handleSubmit}
			isSubmitting={isSubmitting}
		/>
	);
};
