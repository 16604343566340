import { useNavigate } from 'react-router-dom';

import { S } from '@tf/utils';

import { useSelectedAccount } from '@/core/hooks';

export const useNavigateToEntity = () => {
	const navigate = useNavigate();
	const account = useSelectedAccount();

	const getEntityPath = (
		connectionGraphId: number,
		connectionKind: string,
		entityGraphNodeId: number
	) => {
		const mainEntityId = account.reviews.find((r) => r.connectionKind === 'APPLICANT')?.graphNodeId;

		const basePath = `/accounts/${account.meta.graphNodeId}/${S.slugify(connectionKind)}`;

		return entityGraphNodeId === mainEntityId
			? basePath
			: `${basePath}/${connectionGraphId}/entity/${entityGraphNodeId}`;
	};

	const navigateToEntity = (
		connectionGraphId: number,
		connectionKind: string,
		entityGraphNodeId: number
	) => {
		window.scrollTo(0, 0);
		return navigate(getEntityPath(connectionGraphId, connectionKind, entityGraphNodeId));
	};

	return { getEntityPath, navigateToEntity };
};
