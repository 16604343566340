import type { BackendTypes } from '@tf/api';
import { BackendClient } from '@tf/api';

import type { ExtendedReviewContainer } from '@/core/types';
import type { AccountWithReviews } from '@/core/types/account';

export const getAccountQuery = async (accountId: number): Promise<AccountWithReviews> => {
	const api = BackendClient.getInstance();

	// * Get account meta
	const meta = await api
		.entityGet(accountId)
		.then((res) => res.json() as Promise<AccountWithReviews['meta']>);

	// * Get reviews from account
	// TODO: This is a temporary solution, we need to implement batch review fetching
	const connections = meta.listConnections
		.filter((connection) => connection.isReview)
		.map((connection) =>
			connection.listFromEntities.map((entity) => ({
				graphLinkId: entity.graphLinkId,
				graphNodeId: entity.graphNodeId,
			}))
		)
		.flat();

	const reviews: ExtendedReviewContainer[] = await Promise.all(
		connections.map(async (connection) => {
			const res = await api.reviewGet(connection.graphLinkId);
			const review: BackendTypes.ReviewGetData = await res.json();
			return { ...review, ...connection };
		})
	);

	return { meta, reviews };
};
