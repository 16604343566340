import type { BackendTypes } from '@tf/api';

import type { ExtendedReviewContainer } from '@/core/types';

export function isValidEmail(input: string) {
	const atIndex = input.indexOf('@');
	if (atIndex <= 0 || atIndex === input.length - 1) {
		return false;
	}

	const dotIndex = input.indexOf('.', atIndex + 1);
	return dotIndex > atIndex + 1 && dotIndex < input.length - 1;
}


export const extractUniqueIndividualEntities = (
	reviews: ExtendedReviewContainer[]
): BackendTypes.Entity[] => {
	const map: Record<number, boolean> = {};
	const individualEntities: BackendTypes.Entity[] = [];

	reviews.forEach((review) => {
		review.listEntities.forEach((entity) => {
			if (entity.entityKind === 'INDIVIDUAL_ENTITY' && !map[entity.graphNodeId]) {
				map[entity.graphNodeId] = true;
				individualEntities.push(entity);
			}
		});
	});

	return individualEntities;
};
