import React from 'react';

import type { Task } from '@tf/shared';
import { Icon, ThemeIcon } from '@tf/ui';

export const TaskIcon: React.FC<{ type: Task['type'] }> = ({ type }) => {
	switch (type) {
		case 'PENDING_FILL':
		case 'FINALIZE_REVIEW':
			return (
				<ThemeIcon variant="light" color="grey" bg="#F9FAFB" size={24} my=".25rem" radius="xl">
					<Icon.IconCircleDashed size="1rem" />
				</ThemeIcon>
			);
		case 'PENDING_REVIEW':
			return (
				<ThemeIcon variant="light" color="blue" size={24} my=".25rem" radius="xl">
					<Icon.IconClock stroke={1.8} size="1rem" />
				</ThemeIcon>
			);
		case 'REJECTED':
			return (
				<ThemeIcon variant="light" color="red" size={24} my=".25rem" radius="xl">
					<Icon.IconCircleX stroke={1.8} size="1rem" />
				</ThemeIcon>
			);
		case 'VALIDATION_ERRORS':
			return (
				<ThemeIcon variant="light" color="red" size={24} my=".25rem" radius="xl">
					<Icon.IconAlertCircle stroke={1.8} size="1rem" />
				</ThemeIcon>
			);
		case 'CORRECT_SHAREHOLDERS_PERCENTAGE_SUM':
		case 'CORRECT_PARTNERSHIP_PERCENTAGE_SUM':
			return (
				<ThemeIcon variant="light" color="red" size={24} my=".25rem" radius="xl">
					<Icon.IconPercentage stroke={1.8} size="1rem" />
				</ThemeIcon>
			);
		case 'REQUIRED_CONNECTION':
			return (
				<ThemeIcon variant="light" color="orange" size={24} my=".25rem" radius="xl">
					<Icon.IconLink stroke={1.8} size="1rem" />
				</ThemeIcon>
			);
		default:
			return (
				<ThemeIcon variant="light" color="gray" bg="#F9FAFB" size={24} my=".25rem" radius="xl">
					<Icon.IconCircleDashed size="1rem" />
				</ThemeIcon>
			);
	}
};
