import React, { useEffect, useState } from 'react';

import type { BackendTypes } from '@tf/api';
import { TFNotifier } from '@tf/ui';

import {
	useMonitoringMatchesCommentsQuery,
	useUpdateMonitoringCommentMutation,
} from '@/core/api/monitoring';
import { MonitoringCardCommentsList } from '@/components/monitoring';

interface Props {
	entityId: number;
	matchId: string;
}

export const MonitoringCardComments: React.FC<Props> = ({ entityId, matchId }: Props) => {
	const { data: storedComments } = useMonitoringMatchesCommentsQuery(entityId);
	const [comments, setComments] = useState<BackendTypes.MonitoringComment[] | null>(null);

	const updateMonitoringCommentMutation = useUpdateMonitoringCommentMutation({ entityId, matchId });

	const sendComment = async (comment: string) => {
		await updateMonitoringCommentMutation.mutateAsync(comment, {
			onError: (error) => {
				TFNotifier.error('Could not save comment. Please try again later.');
				console.error(error);
			},
		});
	};

	useEffect(() => {
		if (!storedComments?.length) return;
		setComments(storedComments.filter((item: any) => item.matchId === matchId) || null);
	}, [matchId, storedComments]);

	return <MonitoringCardCommentsList sendComment={sendComment} comments={comments} />;
};
