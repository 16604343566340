import { useLocation, useParams } from 'react-router-dom';

type PathParams = {
	accountId: string;
	connectionKind: string;
	connectionId: string;
	formKind: string;
	entityId: string;
	id: string;
};

type AccountParams = {
	accountId: number;
	connectionKind: string;
	connectionId: number;
	entityId: number;
	formKind: string;
	requestId: string;
	searchParams: Record<string, string>;
};

export const useAccountParams = (): AccountParams => {
	const params = useParams<PathParams>();
	const location = useLocation();

	if (!params.accountId) {
		throw new Error('Account ID not found');
	}

	const searchParams = location.search
		.replace('?', '')
		.split('&')
		.reduce((acc, entry) => {
			const [key, value] = entry.split('=');
			return { ...acc, [key]: value };
		}, {});

	return {
		accountId: parseInt(params.accountId, 10),
		connectionKind: params.connectionKind
			? params.connectionKind.replaceAll('-', '_').toUpperCase()
			: '',
		connectionId: parseInt(params.connectionId || '0', 10),
		entityId: parseInt(params.entityId || '0', 10),
		formKind: params.formKind ?? '',
		requestId: params.id ?? '',
		searchParams,
	};
};
