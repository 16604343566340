import { useMutation } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';

import { useOverviewAccount } from '@/core/api/overview';
import { useSelectedAccount } from '@/core/hooks/use-selected-account';
import { setAccountPriorityMutation } from '@/core/mutations';

export const usePriority = () => {
	const { meta } = useSelectedAccount();

	const { data } = useOverviewAccount(meta.graphNodeId);

	const updatePriority = useMutation({
		mutationFn: ({
			priority,
			accountId,
		}: {
			priority: BackendTypes.OverviewImportance;
			accountId?: number;
		}) => {
			return setAccountPriorityMutation({
				accountId: accountId || meta.graphNodeId,
				priority,
			});
		},
	});

	return {
		priority: data?.account.importance,
		updatePriority,
	};
};
