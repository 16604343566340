import type { BackendTypes } from '@tf/api';

import { useCurrentUser } from '@/core/hooks';

/**
 * Check if the current user has a capability
 * @param capability
 */
export const useCurrentUserCapabilityCheck = (capability: BackendTypes.RoleCapabilityValue) => {
	const user = useCurrentUser();
	return user.listRoleCapabilities.includes(capability);
};
