import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { BackendClient } from '@tf/api';
import { TFNotifier } from '@tf/ui';

interface Payload {
	accountId: number;
	connectionId: number;
	graphId: number;
	formKind: string;
	segmentIdentities: BackendTypes.SegmentIdentity[];
	status: BackendTypes.ReviewStatus;
	comment?: string;
}

export const useReviewDecisionMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({
			connectionId,
			graphId,
			formKind,
			segmentIdentities,
			status,
			comment,
		}: Payload): Promise<BackendTypes.ReviewSegmentDecisionWriteData> => {
			const api = BackendClient.getInstance();

			const res = await api.reviewSegmentDecisionWrite({
				listSegmentIdentities: segmentIdentities,
				status,
			});

			if (comment) {
				await api.reviewFormCommentWrite({
					reviewConnectionId: connectionId,
					data: {
						formId: formKind,
						graphId,
						text: comment,
					},
				});
			}

			return res;
		},
		onSuccess: async (res, { accountId }) => {
			await Promise.all([
				queryClient.refetchQueries({ queryKey: ['accounts', accountId] }),
				queryClient.refetchQueries({ queryKey: ['overviewAccounts'] }),
			]);
		},
		onError: () => {
			TFNotifier.error('Something went wrong. Please try again later');
		},
	});
};
