import React from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { TFNotifier } from '@tf/ui';

import { setAccountAssigneesMutation } from '@/core/mutations';

import type { ManagersFilterFn } from './AssignedManagersSelect';
import { AssignedManagersSelect } from './AssignedManagersSelect';

interface Props {
	accountId: number;
	selectedIds: string[];
	managers: BackendTypes.UserInfo[];
	managersFilter?: ManagersFilterFn;
}

export const AssignedManagersWidget: React.FC<Props> = (props) => {
	const queryClient = useQueryClient();
	const { mutate } = useMutation({
		mutationFn: (ids: string[]) => {
			return setAccountAssigneesMutation({
				accountId: props.accountId,
				ids,
			});
		},
		onSuccess: (isSuccess) => {
			if (isSuccess) {
				queryClient.refetchQueries({ queryKey: ['overviewAccount', props.accountId] });
				queryClient.refetchQueries({ queryKey: ['accountEventsLog', props.accountId] });
				queryClient.refetchQueries({ queryKey: ['overviewAccounts'], type: 'active' });
			}
		},
	});

	const onChange = (selectedManagers: string[]) => {
		mutate(selectedManagers, {
			onSuccess: (isSuccess) => {
				if (isSuccess) {
					TFNotifier.info('Assigned managers for account updated', { id: 'assign-manager' });
				}
			},
		});
	};

	return (
		<AssignedManagersSelect
			selectedIds={props.selectedIds}
			managers={props.managers}
			managersFilter={props.managersFilter}
			onChange={onChange}
		/>
	);
};
