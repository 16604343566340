import React from 'react';

import { Button, TFModals } from '@tf/ui';

import { useOrderAccountsExportMutation } from '@/core/api/dataExport';
import { ConfirmationModal } from '@/components/shared';

export const OrderExportButton: React.FC = () => {
	const orderAccountsExportMutation = useOrderAccountsExportMutation();

	const orderExport = () => {
		TFModals.open({
			children: (
				<ConfirmationModal
					title="Data export"
					message="Please note data processing can take several minutes. Once the file is ready, you can download it by clicking the Download button in the All exports table."
					confirmText="Generate file"
					onConfirm={orderAccountsExportMutation.mutate}
				/>
			),
		});
	};

	return <Button onClick={orderExport}>Generate file</Button>;
};
