import React from 'react';

import type { BackendTypes } from '@tf/api';
import { createStyles } from '@tf/ui';

import { useSegmentsQuery } from '@/core/api/segments';
import { AppFormBuilder } from '@/components/shared';

const useStyles = createStyles({
	container: {
		marginTop: '1rem',
		span: {
			fontSize: '14.4px',
			fontWeight: 600,
		},
	},
});

export const LivenessCheckResults = ({ graphId }: { graphId: number }) => {
	const { classes } = useStyles();

	const segments = graphId
		? [
				{
					accessMode: 'VIEW',
					graphId: graphId,
					segmentKind: 'COMMON__BACKGROUND_CHECK',
				} as const,
		  ]
		: [];

	const { data, isPending } = useSegmentsQuery(segments);

	if (isPending) {
		return null;
	}

	const initialValues = data?.reduce((acc, { container }) => {
		const { info, segment } = container;
		return {
			...acc,
			[info.SegmentIdentity.segmentKind]: segment,
		};
	}, {} as Record<BackendTypes.SegmentKind, BackendTypes.Segment>);

	return (
		<div className={classes.container}>
			<AppFormBuilder
				fieldFilters={['liveness_check']}
				initialValues={initialValues}
				segments={segments}
				submitButtonRenderer={() => <div />}
				onSubmit={Promise.resolve}
			/>
		</div>
	);
};
