import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAccountParams, useSelectedAccount } from '@/core/hooks';

/**
 * This component is used to resolve the path to the account page.
 * TODO: This is a temporary solution. We need to find a better way to handle this.
 */
export const AccountPathResolver = () => {
	const navigate = useNavigate();
	const { accountId, entityId } = useAccountParams();
	const account = useSelectedAccount();
	const applicant = account.reviews.find((review) => review.connectionKind === 'APPLICANT');

	useEffect(() => {
		if (!applicant) return;
		if (applicant.graphNodeId === entityId) {
			navigate(`/accounts/${accountId}/applicant`);
		}
	}, [accountId, entityId]);

	return null;
};
