import { queryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { BackendClient } from '@tf/api';

import { updateMonitoringSearchCommentMutation } from '@/core/mutations';
import { monitoringSearchDetailsQuery, monitoringSearchesQuery } from '@/core/queries';

const monitoringQueryOptions = (params: BackendTypes.MonitoringSearchesParams) => {
	return queryOptions({
		queryKey: ['monitoring', params],
		queryFn: () => monitoringSearchesQuery(params),
		staleTime: 20 * 1000,
	});
};

export const useMonitoringQuery = (params: BackendTypes.MonitoringSearchesParams) => {
	return useQuery(monitoringQueryOptions(params));
};

const monitoringMatchesQueryOptions = (params: BackendTypes.MonitoringSearchDetailsParams) => {
	return queryOptions({
		queryKey: ['monitoring-matches', params],
		queryFn: () => monitoringSearchDetailsQuery(params),
		staleTime: 20 * 1000,
	});
};

export const useMonitoringMatchesQuery = (params: BackendTypes.MonitoringSearchDetailsParams) => {
	return useQuery(monitoringMatchesQueryOptions(params));
};

const monitoringMatchesCommentsQueryOptions = (graphNodeId: number) => {
	return queryOptions({
		queryKey: ['monitoring-matches-comments', graphNodeId],
		queryFn: () => monitoringSearchDetailsQuery({ graphNodeId, page: 1, limit: 10 }),
		staleTime: 20 * 1000,
		select: (data) => data?.comments || [],
	});
};

export const useMonitoringMatchesCommentsQuery = (graphNodeId: number) => {
	return useQuery(monitoringMatchesCommentsQueryOptions(graphNodeId));
};

export const useUpdateMonitoringCommentMutation = ({
	entityId,
	matchId,
}: BackendTypes.MonitoringSearchMatchCommentCreateData) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['update-comment-match', entityId, matchId],
		mutationFn: (comment: string) => {
			return updateMonitoringSearchCommentMutation({
				graphNodeId: entityId,
				matchId,
				comment,
			});
		},
		onSuccess: () => {
			return queryClient.invalidateQueries(monitoringMatchesCommentsQueryOptions(entityId));
		},
	});
};

export const useAcknowledgeMonitoringSearchMutation = (graphNodeId: BackendTypes.GraphId) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['acknowledge-monitoring-search', graphNodeId],
		mutationFn: () => BackendClient.getInstance().monitoringSearchAcknowledge(graphNodeId),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['monitoring'] });
			queryClient.invalidateQueries({ queryKey: ['searchDetails'] });
		},
	});
};
