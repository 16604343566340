import React from 'react';

import type { FC } from 'react';

import type { BackendTypes } from '@tf/api';
import { Box, createStyles } from '@tf/ui';
import { fmt } from '@tf/utils';

import { getDisplayName } from '@/core/utils';

const useStyles = createStyles(({ radius, colors }) => ({
	root: {},
	header: {
		display: 'flex',
		alignItems: 'center',
		padding: '.25rem 1rem',
		fontSize: '.75rem',
		backgroundColor: colors.gray[2],
		fontWeight: 500,
	},
	content: {
		padding: '.75rem 1rem',
	},
	author: {
		flex: 1,
	},
	userAvatar: {
		radius: radius.xl,
		marginRight: '0.5rem',
	},
	name: {
		marginRight: '0.5rem',
		color: colors.blue[8],
		fontWeight: 700,
	},
}));

interface CommentItemProps {
	author?: BackendTypes.UserInfo;
	createdAt: string;
	body: string;
}

export const CommentItem: FC<CommentItemProps> = ({ author, createdAt, body }) => {
	const { classes } = useStyles();

	return (
		<Box>
			<Box className={classes.header}>
				{author && <Box className={classes.name}>{getDisplayName(author)}</Box>}
				<Box>{fmt.toDate(createdAt, { preset: 'full_date_with_time' })}</Box>
			</Box>
			<Box className={classes.content}>
				<Box>{body}</Box>
			</Box>
		</Box>
	);
};
