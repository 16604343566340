import React from 'react';

import { observer, Switch } from '@datagrid/state';

import { TFLoadingOverlay } from '@tf/ui';

import { useAccountForm } from '@/core/hooks';
import { AppFormBuilder, ModalFooter } from '@/components/shared';

interface Props {
	onClose: () => void;
}

export const RiskProfileForm: React.FC<Props> = observer(({ onClose }) => {
	const { state, scripts, formSegments, handleSubmit } = useAccountForm(
		['COMMON__COMPLIANCE_FEEDBACK'],
		{ onSuccess: onClose }
	);

	return (
		<Switch value={state.values}>
			{{
				undefined: () => <TFLoadingOverlay h={300} size={24} />,
				default: () => (
					<AppFormBuilder
						styles={{ content: { padding: '.25rem .75rem' } }}
						segments={formSegments}
						processingScripts={scripts}
						shouldValidate
						initialValues={state.values?.get() ?? {}}
						onSubmit={handleSubmit}
						submitButtonRenderer={(isLoading) => (
							<ModalFooter submitText="Save changes" isDisabled={isLoading} onCancel={onClose} />
						)}
					/>
				),
			}}
		</Switch>
	);
});
