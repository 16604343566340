import React, { useEffect, useState } from 'react';

import { Select } from '@tf/ui';

export interface SelectFieldProps {
	value?: string;
	label?: string;
	onChange: (value: string) => void;
	isReadOnly?: boolean;
	isSearchable?: boolean;
	options?: {
		value: string;
		label: string;
	}[];
}

export const SelectField = ({
	value,
	label,
	onChange,
	isReadOnly,
	isSearchable,
	options = [],
}: SelectFieldProps) => {
	const [selectedValue, setSelectedValue] = useState<string | null>(null);

	useEffect(() => {
		if (value) {
			setSelectedValue(value);
		}
	}, [value]);

	return (
		<Select
			value={selectedValue}
			onChange={(value) => {
				setSelectedValue(value);
				onChange(value as string);
			}}
			label={label}
			placeholder={isReadOnly ? 'Selected nothing' : 'Select...'}
			data={options}
			readOnly={isReadOnly}
			data-readonly={isReadOnly}
			searchable={isSearchable || options?.length > 5}
		/>
	);
};
