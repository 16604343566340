import React, { useEffect } from 'react';

import { useSelector } from '@datagrid/state';
import { useNavigate } from 'react-router-dom';

import { useDownloadMonitoringSearchCertificateMutation } from '@tf/form-builder/lib';
import { TFPageContent, TFPageHeader } from '@tf/shared';
import { Box, Button, Flex, Icon } from '@tf/ui';
import { saveFile, T } from '@tf/utils';

import { appStore, instantCheckStore } from '@/core/stores';
import { InstantCheckTable } from '@/components/instant-check';

const InstantCheckHitsLayout = () => {
	const navigate = useNavigate();
	const { hits, searchId } = useSelector(() => instantCheckStore.get());
	const tenant = appStore.tenant?.get();
	T.assertNotNullish(tenant, 'no tenant in appStore');

	useEffect(() => {
		if (!hits) navigate('/instant-check');
	}, [hits]);

	const onOpenCard = (selectedId: number) => {
		instantCheckStore.set((s) => ({ ...s, selectedId }));
		navigate('/instant-check/card');
	};
	const onNewSearch = () => {
		instantCheckStore.set((s) => ({ ...s, hits: null }));
		navigate('/instant-check');
	};

	const downloadCertificateMutation = useDownloadMonitoringSearchCertificateMutation();
	const searchName = instantCheckStore.searchName.get();

	return (
		<>
			<TFPageHeader
				tenant={tenant}
				title="Instant check"
				toolbar={
					<Flex gap="8px">
						<Button
							variant="light"
							leftSection={<Icon.IconSearch size={14} stroke={2.5} />}
							onClick={onNewSearch}
						>
							New Search
						</Button>
						<Button
							onClick={async () => {
								if (!searchId) return;
								const res = await downloadCertificateMutation.mutateAsync(searchId);
								saveFile(res, `${searchName || 'instant check'}.pdf`);
							}}
							disabled={downloadCertificateMutation.isPending}
							leftSection={<Icon.IconFileDownload size={14} stroke={2.5} />}
						>
							Download search certificate
						</Button>
					</Flex>
				}
			/>
			<TFPageContent>
				<Box>
					<InstantCheckTable hits={hits as any[]} onRowClick={onOpenCard}></InstantCheckTable>
				</Box>
			</TFPageContent>
		</>
	);
};

export default InstantCheckHitsLayout;
