import { SignedIn } from '@clerk/clerk-react';
import { Outlet } from 'react-router-dom';

import { AppShell } from '@tf/ui';

import { Affix } from './Affix';
import { AppHeader } from './AppHeader';

export const AppLayout = () => {
	return (
		<SignedIn>
			<AppShell>
				<AppHeader />
				<AppShell.Main bg="#fcfdff" p="44px 1rem 1rem">
					<Outlet />
				</AppShell.Main>

				<Affix />
			</AppShell>
		</SignedIn>
	);
};
