import React, { useEffect, useState } from 'react';

import { Stack } from '@tf/ui';

import { useAccountInfoSegments } from '@/core/hooks';
import type { PrimitiveField } from '@/components/shared';
import { ConfirmationModal, PrimitiveFormBuilder } from '@/components/shared';

import type { ModalProps } from './types';
import { setDefaultValue } from './utils';

interface Props {
	modalData: ModalProps;
	onCancel: () => void;
	onConfirm: (fields: Record<string, any>) => void;
	initialValues: Record<string, any>;
}

export const AccountStatusModal: React.FC<Props> = ({
	modalData,
	onCancel,
	onConfirm,
	initialValues,
}) => {
	const { defaultValues } = useAccountInfoSegments();

	const [fields, setFields] = useState({});
	const [fieldValues, setFieldValues] = useState(initialValues);

	useEffect(() => {
		if (initialValues) {
			const result = { ...initialValues };
			modalData?.additionalFields?.forEach((field: PrimitiveField) => {
				if (!result[field.name]) {
					result[field.name] = setDefaultValue({
						fieldName: field.name,
						defaultFields: defaultValues,
					});
				}
			});

			setFields({ ...fields, ...result });
			setFieldValues({ ...fieldValues, ...result });
		}
	}, [initialValues]);

	return (
		<ConfirmationModal
			message={modalData?.text}
			confirmText={modalData?.confirmButtonLabel}
			onConfirm={() => onConfirm(fieldValues)}
			confirmButtonColor={modalData.confirmButtonColor}
			isSubmitDisabled={fieldValues.account_name !== modalData.accountNameConfirmation}
			onCancel={onCancel}
		>
			<Stack mt="0.75rem" gap="md">
				{modalData?.content}
				{modalData?.additionalFields ? (
					<PrimitiveFormBuilder
						fields={modalData?.additionalFields}
						values={fieldValues}
						onChange={(changedFields) => {
							setFieldValues({ ...fields, ...changedFields });
						}}
					/>
				) : null}
			</Stack>
		</ConfirmationModal>
	);
};
