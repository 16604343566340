import React from 'react';

import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { TFPageContent, TFPageHeader } from '@tf/shared';
import { Box, Button, createStyles, Icon, Tabs } from '@tf/ui';
import { T } from '@tf/utils';

import { appStore } from '@/core/stores';
import { UserCapabilities } from '@/core/types';
import { useCurrentUserCapabilityCheck } from '@/core/utils';

const useStyles = createStyles(({ colors }) => ({
	tabs: {
		marginTop: '-.75rem',
	},
	list: {
		borderBottomWidth: 1,
		borderColor: colors.gray[2],
	},
	tab: {
		display: 'flex',
		gap: '.35rem',
	},
}));

const TABS = [
	{ href: '/settings/users', title: 'Users' },
	{ href: '/settings/users/invitations', title: 'Invitations' },
] as const;

export const UserTablesLayout = () => {
	const { classes } = useStyles();

	const tenant = appStore.tenant?.get();
	T.assertNotNullish(tenant, 'no tenant in appStore');

	const navigate = useNavigate();
	const { pathname } = useLocation();
	const canManageUsers = useCurrentUserCapabilityCheck(UserCapabilities.CAN_MANAGE_USERS);

	return (
		<>
			<TFPageHeader
				tenant={tenant}
				title="User management"
				breadcrumbs={[
					...(canManageUsers ? [{ href: '/settings/users', title: 'Workspace settings' }] : []),
					TABS.find((t) => t.href === pathname) ?? TABS[0],
				]}
				toolbar={
					<Button
						component={Link}
						to="/settings/invitations/invite"
						size="sm"
						leftSection={<Icon.IconPlus size={18} stroke={2.25} />}
					>
						Invite users
					</Button>
				}
			/>
			<TFPageContent>
				<Tabs
					variant="normal"
					value={pathname}
					onChange={(value) => {
						if (value) {
							navigate(value);
						}
					}}
					className={classes.tabs}
					styles={({ colors }) => ({
						root: { flexGrow: 1, borderBottomWidth: 2 },
						tab: {
							padding: '.65rem 1rem',
							borderBottomWidth: 1.5,
							borderRadius: 0,
							"&[data-active='true']": {
								fontWeight: 500,
								color: colors.brand[6],
								backgroundColor: colors.brand[0],
								borderBottom: `1.5px solid ${colors.brand[6]}`,
							},
						},
						list: { borderBottom: `1px solid ${colors.gray[2]}` },
					})}
				>
					<Tabs.List>
						{TABS.map(({ href, title }) => (
							<Tabs.Tab key={href} value={href}>
								<Box className={classes.tab}>{title}</Box>
							</Tabs.Tab>
						))}
					</Tabs.List>
					<Box pt="0.75rem">
						<Outlet />
					</Box>
				</Tabs>
			</TFPageContent>
		</>
	);
};
