import { Outlet } from "react-router-dom";

const InstantCheckRoute = () => {

	return (
		<Outlet/>
	)
};

export default InstantCheckRoute;
