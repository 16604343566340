import { useAccount } from '@/core/api/account';
import { useAccountParams } from '@/core/hooks/use-account-params';

export const useSelectedAccountApplicantReview = () => {
	const { accountId } = useAccountParams();
	const { data } = useAccount(accountId);

	if (!data) {
		throw new Error('Account not found');
	}

	const applicantReview = data.reviews.find((r) => r.connectionKind === 'APPLICANT');
	if (!applicantReview) {
		throw new Error('Applicant review not found');
	}

	return applicantReview;
};
