import { forwardRef, useState } from 'react';

import { useSelector } from '@datagrid/state';
import type { ColorScheme, IMessage, INotificationCenterStyles } from '@novu/notification-center';
import {
	NotificationBell as NovuNotificationBell,
	NotificationCenter as NovuNotificationCenter,
	NovuProvider,
	useNotifications,
} from '@novu/notification-center';
import { useNavigate } from 'react-router-dom';

import { createStyles, Popover, useMantineTheme } from '@tf/ui';

import { useCurrentUser } from '@/core/hooks';
import { appStore, configStore } from '@/core/stores';
import { useAppInstanceId } from '@/core/useAppInstanceId';

const useNovuStyles = () => {
	const theme = useMantineTheme();

	const styles: INotificationCenterStyles = {
		bellButton: {
			dot: {
				'& > rect': {
					fill: theme.colors.brand[7],
				},
			},
		},
		unseenBadge: {
			root: {
				background: theme.colors.brand[7],
			},
		},
		loader: {
			root: {
				stroke: theme.colors.brand[7],
			},
		},
		layout: {
			root: {
				paddingBottom: 0,
				border: 0,
				borderRadius: 0,
				boxShadow: 'none',
			},
		},
		header: {
			title: {
				fontWeight: theme.headings.fontWeight,
				color: theme.colors.gray[8],
			},
		},
		switch: {
			input: {
				background: theme.colors.gray[3],
				'&:checked ~ label': {
					background: theme.colors.brand[7],
				},
				'&:not(checked) ~ label': {
					background: theme.colors.gray[3],
				},
				'&:disabled:not(:checked) ~ label': {
					background: theme.colors.gray[3],
				},
			},
		},
		notifications: {
			listItem: {
				unread: {
					'&::before': {
						background: theme.colors.brand[7],
					},
				},
			},
		},
	};

	return { styles, colorScheme: 'light' };
};

const usePopoverStyles = createStyles(() => ({
	dropdown: {
		padding: 0,
	},
}));

const NotificationBell = forwardRef<HTMLSpanElement, { onClick: () => void }>(({ onClick }, ref) => {
	const { unseenCount } = useNotifications();

	return (
		<span onClick={onClick} ref={ref}>
			<NovuNotificationBell unseenCount={unseenCount} />
		</span>
	);
});

export const NotificationCenter = () => {
	const [isOpened, setIsOpened] = useState(false);

	const onClose = () => setIsOpened(false);

	const onToggle = () => setIsOpened((v) => !v);

	const navigate = useNavigate();

	const handleNotificationClick = (notification: IMessage) => {
		const link = notification.cta.data.url;

		if (link) {
			navigate(link);
		}

		onClose();
	};

	const { styles, colorScheme } = useNovuStyles();
	const { classes } = usePopoverStyles();

	const config = useSelector(() => configStore.get());
	const appInstanceId = useAppInstanceId();
	const currentUserId = useCurrentUser().userId;

	return (
		<NovuProvider
			subscriberId={`${appInstanceId}:${currentUserId}`}
			applicationIdentifier={config.novu.application_identifier}
			styles={styles}
		>
			<Popover
				opened={isOpened}
				onClose={onClose}
				closeOnClickOutside
				position="top"
				withArrow={false}
				shadow="md"
			>
				<Popover.Target>
					<NotificationBell onClick={onToggle} />
				</Popover.Target>

				<Popover.Dropdown className={classes.dropdown}>
					<NovuNotificationCenter
						colorScheme={colorScheme as ColorScheme}
						footer={() => <div />}
						onNotificationClick={handleNotificationClick}
					/>
				</Popover.Dropdown>
			</Popover>
		</NovuProvider>
	);
};
