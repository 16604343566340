import { useSelector } from '@datagrid/state';

import type { BackendTypes } from '@tf/api';

import { appStore } from '@/core/stores';

export function useProcessingScripts(
	segmentsKinds: BackendTypes.SegmentKind[]
): Record<BackendTypes.SegmentKind, string[]> {
	const resultScripts: Record<BackendTypes.SegmentKind, string[]> = {};
	const storedScripts = useSelector(() => appStore.processingScripts.get());

	segmentsKinds.forEach((segmentKind: string) => {
		if (storedScripts[segmentKind]) {
			resultScripts[segmentKind] = storedScripts[segmentKind];
		}
	});

	return resultScripts;
}
