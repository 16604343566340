import React from 'react';

import { SignIn } from '@clerk/clerk-react';

import { Center, MobileNotice, useLocalStorage, useViewportSize } from '@tf/ui';

export const LoginRoute = () => {
	const viewport = useViewportSize();
	const [agreeWithNotice, setAgreeWithNotice] = useLocalStorage({
		defaultValue: false,
		key: 'trustform-mobile-notice',
	});

	if (viewport.width < 743 && !agreeWithNotice) {
		return <MobileNotice onContinue={() => setAgreeWithNotice(true)} />;
	}

	return (
		<Center mt="20vh">
			<SignIn routing="path" path="/sign-in" forceRedirectUrl={window.location.origin} />
		</Center>
	);
};
