import type { BackendTypes } from '@tf/api';
import type { FormSegment } from '@tf/utils';

import { AppFormBuilder } from '@/components/shared';

interface Props {
	data: BackendTypes.SegmentsReadData;
}

export const MonitoringCardForm: React.FC<Props> = ({ data }) => {
	const initialValues = data.reduce((acc, { container }) => {
		const { info, segment } = container;
		return {
			...acc,
			[info.SegmentIdentity.segmentKind]: segment,
		};
	}, {} as Record<BackendTypes.SegmentKind, BackendTypes.Segment>);

	const segments: FormSegment[] = data.map(({ container: { info } }) => {
		return { accessMode: 'VIEW', ...info.SegmentIdentity, label: '' } satisfies FormSegment;
	});

	return (
		<AppFormBuilder
			segments={segments}
			initialValues={initialValues}
			// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
			modifiers={[{ type: 'visible', value: 'removeRequired' }]}
			onSubmit={() => Promise.resolve()}
			segmentPreRenderer={() => null}
			submitButtonRenderer={() => null}
			styles={{
				content: { ':not:first-of-type".': { marginTop: '1rem' } },
				segment: () => ({
					padding: '.75rem 1rem 1rem',
				}),
			}}
		/>
	);
};
