import React from 'react';

import type { BackendTypes } from '@tf/api';
import { Box, Grid, TFCard } from '@tf/ui';
import type { FormSegment } from '@tf/utils';

import { useSegmentsQuery } from '@/core/api/segments';
import { useSelectedMonitoringEntity } from '@/core/hooks';
import { MonitoringCardForm, MonitoringCardMatchesTable } from '@/components/monitoring';
import { FormLoader } from '@/components/shared';

const SEGMENTS = [
	'INDIVIDUAL_ENTITY__DETAILS',
	'INDIVIDUAL_ENTITY__ADDRESSES',
	'CORPORATE_ENTITY__DETAILS',
	'COMMON__BACKGROUND_CHECK',
];

export const MonitoringCardMatches = () => {
	const entityData = useSelectedMonitoringEntity();
	const formSegments: FormSegment[] = entityData?.listSegments
		.filter((s: BackendTypes.SegmentInfo) => {
			const segmentKind = s.SegmentIdentity.segmentKind;
			return SEGMENTS.includes(segmentKind);
		})
		.map((s: BackendTypes.SegmentInfo) => ({ ...s.SegmentIdentity, accessMode: 'VIEW' }));
	const segmentsQuery = useSegmentsQuery(formSegments);

	return (
		<Box p="0.75rem 0">
			<Grid>
				<Grid.Col span={4}>
					<Box mt={12}>
						<TFCard title="Information">
							{segmentsQuery.status === 'success' ? (
								<MonitoringCardForm data={segmentsQuery.data} />
							) : (
								<FormLoader />
							)}
						</TFCard>
					</Box>
				</Grid.Col>
				<Grid.Col span={8}>
					<MonitoringCardMatchesTable />
				</Grid.Col>
			</Grid>
		</Box>
	);
};
