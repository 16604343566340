import React from 'react';

import * as Sentry from '@sentry/react';
import { Link, Outlet, useLocation } from 'react-router-dom';

import { TFPageContent } from '@tf/shared';
import { Anchor, Stack, TFText } from '@tf/ui';

import { useAccountParams } from '@/core/hooks';
import { AccountHeader, AccountPathResolver, AccountProvider } from '@/components/accounts';

const AccountCardError: React.FC<React.PropsWithChildren> = ({ children }) => {
	const location = useLocation();
	const { accountId } = useAccountParams();

	return (
		<Sentry.ErrorBoundary
			key={location.pathname}
			beforeCapture={(scope) => {
				scope.setTag('location', 'accountCard');
			}}
			fallback={
				<TFPageContent>
					<Stack pt="1rem" maw="60ch" gap="1rem">
						<TFText>
							An unexpected error occurred on this page. If this issue persists or you need immediate
							assistance, feel free to reach out to our support team.
						</TFText>
						<Anchor component={Link} to={`/accounts/${accountId}`}>
							Go to account page
						</Anchor>
					</Stack>
				</TFPageContent>
			}
		>
			{children}
		</Sentry.ErrorBoundary>
	);
};

export const AccountCardLayout = () => {
	return (
		<AccountProvider>
			<AccountHeader />
			<AccountPathResolver />
			<AccountCardError>
				<Outlet />
			</AccountCardError>
		</AccountProvider>
	);
};
