import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { DatesProvider } from '@tf/ui';

import { AuthProvider } from './AuthProvider';
import { ConfigProvider } from './ConfigProvider';
import { I18nProvider } from './I18nProvider';
import { StateProvider } from './StateProvider';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false,
		},
	},
});

export const AppProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<QueryClientProvider client={queryClient}>
			<ReactQueryDevtools />
			<ConfigProvider>
				<AuthProvider>
					<StateProvider>
						{/* TODO: update order after splitting StateProvider */}
						<I18nProvider>
							<DatesProvider>{children}</DatesProvider>
						</I18nProvider>
					</StateProvider>
				</AuthProvider>
			</ConfigProvider>
		</QueryClientProvider>
	);
};
