import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import { monitoringSearchDetailsQuery } from '@/core/queries';
import { monitoringStore } from '@/core/stores';

interface UseMonitoringHitsParams {
	graphId: number;
	page: number;
	limit: number;
}

export const useMonitoringHits = ({ graphId, page, limit }: UseMonitoringHitsParams) => {
	const query = useQuery({
		queryKey: ['searchDetails', graphId, page, limit],
		queryFn: () =>
			monitoringSearchDetailsQuery({
				graphNodeId: graphId,
				page: page,
				limit: limit,
			}),
		staleTime: Infinity,
	});

	useEffect(() => {
		if (query.data) {
			// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
			const hits = query.data.matches.map((item) => item.rawResponse.hit);

			monitoringStore.assign({
				hits: hits,
				searchInfo: query.data.searchInfo,
			});
		}
	}, [query.data]);

	return query;
};
