import React, { useRef } from 'react';

import { Button, FileButton, TFNotifier } from '@tf/ui';

import { useUploadCRATemplateMutation } from '@/core/api/craSettings';

export const UploadTemplateButton: React.FC<{ accountKind: string }> = ({ accountKind }) => {
	const resetFileRef = useRef<() => void>(null);

	const uploadCRATemplateMutation = useUploadCRATemplateMutation();

	return (
		<FileButton
			// Excel Files 2007+ (.xlsx)
			accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			resetRef={resetFileRef}
			onChange={(file) => {
				if (file !== null) {
					uploadCRATemplateMutation.mutate(
						{
							accountKind,
							data: { filename: file },
						},
						{
							onSuccess: () => {
								TFNotifier.success('Template uploaded');
							},
							onError: () => {
								TFNotifier.error('Template upload failed');
							},
							onSettled: () => {
								resetFileRef.current?.();
							},
						}
					);
				}
			}}
		>
			{(props) => (
				<Button size="xs" variant="light" loading={uploadCRATemplateMutation.isPending} {...props}>
					Upload
				</Button>
			)}
		</FileButton>
	);
};
