import React from 'react';

import type { ReactElement } from 'react';

import { Box, createStyles, Flex, lighten, TFText, UnstyledButton } from '@tf/ui';

const useStyles = createStyles(({ colors, radius }) => ({
	button: {
		minWidth: '100px',
		borderRadius: radius.sm,
		fontSize: '0.75rem',
		padding: '0.25rem 0.35rem',
		transition: 'background-color 0.3s ease',
	},

	buttonDisabled: {
		cursor: 'default !important',
	},

	badge: {
		color: colors.gray[6],
		width: '100%',
		'&::before': {
			content: '""',
			width: '0.5rem',
			height: '0.5rem',
			borderRadius: '50%',
			verticalAlign: 'middle',
		},
	},

	red: { background: colors.red[1] },
	redHover: { '&:hover': { background: lighten(colors.red[2], 0.3) } },
	redBadge: { '&::before': { background: colors.red[5] } },
	orange: { background: colors.orange[1] },
	orangeHover: { '&:hover': { background: colors.orange[2] } },
	orangeBadge: { '&::before': { background: colors.orange[5] } },
	yellow: { background: colors.yellow[1], '&:hover': { background: colors.yellow[2] } },
	yellowHover: { '&:hover': { background: colors.yellow[2] } },
	yellowBadge: { '&::before': { background: colors.yellow[5] } },
	green: { background: colors.green[1] },
	greenHover: { '&:hover': { background: colors.green[2] } },
	greenBadge: { '&::before': { background: colors.green[5] } },
	blue: { background: colors.blue[1] },
	blueHover: { '&:hover': { background: colors.blue[2] } },
	blueBadge: { '&::before': { background: colors.blue[5] } },
	indigo: { background: colors.indigo[1] },
	indigoHover: { '&:hover': { background: lighten(colors.indigo[2], 0.3) } },
	indigoBadge: { '&::before': { background: colors.indigo[5] } },
	dimmed: { background: colors.gray[2] },
	dimmedHover: { '&:hover': { background: lighten(colors.gray[3], 0.4) } },
	dimmedBadge: { '&::before': { background: colors.gray[4] } },
}));

export enum TFBadgeColors {
	RED = 'red',
	ORANGE = 'orange',
	YELLOW = 'yellow',
	GREEN = 'green',
	BLUE = 'blue',
	INDIGO = 'indigo',
	DIMMED = 'dimmed',
}

interface Props {
	text: ReactElement | string;
	color: TFBadgeColors;
	icon?: ReactElement | string;
	onClick?: () => void;
	isBackground?: boolean;
	withBackground?: boolean;
	disabled?: boolean;
}

export const TFBadge: React.FC<Props> = ({
	text,
	color,
	icon,
	onClick,
	disabled,
	isBackground = true,
	withBackground = true,
}) => {
	const { classes } = useStyles();

	const content = (
		<Flex justify="space-between" align="center">
			{color ? (
				// eslint-disable-next-line sonarjs/no-nested-template-literals
				<Flex className={`${classes.badge} ${classes[`${color}Badge`]}`} align="center">
					<TFText inherit size="12px" ml="0.4rem" fw={withBackground ? 600 : 300}>
						{text}
					</TFText>
				</Flex>
			) : (
				<TFText fw={600}>{text}</TFText>
			)}
			<Flex ml="1rem" justify="center" align="center">
				{icon ? icon : null}
			</Flex>
		</Flex>
	);

	if (!withBackground) {
		return content;
	}

	if (onClick) {
		return (
			<UnstyledButton
				disabled={disabled}
				onClick={onClick}
				className={`${classes.button} ${isBackground ? classes[color] : ''} ${
					disabled || !isBackground ? '' : classes[`${color}Hover`]
				} ${disabled ? classes.buttonDisabled : ''} `}
			>
				{content}
			</UnstyledButton>
		);
	}

	return (
		<Box
			className={`${classes.button} ${isBackground ? classes[color] : ''} ${
				disabled || !isBackground ? '' : (classes[`${color}Hover`] as string)
			} ${disabled ? classes.buttonDisabled : ''} `}
		>
			{content}
		</Box>
	);
};
