import React from 'react';

import { TFPageContent, TFPageHeader } from '@tf/shared';
import { Box, Center, createStyles, TFCard } from '@tf/ui';
import { T } from '@tf/utils';

import { appStore } from '@/core/stores';
import { InstantCheckSearchForm } from '@/components/instant-check';

const useStyles = createStyles(() => ({
	searchForm: {
		width: '600px',
		margin: '1rem',
	},
}));
const InstantCheckSearchLayout = () => {
	const { classes } = useStyles();
	const tenant = appStore.tenant?.get();
	T.assertNotNullish(tenant, 'no tenant in appStore');

	return (
		<>
			<TFPageHeader title="Instant check" tenant={tenant} />
			<TFPageContent>
				<Box sx={({ breakpoints }) => ({ margin: '.75rem auto', maxWidth: breakpoints.xl })}>
					<TFCard>
						<Center>
							<Box className={classes.searchForm}>
								<InstantCheckSearchForm />
							</Box>
						</Center>
					</TFCard>
				</Box>
			</TFPageContent>
		</>
	);
};

export default InstantCheckSearchLayout;
