import {
	ActionIcon,
	Affix as UIAffix,
	Icon,
	rem,
	Tooltip,
	Transition,
	useHotkeys,
	useWindowScroll,
} from '@tf/ui';

export const Affix = () => {
	const [scroll, scrollTo] = useWindowScroll();
	useHotkeys([['Ctrl+ArrowUp', () => scrollTo({ y: 0 })]]);

	return (
		<UIAffix position={{ bottom: rem(20), right: rem(20) }}>
			<Transition transition="slide-up" mounted={scroll.y > 0}>
				{(transitionStyles) => (
					<Tooltip label="Scroll to Top" position="left">
						<ActionIcon
							variant="gradient"
							gradient={{ from: 'brand', to: 'blue' }}
							style={transitionStyles}
							onClick={() => scrollTo({ y: 0 })}
						>
							<Icon.IconArrowUp size="1.2rem" />
						</ActionIcon>
					</Tooltip>
				)}
			</Transition>
		</UIAffix>
	);
};
