import type { BackendTypes } from '@tf/api';
import type { FilterItem } from '@tf/ui';

import { getDisplayName } from '@/core/utils';

export const getAccountsFilters = (
	users: BackendTypes.UserInfo[],
	accountKinds: { value: string; label: string }[]
): FilterItem[] => {
	return [
		{
			name: 'kind',
			label: 'Account type',
			type: 'select',
			value: accountKinds,
		},
		{
			name: 'values.accountStatus',
			label: 'Account status',
			type: 'select',
			value: [
				{ value: 'PROSPECT', label: 'Prospect' },
				{ value: 'ACTIVE', label: 'Active' },
				{ value: 'SUSPENDED', label: 'Suspended' },
				{ value: 'REJECTED', label: 'Rejected' },
				{ value: 'CLOSED', label: 'Closed' },
				{ value: 'ARCHIVED', label: 'Archived' },
			],
		},
		{
			name: 'verification',
			type: 'select',
			label: 'Verification status',
			value: [
				{ value: 'DRAFT', label: 'Draft' },
				{ value: 'READY_TO_REVIEW', label: 'Ready to review' },
				{ value: 'UNDER_REVIEW', label: 'Pending review' },
				{ value: 'VERIFIED', label: 'Verified' },
				{ value: 'UPDATE_REQUIRED', label: 'Update required' },
			],
		},
		{
			name: 'importance',
			type: 'select',
			label: 'Priority',
			value: [
				{ value: 'HIGH', label: 'High' },
				{ value: 'MEDIUM', label: 'Medium' },
				{ value: 'LOW', label: 'Low' },
			],
		},
		{
			name: 'assignedTo',
			type: 'select',
			label: 'Assigned manager',
			value: [
				{ value: '__unassigned__', label: 'Not assigned' },
				...(users?.map((user) => ({ value: user.userId, label: getDisplayName(user) })) || []),
			],
		},
	];
};
