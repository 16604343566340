import { queryOptions, useQuery } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { BackendClient } from '@tf/api';

import { getMonitoringAssociationsQuery } from '@/core/queries';

export const entityLookupQueryOptions = (entityKinds: BackendTypes.EntityKind[], query: string) => {
	return queryOptions({
		queryKey: ['entityLookup', entityKinds, query],
		queryFn: async () => {
			if (query.length === 0) {
				return [];
			}

			const result = await BackendClient.getInstance().entityLookup({ query: query }, { entityKinds });

			return (await result.json()) as BackendTypes.EntityLookupData;
		},
	});
};

export const useEntityLookupQuery = (entityKinds: BackendTypes.EntityKind[], query: string) => {
	return useQuery(entityLookupQueryOptions(entityKinds, query));
};

const entitiesQueryOptions = (params: BackendTypes.EntitiesParams) => {
	return {
		queryKey: ['entities', params],
		queryFn: async (): Promise<BackendTypes.EntitiesData> => {
			const res = await BackendClient.getInstance().entities(params);
			return res.json();
		},
	};
};

export const useEntities = (params: BackendTypes.EntitiesParams) => {
	return useQuery(entitiesQueryOptions(params));
};

const entityQueryOptions = (entityId: number) => {
	return {
		queryKey: ['entity', entityId],
		queryFn: async (): Promise<BackendTypes.Entity> => {
			const res = await BackendClient.getInstance().entityGet(entityId);
			return res.json();
		},
	};
};

export const useEntity = (entityId: number) => {
	return useQuery(entityQueryOptions(entityId));
};

const entityAssociationsQueryOptions = (params: BackendTypes.EntityAssociationsGetParams) => {
	return {
		queryKey: ['entityAssociations', params],
		queryFn: () => getMonitoringAssociationsQuery(params),
	};
};

export const useEntityAssociations = (params: BackendTypes.EntityAssociationsGetParams) => {
	return useQuery(entityAssociationsQueryOptions(params));
};
