import React, { useState } from 'react';

import type { FC } from 'react';

import { ActionIcon, Box, Button, createStyles, Drawer, Group, Icon, Title } from '@tf/ui';

import { useSelectedAccountTasksTotal } from '@/core/hooks';
import { AccountTasks } from '@/components/accounts';
import { CounterBadge } from '@/components/shared';

const useStyles = createStyles(({ colors }) => ({
	header: {
		display: 'flex',
		alignItems: 'center',
		padding: '1rem',
		borderBottomStyle: 'solid',
		borderBottomWidth: 1,
		borderBottomColor: colors.gray[2],
	},
	title: {
		fontSize: '1.4rem',
		fontWeight: 500,
		flex: 1,
	},
	content: {
		padding: 0,
	},
}));

export const AccountTasksButton: FC = () => {
	const { classes } = useStyles();
	const [isOpened, setIsOpened] = useState(false);

	const handleClick = () => {
		setIsOpened(!isOpened);
	};

	const tasksTotal = useSelectedAccountTasksTotal();

	return (
		<>
			<Button
				variant="light"
				size="sm"
				leftSection={<Icon.IconListCheck size={16} />}
				onClick={handleClick}
			>
				<Group gap=".25rem" sx={{ flexWrap: 'nowrap' }}>
					Tasks
					{tasksTotal > 0 ? <CounterBadge value={tasksTotal} /> : null}
				</Group>
			</Button>
			<Drawer
				overlayProps={{ opacity: 0.1 }}
				position="right"
				opened={isOpened}
				onClose={handleClick}
				size={480}
				lockScroll={false}
				withCloseButton={false}
				styles={{ body: { padding: 0 } }}
			>
				<Box className={classes.header}>
					<Title className={classes.title}>Tasks</Title>
					<ActionIcon variant="transparent" color="#6B7280">
						<Icon.IconX size={18} onClick={handleClick} />
					</ActionIcon>
				</Box>
				<Box className={classes.content}>
					<AccountTasks onTaskActionFinish={handleClick} />
				</Box>
			</Drawer>
		</>
	);
};
