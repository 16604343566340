import type { CSTypes } from '@tf/api';

type VisualModifierField = {
	label: string;
	order?: number;
	preset?: string;
	value?: string;
};

type VisualModifier = {
	[key: string]: VisualModifierField;
};

const visualModifiers: Record<string, VisualModifier> = {
	AdhocSearchPersonParams: {
		firstName: {
			label: 'First name',
			order: 1,
		},
		middleName: {
			label: 'Middle name',
			order: 2,
		},
		lastName: {
			label: 'Last name',
			order: 3,
		},
		birthDate: {
			label: 'Birth date',
			order: 4,
			preset: 'date',
		},
	},
	OverviewAccountPepStatus: {
		PEP: {
			label: 'PEP',
			value: 'PEP',
		},
		NOT_PEP: {
			label: 'Not PEP',
			value: 'NOT_PEP',
		},
		FAMILY_MEMBER: {
			label: 'Family member',
			value: 'FAMILY_MEMBER',
		},
		CLOSE_ASSOCIATE: {
			label: 'Close associate',
			value: 'CLOSE_ASSOCIATE',
		},
		DEPEPED: {
			label: 'De-PEPed',
			value: 'DEPEPED',
		},
		DONT_KNOW: {
			label: "Don't know",
			value: 'DONT_KNOW',
		},
	},
	AdhocSearchEntityParams: {
		entityName: {
			label: 'Entity name',
		},
	},
};

export const applyVisualModifierPatches = (definitions: CSTypes.GetFrontendIrResponse) => {
	const defs = structuredClone(definitions);

	const structs = defs.structs;
	const options = defs.options;
	for (const modifierKey of Object.keys(visualModifiers)) {
		const structure = structs[modifierKey];
		const optionsToModify = options[modifierKey];

		if (structure) {
			const { fields } = structure;

			for (const field of fields) {
				field.visual = {
					...field.visual,
					...visualModifiers[modifierKey][field.name],
				};
			}

			fields.sort((a, b) => {
				const weightA = a.visual?.order || 0;
				const weightB = b.visual?.order || 0;
				return weightA - weightB;
			});
		}

		const modifiedOptions: any[] = [];
		if (optionsToModify) {
			for (const option of optionsToModify) {
				if (visualModifiers[modifierKey][option.value]) {
					modifiedOptions.push(visualModifiers[modifierKey][option.value]);
				} else {
					modifiedOptions.push(option);
				}
			}
			options[modifierKey] = [...modifiedOptions];
		}
	}

	return defs;
};
