import { Box, createStyles } from '@tf/ui';

const useStyles = createStyles(({ breakpoints }) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		maxWidth: breakpoints.xl,
		margin: '.75rem auto',
	},
	content: {
		flex: 1,
	},
}));

interface Props {
	menu: React.ReactNode;
	children: React.ReactNode;
}

export const SideMenuLayout: React.FC<Props> = ({ children, menu }) => {
	const { classes } = useStyles();
	return (
		<Box className={classes.root}>
			{menu}
			<Box className={classes.content} miw={0}>
				{children}
			</Box>
		</Box>
	);
};
