import { Alert, Box, createStyles, TFText } from '@tf/ui';

import { useAccountParams, useSelectedAccount } from '@/core/hooks';

const useStyles = createStyles(({ colors, breakpoints }) => ({
	root: {
		backgroundColor: colors.gray[0],
		margin: '.75rem auto',
		maxWidth: breakpoints.xl,
	},
	alert: {
		borderStyle: 'solid',
		borderWidth: 1,
		borderColor: colors.brand[1],
	},
	text: {
		fontSize: '.8rem',
	},
}));

export const AccountAlert = () => {
	const { connectionKind } = useAccountParams();
	const { reviews } = useSelectedAccount();
	const { classes } = useStyles();

	const isApplicant = connectionKind === 'APPLICANT';

	if (isApplicant) {
		const applicant = Array.from(reviews.values()).find((r) => r.connectionKind === 'APPLICANT');
		const isNotFilled = applicant?.listSegmentReviewContainers.some((c) => c.status === 'PENDING_FILL');

		if (isNotFilled && applicant?.state === 'DRAFT') {
			return (
				<Box className={classes.root}>
					<Alert className={classes.alert}>
						<TFText className={classes.text}>
							Please fill in the following forms to complete the application.
						</TFText>
					</Alert>
				</Box>
			);
		}
	}

	return null;
};
