import React, { useRef, useState } from 'react';

import { useSelector } from '@datagrid/state';
import { useQueryClient } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import type { FormControl } from '@tf/form-builder';
import { getFormStatuses } from '@tf/shared';
import { TFNotifier } from '@tf/ui';
import type { FormSegment, SubmitHandler } from '@tf/utils';
import { rawRevision, T } from '@tf/utils';

import { accountQueryOptions } from '@/core/api/account';
import { useAccountParams, useProcessingScripts, useSelectedReview } from '@/core/hooks';
import { writeSegmentsMutation } from '@/core/mutations';
import { appStore } from '@/core/stores';
import type { FormState } from '@/core/types';
import { AppFormBuilder } from '@/components/shared';

import { FormFooter } from './FormFooter';

interface Props {
	data: BackendTypes.SegmentsReadData;
	additionalData:
		| BackendTypes.AdhocSearchEntityParams
		| BackendTypes.AdhocSearchPersonParams
		| undefined;
	validateInitialValues: boolean;
}

type Revisions = Record<BackendTypes.SegmentKind, number>;

export const Form: React.FC<Props> = ({ data, additionalData, validateInitialValues }) => {
	const queryClient = useQueryClient();
	const params = useAccountParams();
	const review = useSelectedReview();
	const defs = useSelector(() => appStore.defs.get().structs);

	const formControl = useRef<FormControl>();

	// * Initial segments metadata
	const segments: FormSegment[] = data.map(({ container: { info } }) => {
		const label = defs[info.SegmentIdentity.segmentKind]?.label || '';
		return { accessMode: info.AccessMode, ...info.SegmentIdentity, label } satisfies FormSegment;
	});

	const currency = useSelector(() => appStore.currency?.get());
	T.assertNotNullish(currency, 'no currency in appStore');

	// * Segments processing scripts
	const segmentKinds = segments.map(({ segmentKind }) => segmentKind);
	const scripts = useProcessingScripts(segmentKinds);

	// * Initial revisions
	const initialRevisions = data.reduce(
		(acc, { container: { info } }) => ({
			...acc,
			[info.SegmentIdentity.segmentKind]: info.Revision.revision,
		}),
		{} as Revisions
	);

	const [revisions, setRevisions] = useState<Revisions>(initialRevisions);

	// * Initial values
	const initialValues = data.reduce((acc, { container }) => {
		const { info, segment } = container;
		return {
			...acc,
			[info.SegmentIdentity.segmentKind]: segment,
		};
	}, {} as Record<BackendTypes.SegmentKind, BackendTypes.Segment>);

	// * Component action
	const handleSubmit: SubmitHandler = async ({ values, segmentValidationStatuses }) => {
		try {
			// * Accumulate segments data
			const input: BackendTypes.SegmentsWritePayload = [];
			for (const { container } of data) {
				const { graphId, segmentKind } = container.info.SegmentIdentity;
				input.push({
					segmentIdentity: { graphId, segmentKind },
					revision: { ...rawRevision, revision: revisions[segmentKind] },
					segment: values[segmentKind] ?? {},
					validationStatus: segmentValidationStatuses[segmentKind],
				});
			}

			// * Write segments and updating revisions
			const res = await writeSegmentsMutation(input);
			TFNotifier.info('Form successfully updated', { id: 'form-updated' });
			for (const { container } of res) {
				setRevisions((prev) => ({
					...prev,
					[container.info.SegmentIdentity.segmentKind]: container.info.Revision.revision,
				}));
			}

			// * Refetch account data
			await queryClient.refetchQueries(accountQueryOptions(params.accountId));
		} catch (err) {
			const message = err instanceof Error ? err.message : 'Something went wrong';
			TFNotifier.error(message);
		}
	};

	// * Compose FormLoader State
	const state = {
		id: params.formKind,
		statuses: getFormStatuses({
			containers: review.listSegmentReviewContainers,
			segmentIdentities: segments,
		}),
	} satisfies FormState;

	return (
		<AppFormBuilder
			currency={currency}
			formControlRef={formControl}
			segments={segments}
			additionalData={additionalData}
			initialValues={initialValues}
			processingScripts={scripts}
			validateInitialValues={validateInitialValues}
			onSubmit={handleSubmit}
			submitButtonRenderer={(isLoading) => (
				<FormFooter
					state={state}
					review={review}
					segments={segments}
					graphId={segments[0]?.graphId}
					isLoading={isLoading}
				/>
			)}
			styles={{
				content: { marginTop: '1rem' },
				segment: ({ colors }) => ({
					marginBottom: '1rem',
					padding: '.75rem 1rem 1rem',
					borderStyle: 'solid',
					borderRadius: 8,
					borderWidth: 0.5,
					borderColor: colors.gray[2],
					backgroundColor: '#fff',
					boxShadow: '0 4px 20px -4px rgb(0 0 0 / 10%)',
				}),
			}}
		/>
	);
};
