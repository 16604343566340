import React from 'react';

import { useSelector } from '@datagrid/state';
import { Navigate, useParams } from 'react-router-dom';

import { extractForms } from '@tf/shared';

import { useEntity } from '@/core/api/entities';
import { appStore } from '@/core/stores';

export const NavigateToFirstEntityFormRoute: React.FC = () => {
	const { entityId } = useParams<{ entityId: string }>();
	const formDefs = useSelector(() => appStore.defs.get().forms);

	const { data: entity } = useEntity(Number(entityId));

	if (!entity) return null;

	const forms = extractForms({
		formDefs,
		segmentIdentities: entity.listSegments.map((s) => s.SegmentIdentity),
	});

	const firstForm = forms[0];

	return <Navigate to={`${firstForm.name}`} replace />;
};
