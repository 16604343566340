import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import type { ExtendedFormDefinition } from '@tf/shared';
import { getFormHash } from '@tf/shared';
import { Box, createStyles, Modal, TFText, useDisclosure } from '@tf/ui';
import { S } from '@tf/utils';

import { useSelectedAccount, useSelectedAccountTasks } from '@/core/hooks';
import { isReviewFromConnectionGroup } from '@/core/utils/reviews';
import { useNavigateToEntity } from '@/core/utils/routes';
import { ConnectionForm } from '@/components/shared/ConnectionForm';

import { RiskModalForm } from './RiskModalForm';
import { TaskItem } from './TaskItem';

const useStyles = createStyles(({ colors }) => ({
	reviewRoot: {},
	reviewTitle: {
		fontWeight: 500,
		fontSize: '1.1rem',
		padding: '.5rem 1rem',
		borderBottomStyle: 'solid',
		borderBottomWidth: 1,
		borderBottomColor: colors.gray[2],
	},
	entityHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: '.375rem 1rem',
		backgroundColor: colors.brand[0],
	},
	entityLabel: {
		color: colors.brand[6],
		fontSize: '.8rem',
	},
}));

interface ConnectionModalParams {
	connectionKind: BackendTypes.EntityConnectionKind;
	parentEntityId: BackendTypes.GraphId;
	parentEntityKind: BackendTypes.EntityKind;
	review: {
		graphLinkId: BackendTypes.GraphId;
		connectionKind: BackendTypes.EntityConnectionKind;
	};
	label: string;
}

type ConnectionModalState = { isModalOpen: false } | ({ isModalOpen: true } & ConnectionModalParams);

interface Props {
	onTaskActionFinish?: () => void;
	hideLabel?: boolean;
}

export const AccountTasks: React.FC<Props> = ({ onTaskActionFinish, hideLabel }) => {
	const { classes } = useStyles();

	const { getEntityPath } = useNavigateToEntity();
	const navigate = useNavigate();
	const navigateToEntity = (params: {
		reviewConnectionKind: BackendTypes.EntityConnectionKind;
		reviewLinkId: BackendTypes.GraphId;
		entityId: BackendTypes.GraphId;
	}) => {
		window.scrollTo(0, 0);
		navigate(getEntityPath(params.reviewLinkId, params.reviewConnectionKind, params.entityId));
		onTaskActionFinish?.();
	};

	const account = useSelectedAccount();
	const navigateToForm = (params: {
		reviewConnectionKind: BackendTypes.EntityConnectionKind;
		reviewLinkId: BackendTypes.GraphId;
		entityId: BackendTypes.GraphId;
		form: ExtendedFormDefinition<BackendTypes.FormDefinition>;
	}) => {
		const mainEntityId = account.reviews.find((r) => r.connectionKind === 'APPLICANT')?.graphNodeId;

		const basePath = `/accounts/${account.meta.graphNodeId}/${S.slugify(params.reviewConnectionKind)}/${
			params.reviewLinkId
		}`;
		const formPart = `${params.form.name}?hash=${getFormHash(params.form)}`;

		const formPath =
			params.entityId === mainEntityId || isReviewFromConnectionGroup(account, params.reviewLinkId)
				? `${basePath}/${formPart}`
				: `${basePath}/entity/${params.entityId}/${formPart}`;

		window.scrollTo(0, 0);
		navigate(formPath);
		onTaskActionFinish?.();
	};

	const riskModal = useDisclosure();

	const [connectionModalState, setConnectionModalState] = useState<ConnectionModalState>({
		isModalOpen: false,
	});

	const closeConnectionModal = () => setConnectionModalState({ isModalOpen: false });

	const createConnection = (params: ConnectionModalParams) => {
		setConnectionModalState({
			...params,
			isModalOpen: true,
		});
	};

	const accountTasks = useSelectedAccountTasks();
	const hasAnyTasks = accountTasks.some((reviewTasks) =>
		reviewTasks.entities.some((entityTasks) => entityTasks.tasks.length > 0)
	);

	if (!hasAnyTasks) {
		return (
			<Box p="1rem">
				<TFText c="dimmed">All tasks are completed</TFText>
			</Box>
		);
	}

	return (
		<>
			{accountTasks.map((reviewTasks) => {
				const hasTasksForReview = reviewTasks.entities.some(
					(entityTasks) => entityTasks.tasks.length > 0
				);

				if (!hasTasksForReview) {
					return null;
				}

				return (
					<Box key={reviewTasks.reviewLinkId} className={classes.reviewRoot}>
						{!hideLabel && (
							<TFText className={classes.reviewTitle}>
								{S.prettify(reviewTasks.reviewConnectionKind)}
							</TFText>
						)}

						{reviewTasks.entities.map((entityTasks) => {
							if (entityTasks.tasks.length === 0) {
								return null;
							}

							return (
								<div key={entityTasks.entityId}>
									<Box className={classes.entityHeader}>
										<TFText className={classes.entityLabel}>{entityTasks.entityName}</TFText>
									</Box>

									<Box>
										{entityTasks.tasks.map((task) => {
											let handleClick;
											switch (task.type) {
												case 'PENDING_FILL':
												case 'VALIDATION_ERRORS':
												case 'PENDING_REVIEW':
												case 'REJECTED':
													handleClick = () => {
														navigateToForm({
															reviewLinkId: reviewTasks.reviewLinkId,
															reviewConnectionKind: reviewTasks.reviewConnectionKind,
															entityId: entityTasks.entityId,
															form: task.form,
														});
													};
													break;
												case 'CORRECT_SHAREHOLDERS_PERCENTAGE_SUM':
												case 'CORRECT_PARTNERSHIP_PERCENTAGE_SUM':
													handleClick = () => {
														navigateToEntity({
															reviewLinkId: reviewTasks.reviewLinkId,
															reviewConnectionKind: reviewTasks.reviewConnectionKind,
															entityId: entityTasks.entityId,
														});
													};
													break;
												case 'REQUIRED_CONNECTION':
													handleClick = () => {
														createConnection({
															...task.connection,
															label: task.label,
															review: {
																graphLinkId: reviewTasks.reviewLinkId,
																connectionKind: reviewTasks.reviewConnectionKind,
															},
														});
													};
													break;
												case 'FINALIZE_REVIEW':
													handleClick = () => {
														riskModal.open();
													};
													break;
												default:
													handleClick = () => {
														console.log('Unhandled task type');
													};
											}

											return <TaskItem key={task.id} task={task} onClick={handleClick} />;
										})}
									</Box>
								</div>
							);
						})}
					</Box>
				);
			})}

			{connectionModalState.isModalOpen && (
				<Modal
					size="500px"
					opened={connectionModalState.isModalOpen}
					title={connectionModalState.label}
					onClose={closeConnectionModal}
				>
					<ConnectionForm
						connectionKind={connectionModalState.connectionKind}
						parentEntity={{
							kind: connectionModalState.parentEntityKind,
							graphId: connectionModalState.parentEntityId,
						}}
						review={connectionModalState.review}
						onSuccess={() => {
							closeConnectionModal();
							onTaskActionFinish?.();
						}}
					/>
				</Modal>
			)}

			{riskModal.isOpen && (
				<Modal opened={riskModal.isOpen} onClose={riskModal.close} title="Client risk profile">
					<RiskModalForm onClose={riskModal.close} />
				</Modal>
			)}
		</>
	);
};
