import { useSelector } from '@datagrid/state';

import type { BackendTypes } from '@tf/api';
import { S } from '@tf/utils';

import { appStore } from '@/core/stores';

type ConnectionLabels = Record<BackendTypes.EntityConnectionKind, { singular: string; plural: string }>;

export const useEntityConnectionLabels = (entityKind: BackendTypes.EntityKind): ConnectionLabels => {
	const entities = useSelector(() => appStore.defs.entities.get());
	const labels: ConnectionLabels = {};

	entities[entityKind].possibleConnections.forEach((connection) => {
		labels[connection.kind] = {
			singular: connection.labels?.singular || S.prettify(connection.kind),
			plural: connection.labels?.plural || S.prettify(connection.kind),
		};
	});

	return labels;
};
