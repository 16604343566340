import { useSelectedAccountTasks } from '@/core/hooks/use-selected-account-tasks';

export const useSelectedAccountTasksTotal = () => {
	const accountTasks = useSelectedAccountTasks();

	return accountTasks.reduce((total, reviewTasks) => {
		return (
			total +
			reviewTasks.entities.reduce((reviewTotal, { tasks }) => {
				return reviewTotal + tasks.length;
			}, 0)
		);
	}, 0);
};
