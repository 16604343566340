import React, { useEffect } from 'react';

import { useObserveEffect, useSelector } from '@datagrid/state';
import { useNavigate } from 'react-router-dom';

import { TFLoadingOverlay, TFNotifier } from '@tf/ui';

import { useEntity } from '@/core/api/entities';
import { monitoringStore } from '@/core/stores';

interface Props extends React.PropsWithChildren {
	entityId: number;
}

export const MonitoringProvider: React.FC<Props> = ({ children, entityId }) => {
	const navigate = useNavigate();

	const entityQuery = useEntity(entityId);
	const selectedAccount = useSelector(() => monitoringStore.selected.get());

	useObserveEffect(() => {
		// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
		monitoringStore.set({ hits: null, selected: null });
	});

	useEffect(() => {
		if (selectedAccount === null) {
			monitoringStore.selected.set(entityQuery.data || null);
		} else {
			monitoringStore.selected.assign(entityQuery.data || null);
		}
	}, [entityQuery.data]);

	useEffect(() => {
		if (entityQuery.error) {
			TFNotifier.error('Could not read the entity information. Please try again later');
			navigate('/monitoring');
		}
	}, [entityQuery.error, navigate]);

	if (selectedAccount === null) {
		return <TFLoadingOverlay label="Loading..." h={500} />;
	}

	return <>{children}</>;
};
