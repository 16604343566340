import { Stack, TFText } from '@tf/ui';

import { AccountTasks } from '@/components/accounts';

export const ProspectActiveConfirmationText = () => {
	return (
		<Stack gap="md">
			<TFText>
				Please note the account review is incomplete and requires further info in the following forms:
			</TFText>
			<AccountTasks hideLabel />
			<TFText>Once activated, the profile will be monitored against an AML risk database.</TFText>
		</Stack>
	);
};
