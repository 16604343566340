import React from 'react';

import { createStyles, Flex, Icon, TFText, UnstyledButton } from '@tf/ui';

const useStyles = createStyles(({ colors }) => ({
	accountName: {
		fontSize: 13,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		color: colors.gray[9],
		'&:hover': {
			color: colors.brand[6],
			textDecoration: 'underline',
		},
	},
}));

interface Props {
	textSize: string;
	onClick: () => void;
	withIcon?: boolean;
}

export const HiddenAccountsNotice: React.FC<Props> = ({ textSize, onClick, withIcon = false }) => {
	const { classes } = useStyles();

	return (
		<Flex justify="center" align="center">
			{withIcon && <Icon.IconEyeOff size="16px" />}
			<TFText ml={withIcon ? '8px' : '0'} size={textSize} mr="4px">
				Some accounts may be hidden in
			</TFText>
			<UnstyledButton onClick={onClick} className={classes.accountName}>
				<TFText fz={textSize}>Archived status</TFText>
			</UnstyledButton>
		</Flex>
	);
};
