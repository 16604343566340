import { useEffect } from 'react';

import { useObservable, useSelector } from '@datagrid/state';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { TFNotifier } from '@tf/ui';
import type { FormSegment, FormValues } from '@tf/utils';
import { rawRevision } from '@tf/utils';

import { accountQueryOptions } from '@/core/api/account';
import { overviewAccountQueryOptions } from '@/core/api/overview';
import { useSegmentsQuery } from '@/core/api/segments';
import { useSelectedAccount } from '@/core/hooks/use-selected-account';
import { writeSegmentsMutation } from '@/core/mutations';
import { getSelfSegment } from '@/core/utils';

interface Values {
	accountId: string;
	name: string;
	segments: FormValues;
}

interface State {
	values?: FormValues;
	revisions: Record<string, number>;
}

export const useAccountSettingsSegment = () => {
	const { meta } = useSelectedAccount();

	const state = useObservable<State>({
		revisions: {},
	});

	// Extract target segment identities
	const selfSegmentKind = getSelfSegment(meta.entityKind);
	const rmFeedbackSegmentKind = 'COMMON__RM_FEEDBACK';
	const requiredSegments = [selfSegmentKind, rmFeedbackSegmentKind, 'COMMON__ACCOUNT_OVERVIEW'];
	const formSegments: FormSegment[] = meta.listSegments
		.filter((s) => requiredSegments.includes(s.SegmentIdentity.segmentKind))
		.map((s) => ({ ...s.SegmentIdentity, accessMode: s.AccessMode }));

	// * Get segments data
	const segmentsQuery = useSegmentsQuery(formSegments);

	useEffect(() => {
		if (segmentsQuery.data) {
			const nextState: Required<State> = {
				values: {},
				revisions: {},
			};
			for (const segmentData of segmentsQuery.data) {
				const { info, segment } = segmentData.container;
				const segmentKind = info.SegmentIdentity.segmentKind;
				nextState.values[segmentKind] = segment;
				nextState.revisions[segmentKind] = info.Revision.revision;
			}
			state.assign(nextState);
		}
	}, [segmentsQuery.data, state]);

	const updateSegments = (values: FormValues) => {
		const payload: BackendTypes.SegmentsWritePayload = [];
		for (const formSegment of formSegments) {
			if (formSegment.accessMode === 'VIEW') {
				continue;
			}

			const isPartiallyEditedSegment =
				formSegment.segmentKind === selfSegmentKind || formSegment.segmentKind === rmFeedbackSegmentKind;

			payload.push({
				segmentIdentity: formSegment,
				// IMPORTANT: AccountSettings form have regular FormBuilder that renders full segments,
				// 	but also have few segments that rendered (and edited!) partially: SELF_SEGMENT, RM_FEEDBACK.
				// 	So, we need to merge values with previous data when we save partially edited segments
				// 	but must not merge anything for segments that were rendered fully
				segment: isPartiallyEditedSegment
					? {
							...state.values?.get()?.[formSegment.segmentKind],
							...values[formSegment.segmentKind],
					  }
					: values[formSegment.segmentKind],
				revision: {
					...rawRevision,
					revision: state.revisions.peek()[formSegment.segmentKind],
				},
			});
		}

		return writeSegmentsMutation(payload);
	};

	const queryClient = useQueryClient();
	const onSuccess = (res: BackendTypes.SegmentsWriteData) => {
		// * Update revisions
		for (const segmentData of res) {
			const { SegmentIdentity, Revision } = segmentData.container.info;
			const segmentKind = SegmentIdentity.segmentKind;
			state.revisions.set((prev) => ({ ...prev, [segmentKind]: Revision.revision }));
		}

		TFNotifier.success('Account updated successfully!');
		queryClient.refetchQueries(overviewAccountQueryOptions(meta.graphNodeId));
		queryClient.refetchQueries(accountQueryOptions(meta.graphNodeId));
	};

	// Getters and mutations for separate fields
	const name: string = useSelector(() => state.values?.get()?.[selfSegmentKind]?.name) ?? '';
	const accountId: string =
		useSelector(() => state.values?.get()?.[rmFeedbackSegmentKind].account_id) ?? '';
	const segmentDefs = formSegments.filter((s) => {
		return s.segmentKind !== selfSegmentKind && s.segmentKind !== rmFeedbackSegmentKind;
	});

	const updateMutation = useMutation({
		mutationFn: ({ segments, accountId, name }: Values) => {
			return updateSegments({
				...segments,
				[rmFeedbackSegmentKind]: { account_id: accountId },
				[selfSegmentKind]: { name },
			});
		},
		onSuccess,
	});

	return {
		values: {
			name,
			accountId,
			segments: state.values?.get(),
		},
		segmentDefs,
		updateMutation,
	};
};
